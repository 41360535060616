import {useCallback, useContext} from 'react';
import useNotifier from './useNotifier';
import ScopeContext from '../components/providers/ScopeContext';
import { IPedido } from '../commons/interfaces/Pedidos';

type NotifierHook = {
    showSucessMessage: () => void;
    showSucessCancelMessage: () => void;
    showErrorMessage: () => void;
    // processAxiosError: (axiosError: AxiosError<string>) => string;
};

interface UseSeparatedArgs {
    title: string;
    onSeparateCallBack: () => void;
    onCancelSeparateCallBack: () => void;
}

const useSeparated = ({title, onSeparateCallBack, onCancelSeparateCallBack}: UseSeparatedArgs): NotifierHook => {
    const {scope} = useContext(ScopeContext);
    const { showMessage } = useNotifier();

    const separatedMessage: string = 'El pedido fue apartado.';
    const cancelSeparateMessage: string = 'El apartado fue cancelado.';
    const errorMessage: string = 'Ocurrio un error durante el proceso';


    const showSucessMessage = useCallback(() => {
        showMessage(title, separatedMessage, "success");
        // refreshTable.next({scopeContext: scope.scopeContext});
        onSeparateCallBack();
    }, [showMessage, scope.scopeContext, onSeparateCallBack, title, separatedMessage]);

    const showSucessCancelMessage = useCallback(() => {
        showMessage(title, cancelSeparateMessage, "success");
        // refreshTable.next({scopeContext: scope.scopeContext});
        onCancelSeparateCallBack();
    }, [showMessage, scope.scopeContext, onSeparateCallBack, title, cancelSeparateMessage]);


    const showErrorMessage = useCallback(() => {
        showMessage(title, errorMessage, "error");
    }, [showMessage, scope.scopeContext, onSeparateCallBack, title, separatedMessage]);


    return { showSucessMessage, showSucessCancelMessage, showErrorMessage };
};

export default useSeparated;