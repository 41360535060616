import { FC, useCallback, useContext, useEffect, useState } from "react";
import {
  Group,
  Grid,
  Select,
  Flex,
  Container,
  Button,
  Text,
  Space,
} from "@mantine/core";
import "dayjs/locale/es";
import {
  FormFieldConfigInterface,
  FormFieldsInterface
} from "../../commons/interfaces/MasterFilterInterfaces";
import useMasterFilterConfig from "../../hooks/useMasterFilterConfig";
import PaginationBar from "../Bar/PaginationBar";
import MasterTable from "../Table/MasterTable";
import { fetchProductColors, refreshTable } from "../../commons/utils/ActionsSubject";
import SearchBar from "../Bar/SearchBar";
import { useToggle } from "@mantine/hooks";
import { filterZoneBackground, tableZoneBackground } from "../../styles";
import CheckBoxActionBar from "../Bar/CheckBoxActionBar";
import { FullTableActionProps, TableBaseProps } from "../../commons/types/MasterTable";
import ScopeContext from "../providers/ScopeContext";
import MasterFilterSkeleton from "../skeletons/MasterFilterSkeleton";
import TotalSection from "../Table/totals/TotalSection";
import TotalSectionSkeleton from "../skeletons/TotalSectionSkeleton";
import ThreeStateFilter from "../select/ThreeStateFilter";
import { useForm } from "@mantine/form";
import FieldsFactory from "./fields/FieldsFactory";
import { FormValues } from "../../commons/interfaces/Form";
import RefreshTabsContext from "../providers/RefreshTabsContext";
import ColumnSelector from "../Table/ColumnSelector";


type MasterFilterProps = {
  buttonsConfig: TableBaseProps;
  filtersConfig: FormFieldConfigInterface[];
  threeStateConfig?: FormFieldConfigInterface[];
};

const MasterFilter: FC<MasterFilterProps> = ({
  buttonsConfig,
  filtersConfig,
  threeStateConfig
}) => {
  const { scope, setScope } = useContext(ScopeContext);
  const { refreshTabs, setRefreshTabs, getAbortController } = useContext(RefreshTabsContext);

  const {
    handleFormData,
    handleRefreshTotals,
    isLoadingData,
    isLoadingTotal,
    pedidosResponse,
    totalsResponse,
    setTotalsResponse
  } = useMasterFilterConfig(scope.scopeUrl);

  const [isFilterOpened, toggleFilters] = useToggle<boolean>([false, true]);
  const [itemsPerPage, setItemPerPage] = useState<number>(15);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [activePage, setActivePage] = useState<number>(1);
  const [columns, setColumns] = useState<Record<string, string>>({});
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);

  const form = useForm<FormValues>(
    { mode: 'uncontrolled',
      initialValues: (
        filtersConfig?.concat(threeStateConfig || [])
          .reduce((acc, fieldConfig) => {
                    acc[fieldConfig.id] = fieldConfig.defaultValue || '';
                    return acc;
            }, {} as FormValues)
      )
    });

  /**
   * useEffect to handle the change of the itemsPerPage state
   */
  useEffect(() => {
    if (pedidosResponse) {
      const pages = Math.ceil(pedidosResponse.totalItems / itemsPerPage);
      setTotalPages(pages);
      setActivePage(pedidosResponse.pageNumber);
    }
  }, [pedidosResponse, itemsPerPage, totalsResponse]);

  /**
   * useEffect to handle data fetching when the component changes from lazyFetch to fetch all data
   */
  useEffect(() => {
    if(!scope.lazyFetch){
      handleSubmit();
    }
  }, [itemsPerPage, scope]);

  /**
   * useEffect to handle the refresh of the table when the refreshTable action is triggered
   */
  useEffect(() => {
    const subscription = refreshTable.subscribe((value) => {
      if (value && value.scopeContext === scope.scopeContext) {
        handleSubmit();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [scope]);

  /**
   * useEffect to handle refreshTabs behavior
   */
  useEffect(() => {
    if (refreshTabs) {
      if (!scope.lazyFetch) {
        refreshTable.next({scopeContext: scope.scopeContext});
      }
    }

    return () => {
      setRefreshTabs(false);
    }

  }, [refreshTabs]);

  const handleSubmit = (page?: number) => {
    console.log('[MasterFilter] handlesubmit', { formFields: getFormFields(), page: page || 1, pageSize: itemsPerPage, order: scope.order });
    setTotalsResponse(null); // blanqueamos los totales
    handleFormData({ formFields: getFormFields(), page: page || 1, pageSize: itemsPerPage, order: scope.order });
  };

  const handleFilterSubmit = () => {
    console.log('[MasterFilter] handle filter button submit');
    setTotalsResponse(null); // blanqueamos los totales
    setScope((prevScope) => ({...prevScope, order: undefined}));
  };

  /**
   * This function only send a post to get the totals based on filters
   */
  const handleTotalsRefresh = () => {
    handleRefreshTotals({ formFields: getFormFields(), page: 0, pageSize: 0 });
  }

  const getFormFields = useCallback(() => {
    const formFields: FormFieldsInterface[] = Object.entries(form.getValues())
                .map(([key, value]) => {
                          return { id: key, value };
                      });
    return formFields;
  }, [form]);

  const handlePageChange = (pageNumber: number) => {
    handleSubmit(pageNumber);
  }

  const handleWildCardSubmit = (wildcard: string) => {
    console.log('[MasterFilter] handleWildCardSubmit', wildcard);
    setTotalsResponse(null); // blanqueamos los totales
    const fields = wildcard ? [{id: 'searchWildcard', value: wildcard}] : [];
    const abortController = getAbortController(scope.scopeUrl);
    abortController?.abort();
    handleFormData({ formFields: fields, page: 1, pageSize: itemsPerPage, order: scope.order });
  }

  const cleanFilters = () => {
    fetchProductColors.next({productId: '', scopeContext: scope.scopeContext});
    form.reset()
  }

  const buildHideableColumns = () => {
    let _columns: Record<string, string> = {};
    Object.entries(buttonsConfig).forEach(([_, value]) => {
      if (Object.keys(value).includes('hideable')) {
        const _value = value as FullTableActionProps;
        if (_value.hideable && !_value.disabled && _value.key) {
          _columns[_value.key] = _value.key;
        }
      }
    });
    setColumns(_columns);
  }

  const onColumnsChanged = (cols: string[]) => {
    setVisibleColumns(cols);
  }

  useEffect(() => {
    buildHideableColumns();
  }, [buttonsConfig]);

  return (
    <>
    <SearchBar title={scope.scopeContext}  searchCallback={handleWildCardSubmit} onToggle={toggleFilters} />
    <div style={{ display: isFilterOpened ? 'block' : 'none' }}>
      <form onSubmit={form.onSubmit((values) => console.log(values))}>
      <Container fluid px="sm" py="sm" styles={filterZoneBackground} >
        <Grid>
            {filtersConfig && filtersConfig.map((fieldConfig, index) => (
              <Grid.Col span={fieldConfig.span} key={fieldConfig.id + scope.scopeContext}>
                  <FieldsFactory fieldConfig={fieldConfig} form={form}/>
              </Grid.Col>
            ))}
          </Grid>

        <Flex py="md" gap="md" align="center">
            {threeStateConfig && threeStateConfig.map((fieldConfig, index) => {
              return(
                // <div key={fieldConfig.id}>
                <ThreeStateFilter
                  id={fieldConfig.id}
                  label={fieldConfig.label}
                  options={fieldConfig.options}
                  key={form.key(fieldConfig.id)}
                  {...form.getInputProps(fieldConfig.id)}
                />
              // </div>
            );})}
            <Space w="xs"/>
            <Button mt={27} variant="filled" onClick={() => handleFilterSubmit()} type="submit">
              Filtrar
            </Button>
            <Button mt={27} onClick={cleanFilters}>
              Limpiar filtros
            </Button>
        </Flex>

      </Container>
      </form>
    </div>
      {isLoadingData ? (
        <MasterFilterSkeleton/>
      ) : (
        pedidosResponse?.pedidos && pedidosResponse.pedidos.length > 0 && (
          <Container fluid px="sm" py="md" styles={tableZoneBackground} >
            <Grid grow gutter="xs">
              <Grid.Col span={6} py="xs"><Text><b>{itemsPerPage}</b> de {pedidosResponse.totalItems} {scope.scopeContext}</Text></Grid.Col>
              <Grid.Col span={6}>
                <Flex justify="flex-end" align="center" direction="row">
                  <Group>
                    <Text>Mostrar: </Text>
                    <Select
                      checkIconPosition="right"
                      data={['15', '30', '50']}
                      pl={10}
                      defaultValue="15"
                      value={itemsPerPage.toString()}
                      onChange={(value, option) => setItemPerPage(Number(value))}
                    />
                  </Group>
                  <Space w="md" />
                  <Group>
                    <ColumnSelector
                      columns={columns}
                      onChanged={onColumnsChanged}
                      context={scope.scopeContext.toString()}
                    />
                  </Group>
                </Flex>
              </Grid.Col>
              <Grid.Col span={12}>
                  <CheckBoxActionBar controlId="BtnGridAutCeo"/>
              </Grid.Col>
            </Grid>

            <MasterTable
              {...buttonsConfig}
              pedidosResponse={pedidosResponse}
              visibleColumns={visibleColumns}
            />
            {isLoadingTotal ? (
              <TotalSectionSkeleton/>
            ) : (
            <TotalSection totals={totalsResponse || pedidosResponse?.totals} onRefreshCallBack={handleTotalsRefresh}/>
            )}
            
            <PaginationBar totalPages={totalPages} activePage={activePage} handlePageChange={handlePageChange} />
          </Container>
        )
      )}
      
    </>
  );
};

export default MasterFilter;
