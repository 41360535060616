import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconStyles } from '../../../styles';
import { ActionIcon, Anchor, Button, Mark, Modal, Table, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { useContext, useEffect, useState } from 'react';
import { IPedido } from '../../../commons/interfaces/Pedidos';
import { FileInfoInterface, FileTypeEnum } from '../../../commons/interfaces/files/FileInfoInterface';
import useAxios from '../../../hooks/useAxios';
import { notifications } from '@mantine/notifications';
import GlobalContext from '../../providers/GlobalContext';

interface FilesListProps {
    request: IPedido;
}

const FilesList: React.FC<FilesListProps> = (props) => {
    const [fileInfo, setFileInfo] = useState<FileInfoInterface[]>([]);
    const [fileToDelete, setFileToDelete] = useState<FileInfoInterface | null>(null);
    const [opened, { open, close }] = useDisclosure(false);
    const {globalContext} = useContext(GlobalContext);
    const { data: filesData, executeRequest: filesDataRequest, responseError: filesDataError } = useAxios({});
    const { data: deleteFileData, executeRequest: deleteFileRequest} = useAxios({});

    useEffect(() => {
        if(opened){
            // Listing order files
            filesDataRequest(`/files/request?action=request-files&request=${props.request.idPedido}&tkmov=${props.request.tkmov}&company=${props.request.idEmpresa}`,
                'get', {});
        }
    },[opened]);

    useEffect(() => {
        if(filesData){
            setFileInfo(filesData);
        }
        if(filesDataError){
        }
    },[filesData, filesDataError]);

    useEffect(() => {
        if(deleteFileData){
            const remainingFiles = fileInfo.filter((file) => {
                if(file.name === fileToDelete?.name){
                    return file.type !== fileToDelete.type;
                }
                return true;
            });
            setFileInfo(remainingFiles);
            setFileToDelete(null);
            notifications.show({
                title: "Resultado",
                message: "Archivo eliminado correctamente",
                color: "green",
            });
        }
    }, [deleteFileData]);

    const handleDeteleFile = (file: FileInfoInterface) => {
        const basePath: string = '/files/request?';
        setFileToDelete(file);
        deleteFileRequest(`${basePath}request=${props.request.idPedido}&tkmov=${props.request.tkmov}&company=${props.request.idEmpresa}&filename=${file.name}&type=${file.type}`,
        'delete', {});
    }

    const getTypeChip = (type: FileTypeEnum) => {
        switch(type){
            case FileTypeEnum.PURCHASE_ORDER:
                return <Mark color="#F8F9FA">Orden de compra</Mark>
            case FileTypeEnum.PAYMENT:
                return <Mark color="#ABB5BE">Archivo de pago</Mark>
            case FileTypeEnum.PRODUCTION:
                return <Mark color="#e9ecef00">Archivo de producción</Mark>
            default:
                return <Mark color="#F8F9FA">Origen desconocido</Mark>
        }
    }

    const getFileURL = (file: FileInfoInterface) => {
        if(file.legacyStorage){
            return `${document.referrer}${globalContext.parentContext}/${file.url}`;
        }else{
            return file.url;
        }
    }

    const rows = fileInfo.map((file, index) => (
        <Table.Tr key={index}>
            <Table.Td>{getTypeChip(file.type )}</Table.Td>
            <Table.Td>
                <Anchor href={getFileURL(file)} target="_blank">
                    {file.name}
                </Anchor>
            </Table.Td>
            <Table.Td>
                <Anchor onClick={() => handleDeteleFile(file)} target="_blank" c='red'>
                    Eliminar archivo
                </Anchor>
            </Table.Td>
        </Table.Tr>
        ));

    return (
        <>
            <Table.Td pr={3} pl={3}>
                <Tooltip label="Ver archivos">
                    <ActionIcon variant="light" size="sm" aria-label="Settings" onClick={open}>
                        <FontAwesomeIcon icon={faFile} />
                    </ActionIcon>
                </Tooltip>
            </Table.Td>
            <Modal opened={opened} onClose={close} size="xl" title="Archivos">
                <Table styles={{
                    thead: {
                        backgroundColor: '#E9ECEF',
                    }
                }}>
                    <Table.Thead>
                        <Table.Tr>
                        <Table.Th>Tipo</Table.Th>
                        <Table.Th>Nombre del archivo</Table.Th>
                        <Table.Th></Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
            </Modal>
        </>
    );
};

export default FilesList;