import { CloseButton, Combobox, Input, InputBase, Loader, useCombobox } from '@mantine/core';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { ExtendedInputProps, ThirdPartyInfoDTO } from '../../commons/interfaces/SelectInterfaces';
import GlobalContext from '../providers/GlobalContext';
import ScopeContext from '../providers/ScopeContext';
import { ContextEnum } from '../../commons/enums/RequestContextEnum';
import { useUncontrolled } from '@mantine/hooks';


const ThirdPartySelect: React.FC<ExtendedInputProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ThirdPartyInfoDTO[]>([]);
    const [disabled, setDisabled] = useState<boolean>(false);
    const {globalContext} = useContext(GlobalContext);
    const {scope} = useContext(ScopeContext);

    const [_value, handleChange] = useUncontrolled({
        value : props.value,
        defaultValue: props.defaultValue,
        finalValue: 'Final',
        onChange: props.onChange,
    });

    useEffect(() => {
        if(globalContext.userInfo){
            const sessionInfo = globalContext.userInfo;
            if(sessionInfo.userType === 'SELLER'){
                setDisabled(props.thirdPartyContext !== 'sellers');
            }else if(sessionInfo.userType === 'ADVISOR'){
                setDisabled(props.thirdPartyContext !== 'advisors');
            }
        }
    },[globalContext.userInfo, props.thirdPartyContext]);

    function getAsyncData() {
        return new Promise<ThirdPartyInfoDTO[]>((resolve) => {
            let url = `${document.referrer}${globalContext.parentContext}/${props.thirdPartyContext}`;
            if(scope.scopeContext === ContextEnum.SEPARATED){
                url += '?action=fullData';
            }
            axios(
                {
                    url,
                    method: 'get',
                    withCredentials: true,
                }).then((response) => {
                    const data = response.data.map((item: any) => ({id: item.id, name: item.name}));
                    resolve(data);
                });
        });
    }

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownOpen: () => {
        if (data.length === 0 && !loading) {
            setLoading(true);
            getAsyncData().then((response) => {
                setData(response);
                setLoading(false);
                combobox.resetSelectedOption();
            });
        }
        },
    });

    const getThirdPartyName = (id: string) => {
        return data.find((item) => item.id === id)?.name || '';
    }

    const options = data.map((item) => (
        <Combobox.Option value={`${item.id}@${item.name}`} key={item.id}>
        {item.name}
        </Combobox.Option>
    ));

    return (
        <Combobox
        store={combobox}
        withinPortal={false}
        onOptionSubmit={(val) => {
            const [id, name] = val.split('@');
            handleChange(id);
            combobox.closeDropdown();
        }}
        >
        <Combobox.Target>
            <InputBase
            disabled={disabled}
            id={props.id}
            label={props.label}
            component="button"
            type="button"
            pointer
            rightSection={
                _value !== null ? (
                    <CloseButton
                        size="sm"
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={() => {
                            handleChange('');
                            }
                        }
                        aria-label="Clear value"
                    />
                    ) : (
                            loading ? <Loader size={18} /> : <Combobox.Chevron />
                        )
                }
            onClick={() => combobox.toggleDropdown()}
            rightSectionPointerEvents={_value === null ? 'none' : 'all'}
            >
            {getThirdPartyName(_value) || <Input.Placeholder></Input.Placeholder>}
            </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown>
            <Combobox.Options>
            {loading ? <Combobox.Empty>Loading....</Combobox.Empty> : options}
            </Combobox.Options>
        </Combobox.Dropdown>
    </Combobox>
    );
};

export default ThirdPartySelect;