import axios from 'axios';
import { Dispatch, SetStateAction, useCallback, useContext } from 'react';
import useNotifier from './useNotifier';
import { showLoadingOverlaySubject } from '../commons/utils/ActionsSubject';
import GlobalContext from '../components/providers/GlobalContext';

type ExecuteRequestConfig = {
    url: string;
    method?: string;
    body?: any;
    headers?: any;
    callback?: () => void;
    errorCallback?: () => void;
    silentFailure?: boolean;
}

type ExecuteRequestAndCallbackDataConfig = {
    url: string;
    method?: string;
    body?: any;
    headers?: any;
    callback?: (response: any) => void;
    errorCallback?: () => void;
}

type ExecuteRequestAndUpdateStateConfig = {
    url: string;
    method?: string;
    body?: any;
    stateToUpdate?: Dispatch<SetStateAction<any | undefined>>;
}

interface UseRequestResponse{
    executeRequest: (config: ExecuteRequestConfig) => void;
    executeRowRequest: (config: ExecuteRequestConfig) => void;
    executeRequestAndCallbackData: (config: ExecuteRequestAndCallbackDataConfig) => void;
    executeRequestAndUpdateState: (config: ExecuteRequestAndUpdateStateConfig) => void;
    executeRowRequestAndUpdateState: (config: ExecuteRequestAndUpdateStateConfig) => void;
}

const useRequest = (): UseRequestResponse => {
    const {globalContext} = useContext(GlobalContext);
    const { processAxiosError } = useNotifier();

    const executeRowRequest = useCallback((config: ExecuteRequestConfig) => {
        showLoadingOverlaySubject.next(true);
        axios({
            url: `${config.url}`,
            method: config.method,
            data: config.body,
            headers: config.headers,
            withCredentials: true
        }).then((response) => {
            config.callback && config.callback();
        }).catch((error) => {
            config.errorCallback && config.errorCallback();
            if(!config.silentFailure){
                processAxiosError(error);
            }
        }).finally(() => {
            showLoadingOverlaySubject.next(false);
        });
    }, [processAxiosError]);

    const executeRequest = useCallback((config: ExecuteRequestConfig) => {
        showLoadingOverlaySubject.next(true);
        axios({
            url: `${document.referrer}${globalContext.parentContext}${config.url}`,
            method: config.method,
            data: config.body,
            headers: config.headers,
            withCredentials: true
        }).then((response) => {
            config.callback && config.callback();
        }).catch((error) => {
            config.errorCallback && config.errorCallback();
            if(!config.silentFailure){
                processAxiosError(error);
            }
        }).finally(() => {
            showLoadingOverlaySubject.next(false);
        });
    }, [processAxiosError]);

    const executeRequestAndCallbackData = useCallback((config: ExecuteRequestAndCallbackDataConfig) => {
        showLoadingOverlaySubject.next(true);
        axios({
            url: `${document.referrer}${globalContext.parentContext}${config.url}`,
            method: config.method,
            data: config.body,
            headers: config.headers,
            withCredentials: true
        }).then((response) => {
            config.callback && config.callback(response.data);
        }).catch((error) => {
            config.errorCallback && config.errorCallback();
            processAxiosError(error);
        }).finally(() => {
            showLoadingOverlaySubject.next(false);
        });
    }, [processAxiosError]);

    const executeRequestAndUpdateState = useCallback((config: ExecuteRequestAndUpdateStateConfig) => {
        showLoadingOverlaySubject.next(true);
        axios({
            url: `${document.referrer}${globalContext.parentContext}${config.url}`,
            method: config.method,
            data: config.body,
            withCredentials: true
        }).then((response) => {
            config.stateToUpdate && config.stateToUpdate(response.data);
        }).catch((error) => {
            processAxiosError(error);
        }).finally(() => {
            showLoadingOverlaySubject.next(false);
        });
    }, [processAxiosError]);

    const executeRowRequestAndUpdateState = useCallback((config: ExecuteRequestAndUpdateStateConfig) => {
        showLoadingOverlaySubject.next(true);
        axios({
            url: `${config.url}`,
            method: config.method,
            data: config.body,
            withCredentials: true
        }).then((response) => {
            config.stateToUpdate && config.stateToUpdate(response.data);
        }).catch((error) => {
            processAxiosError(error);
        }).finally(() => {
            showLoadingOverlaySubject.next(false);
        });
    }, [processAxiosError]);

    return {
        executeRequest,
        executeRowRequest,
        executeRequestAndCallbackData,
        executeRequestAndUpdateState,
        executeRowRequestAndUpdateState
    };
};

export default useRequest;