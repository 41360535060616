import { FilterDataInterface, FilterParametersBaseDTO, FilterRequestDTO } from '../interfaces/MasterFilterInterfaces';

export function parseFormDataListToDTO(filterData: FilterDataInterface): FilterRequestDTO {

    const resultDTO: FilterParametersBaseDTO = {};
    for (const formData of filterData.formFields) {
        if(formData.value){
            resultDTO[formData.id] = formData.value;
        }
    }
    const filterReqDTO: FilterRequestDTO = {
        filterParametersBaseDTO: resultDTO,
        page: filterData.page,
        pageSize: filterData.pageSize,
        order: filterData.order
    };
    return filterReqDTO;
}