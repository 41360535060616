import React, { useContext } from "react";
import MasterFilter from "../Filters/MasterFilter";
import { FormFieldConfigInterface, ThreeStateFilterInterface } from "../../commons/interfaces/MasterFilterInterfaces";
import { TableBaseProps } from "../../commons/types/MasterTable";
import ScopeContext from "../providers/ScopeContext";
import { TableCheckActionProvider } from "../providers/TableCheckActionContext";

interface TodosProps {
  forwardedRef: React.MutableRefObject<{ setActiveMethod: () => void } | null>;
}

const Todos: React.FC<TodosProps> = ({forwardedRef}) => {
  const {scope, setScope} = useContext(ScopeContext);

  // Useful method to configure actions on tab selection
  const setActiveMethod = () => {
    console.log("[Todos] setActiveMethod");
    if(scope.lazyFetch){
      setScope((prevValue) => ({...prevValue, lazyFetch: false}));
    }
  }
  
  forwardedRef.current = { setActiveMethod };
  const threeStateConfig: FormFieldConfigInterface[] = [
    { id: "remision", label: "Remisión", options: [
      {label: "Todos", value: "A"},
      {label: "Si", value: "T"},
      {label: "No", value: "K"},
    ], defaultValue: "A", type: 'threeState-select', span: 1 },
    { id: "apartado", label: "Apartado", type: 'threeState-select', span: 1 },
    { id: "autoceo", label: "Auto C.E.O", type: 'threeState-select', span: 1 },
    { id: "incidencia", label: "Incidencia", type: 'threeState-select', span: 1 },
    { id: "autoP", label: "Auto P", type: 'threeState-select', span: 1 },
    { id: "autoS", label: "Auto S", type: 'threeState-select', span: 1 },
    { id: "entregados", label: "Entregados", type: 'threeState-select', span: 1 },
    { id: "facturados", label: "Facturados", options: [
      {label: "Todos", value: "T"},
      {label: "Si", value: "S"},
      {label: "No", value: "N"},
    ], type: 'threeState-select', span: 1 },
    { id: "produccion", label: "Producción", type: 'threeState-select', span: 1 },
  ];

  const formFieldsConfig: FormFieldConfigInterface[] = [
    {
      id: "idClientes",
      type: "client-select",
      label: "Cliente",
      span: 3,
    },
    {
      id: "idRuta", // Cambiar esto a forma de entrega. Esta clave es confusa ya que existe una definicion mas adecuada para rutas, son las rutas de entrega (Las de Ruta 1 y Ruta 2)
      type: "deliveryWay-select",
      label: "Ruta",
      span: 3,
    },
    { 
      id: "idVendedor",
      type: "thirdParty-select",
      label: "Vendedor",
      thirdPartyContext: "sellers",
      span: 3
    },
    {
      id: "idArticulo",
      type: "product-select",
      label: "Artículo",
      span: 3,
    },
    { id: "fechaDesde", type: "datePicker", label: "Inicial", span: 1.5 },
    { id: "fechaHasta", type: "datePicker", label: "Final", span: 1.5 },
    { id: "montoInicial", type: "number-input", label: "Monto Inicial", span: 1.5 },
    { id: "montoFinal", type: "number-input", label: "Monto Final", span: 1.5 },
    { 
      id: "idAsesor",
      type: "thirdParty-select",
      label: "Asesor C.",
      thirdPartyContext: "advisors",
      span: 3
    },
    {
      id: "sku",
      type: "productColor-select",
      label: "Color",
      span: 3,
    },
    {
      id: "authDescuento",
      type: "select",
      label: "Auth descuentos",
      span: 3,
      data: [
        {label: "En Proceso", value: '-1'},
        {label: "Rechazados", value: '0'},
        {label: "Autorizados", value: '1'},
        {label: "N-A", value: '-99'},
        ]
    },
    {
      id: "authCxC",
      type: "select",
      label: "Auth Cuentas x cobrar",
      span: 3,
      data: [
        {label: "En Proceso", value: '-1'},
        {label: "Rechazados", value: '0'},
        {label: "Autorizados", value: '1'},
        {label: "N-A", value: '-99'},
      ]
    },
  ];

  const buttonsConfig: TableBaseProps = {
    checkbox: true,
    p: {disabled: false, rightControlId: "BtnGridAutCxc", hideable: false, key: "p"},
    s: {disabled: false, rightControlId: "BtnGridAutCeo", hideable: false, key: "s"},
    t: {disabled: false, rightControlId: "BtnGridAutCeo", hideable: false, key: "t"},
    e: true,
    clienteEnRecepcion: {disabled: false, rightControlId: "BtnNotCliente", hideable: false, key: "clienteEnRecepcion"},
    apartarMercancia: {disabled: false, rightControlId: "BtnGridReq", hideable: false, key: "apartarMercancia"},
    editarCotizacion: {disabled: false, rightControlId: "BtnGridEdit", hideable: false, key: "editarCotizacion"},
    eventosMovimientos: {disabled: false, rightControlId: "BtnGridEventPed", hideable: false, key: "eventosMovimientos"},
    verArchivos: true,
    generarFacturas: {disabled: false, rightControlId: "BtnGridGenFact", hideable: false, key: "generarFacturas"},
    numeroPedido: {disabled: false, rightControlId: "BtnGridViewFact", columnOrderName: "pedido", hideable: false, key: "numeroPedido"},
    tipoPedido: true,
    cliente: {disabled: false, columnOrderName: "Nomcte", hideable: true, key: "cliente"},
    fecha: {disabled: false, columnOrderName: "fecha", hideable: true, key: "fecha"},
    fechaApartado: {disabled: false, columnOrderName: "apartado_fecha", hideable: true, key: "fechaApartado"},
    fechaEntrega: {disabled: false, columnOrderName: "apartado_fecha", hideable: true, key: "fechaEntrega"},
    pedCliente: {disabled: false, columnOrderName: "Nomcte", hideable: true, key: "pedCliente"},
    factura: {disabled: false, columnOrderName: "porfac", hideable: true, key: "factura"},
    ruta: {disabled: false, columnOrderName: "ruta", hideable: true, key: "ruta"},
    subtotal: {disabled: false, columnOrderName: "SubTotal", hideable: true, key: "subtotal"},
    iva: {disabled: false, columnOrderName: "IVA", hideable: true, key: "iva"},
    total: {disabled: false, columnOrderName: "Total", hideable: true, key: "total"},
    vendedor: {disabled: false, columnOrderName: "nombre_vendedor", hideable: true, key: "vendedor"},
    acciones: true,
  };

  return (
    <TableCheckActionProvider>
      <MasterFilter
        buttonsConfig={buttonsConfig}
        filtersConfig={formFieldsConfig}
        threeStateConfig={threeStateConfig}
        />
    </TableCheckActionProvider>
  );
};

export default Todos;
