import { ActionIcon, Table, Tooltip } from '@mantine/core';
import React, { useState } from 'react';
import { getButtonTStyles } from '../../../commons/utils/ActionButtonsStyleHelper';
import { IPedido } from '../../../commons/interfaces/Pedidos';
import useRequest from '../../../hooks/useRequest';

interface ActionButtonAutoCEOProps {
    request: IPedido;
}

const ActionButtonAutoCEO: React.FC<ActionButtonAutoCEOProps> = (props) => {
    const [request, setRequest] = useState<IPedido>(props.request);
    const { executeRequest: executeRequestAndCallback } = useRequest();

    const executeTableRefresh = () => {
        setRequest((prevRequest) => ({...prevRequest, autoCeo: prevRequest.autoCeo === 0 ? 1 : 0}));
    }

    const handleAction = () => {
        const requestBody = {
            pedido: request.idPedido,
            tkmov: request.tkmov,
            empresa: request.idEmpresa,
            }
        executeRequestAndCallback({url: `/sales/quotation-actions?action=auth-ceo`, method: 'POST', body: requestBody, callback: executeTableRefresh});
    }

    return (
        <Table.Td pr={3} pl={3}>
            <Tooltip label="Autorizar remisión">
                <ActionIcon variant="light" size="sm" onClick={handleAction} aria-label="Settings" style={getButtonTStyles(request)}>
                    T
                </ActionIcon>
            </Tooltip>
        </Table.Td>
    );
};

export default ActionButtonAutoCEO;