import React, { useContext } from 'react';
import ScopeContext from '../../providers/ScopeContext';
import { ContextEnum } from '../../../commons/enums/RequestContextEnum';
import { ActionIcon, Flex, Text } from '@mantine/core';
import Currency from '../../currency/Currency';
import { ITotalSum } from '../../../commons/interfaces/Pedidos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';

interface TotalSectionProps {
    totals: ITotalSum;
    onRefreshCallBack: () => void;
}

const TotalSection: React.FC<TotalSectionProps> = (props) => {
    const { scope } =useContext(ScopeContext);

    return (
        <>
        { scope.scopeContext !== ContextEnum.SEPARATED && (

                <Flex
                mih={50}
                gap="md"
                justify="flex-end"
                align="center"
                direction="row"
                wrap="wrap"
                >
                    <Text fw={700}>Totales</Text>
                    <Currency amount={props.totals?.subTotal || 0} />
                    <Currency amount={props.totals?.iva || 0} />
                    <Currency amount={props.totals?.total || 0} />
                    <ActionIcon onClick={props.onRefreshCallBack} variant="filled" aria-label="Settings" size="sm">
                        <FontAwesomeIcon icon={faArrowsRotate} />
                    </ActionIcon>
                </Flex>
            )}
        </>
    );
};

export default TotalSection;