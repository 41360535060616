import { Anchor, Divider, Drawer, Flex, Grid, Table, Text, Tooltip } from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react';
import useAxios from '../../../hooks/useAxios';
import { useDisclosure } from '@mantine/hooks';
import { IPedido, IPedidoBaseDetailDTO } from '../../../commons/interfaces/Pedidos';
import Currency from '../../currency/Currency';
import ScopeContext from '../../providers/ScopeContext';
import { ContextEnum } from '../../../commons/enums/RequestContextEnum';
import { getButtonRequestIdStyles } from '../../../commons/utils/ActionButtonsStyleHelper';
import { fontStyles } from '../../../styles';
import useRights from '../../../hooks/useRights';
import { FullTableActionProps } from '../../../commons/types/MasterTable';
import DeliveryProofViewer from './DeliveryProof';
import useLegacyStorage from '../../../hooks/useLegacyStorage';
import LogisticsStatus from './LogisticsStatus';

interface DetailsProps {
    request: IPedido;
    controlInfo: FullTableActionProps;
}

interface AmountDetail{
    subTotal: number;
    iva: number;
    total: number;
}

const RequestDetails: React.FC<DetailsProps> = ({request, controlInfo}) => {
    const {showControlledInput} = useRights();
    const {getResourceUrl} = useLegacyStorage();
    const { scope } = useContext(ScopeContext);
    const [details, setDetails] = useState<IPedidoBaseDetailDTO>();
    const [amountDetail, setAmountDetail] = useState<AmountDetail | null >(null);

    const { data, executeRequest, loading } = useAxios({});

    const [opened, { open, close }] = useDisclosure(false, {
        onOpen: () => executeRequest(`/sales/request-detail?pedido=${request.idPedido}&tkmov=${request.tkmov}&empresa=${request.idEmpresa}`, 'get'),
    });

    useEffect(() => {
        if(data){
            setDetails(data);
            calculateSubtotal();
            console.log('[RequestDetails] data ', data);
            console.log('[RequestDetails] request ', request);
        }
    }, [data]);

    useEffect(() => {
        if(details){
            calculateSubtotal();
        }
    }, [details]);

    const calculateSubtotal = () => {
        let subTotal = 0
        let iva = 0;
        let total = 0;

        subTotal = details?.productDetails.map((detail, index) => {
            const precio = detail.precio - (detail.precio * (detail.desco / 100));
            let itemTotal = detail.cantidad * precio;
            return itemTotal;
        }).reduce((prev, curr) => prev + curr, 0) || 0;


        if(details?.decto1 !== 0){
            subTotal = applyDiscount(details?.decto1 || 0, subTotal);
        }
        if(details?.decto2 !== 0){
            subTotal = applyDiscount(details?.decto2 || 0, subTotal);
        }
        if(details?.decto3 !== 0){
            subTotal = applyDiscount(details?.decto3 || 0, subTotal);
        }

        if(details?.tkmov === 'K' && details.opciva === 'N'){
            iva = subTotal * details?.tasaIva;
            total = subTotal + iva;
        }
        setAmountDetail({subTotal, iva, total});
    }

    const applyDiscount = (discount: number, subTotal: number) => {
        return subTotal - (subTotal * (discount / 100));
    }

    const rows = (details as IPedidoBaseDetailDTO)?.productDetails.map((productDetail, index) => (
        <Table.Tr key={index}>
            <Table.Td>{productDetail.idArticulo}</Table.Td>
            <Table.Td>{productDetail.color}</Table.Td>
            <Table.Td>
                <img src={getResourceUrl(productDetail.url, productDetail.urlLegacy)} width="50" alt='X'/>
            </Table.Td>
            <Table.Td>{productDetail.descripcion}</Table.Td>
            <Table.Td>{productDetail.cantidad}</Table.Td>
            {scope.scopeContext !== ContextEnum.SEPARATED &&
                <>
                    <Table.Td>{productDetail.cantidadDespachada}</Table.Td>
                    <Table.Td>{productDetail.diferencia}</Table.Td>
                    <Table.Td><Currency amount={productDetail.precio}/></Table.Td>
                    <Table.Td>{productDetail.desco}%</Table.Td>
                    <Table.Td><Currency amount={productDetail.precio - productDetail.precio * (productDetail.desco /100)}/></Table.Td>
                    <Table.Td><Currency amount={(productDetail.precio - productDetail.precio * (productDetail.desco /100)) * productDetail.cantidad}/></Table.Td>
                </>
            }
        </Table.Tr>
    ));

    return (
        <Table.Td  style={fontStyles.table}>
            <Drawer
                offset={0}
                opened={opened}
                onClose={close}
                position="right"
                size="80%"
                title="Detalles del pedido"
            >
                {details &&
                <>
                    <Grid gutter="xs">
                        <Grid.Col span={2}>
                            <Text fw={700}>No Pedido</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>{request.idPedido}</Grid.Col>

                        <Grid.Col span={2}>
                            <Text fw={700}>Vendedor</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>{request.nombreVendedor}</Grid.Col>

                        <Grid.Col span={2}>
                            <Text fw={700}>Empresa</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>{details.razonsocial}</Grid.Col>

                        <Grid.Col span={2}>
                            <Text fw={700}>Surte</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>{details.cedisAlmacen}</Grid.Col>

                        <Grid.Col span={2}><Text fw={700}>Fecha</Text></Grid.Col>
                        <Grid.Col span={4}>{details.fecha}</Grid.Col>

                        <Grid.Col span={2}><Text fw={700}>Ruta</Text></Grid.Col>
                        <Grid.Col span={4}>{details.rutaFor}</Grid.Col>

                        <Grid.Col span={2}><Text fw={700}>Cliente</Text></Grid.Col>
                        <Grid.Col span={4}>{details.nombrecte}</Grid.Col>

                        <Grid.Col span={2}><Text fw={700}>Producción</Text></Grid.Col>
                        <Grid.Col span={4}>{details.opcProd}</Grid.Col>

                        <Grid.Col span={2}><Text fw={700}>Paqueteria</Text></Grid.Col>
                        <Grid.Col span={4}>{details.paqueteria}</Grid.Col>

                        <Grid.Col span={2}><Text fw={700}>N° Cajas</Text></Grid.Col>
                        <Grid.Col span={4}>{details.cajasEmpaque}</Grid.Col>
                        {/* <Grid.Col span={6}></Grid.Col> */}

                        <Grid.Col span={2}><Text fw={700}>Suc de entrega:</Text></Grid.Col>
                        <Grid.Col span={10}>
                            {details.deliveryAddressInfo && 
                                <Flex justify="flex-start" direction="row" wrap="wrap">
                                    <Text c='dimmed'>Calle:</Text>&nbsp;{details.deliveryAddressInfo.street}&nbsp;
                                    <Text c='dimmed'>&nbsp;/ N. Ext:</Text>&nbsp;{details.deliveryAddressInfo.extNumber}&nbsp;
                                    <Text c='dimmed'>&nbsp;/ N. Int:</Text>&nbsp;{details.deliveryAddressInfo.intNumber}&nbsp;
                                    <Text c='dimmed'>&nbsp;/ Municipio:</Text>&nbsp;{details.deliveryAddressInfo.municipality}&nbsp;
                                    <Text c='dimmed'>&nbsp;/ Colonia:</Text>&nbsp;{details.deliveryAddressInfo.colony}&nbsp;
                                    <Text c='dimmed'>&nbsp;/ Ciudad:</Text>&nbsp;{details.deliveryAddressInfo.city}&nbsp;
                                    <Text c='dimmed'>&nbsp;/ Estado:</Text>&nbsp;{details.deliveryAddressInfo.state}&nbsp;
                                </Flex>
                            }

                        </Grid.Col>
                    </Grid>
                    <Divider my="xs" label="Detalle" labelPosition="center" />
                    <Table>
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th>Clave</Table.Th>
                                <Table.Th>Color</Table.Th>
                                <Table.Th>Img</Table.Th>
                                <Table.Th w={'400'}>Descripción</Table.Th>
                                <Table.Th>Cant.</Table.Th>
                                {scope.scopeContext !== ContextEnum.SEPARATED &&
                                    <>  
                                        <Table.Th>Surtido</Table.Th>
                                        <Table.Th>Dif.</Table.Th>
                                        <Table.Th>Prec. Unit</Table.Th>
                                        <Table.Th>Dcto</Table.Th>
                                        <Table.Th>Prec. c Dcto</Table.Th>
                                        <Table.Th>Importe</Table.Th>
                                    </>
                                }
                            </Table.Tr>
                        </Table.Thead>
                            <Table.Tbody>{rows}</Table.Tbody>
                    </Table>
                    <Divider my="xs" />
                    { scope.scopeContext !== ContextEnum.SEPARATED &&
                    <Flex justify="flex-end" align="flex-end" direction="column">
                        <Text>Subtotal: <Currency amount={amountDetail?.subTotal || 0}/></Text>
                        <Text>I.V.A: <Currency amount={amountDetail?.iva || 0}/></Text>
                        <Text fw={700}>Total: <Currency amount={amountDetail?.total || 0}/></Text>
                    </Flex>
                    }
                    <Divider my="xs" />
                    <Grid gutter="xs">
                        <Grid.Col span={2}>
                            <Text fw={700}>I.V.A Incluído</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>{details.opciva === 'S' ? 'Si': 'No'}</Grid.Col>

                        <Grid.Col span={2}>
                            <Text fw={700}>Moneda</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>{details.moneda ? 'Moneda nacional': 'Divisa extrangera'}</Grid.Col>

                        <Grid.Col span={2}>
                            <Text fw={700}>Fecha Entrega</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>{details.fechaEnt}</Grid.Col>

                        <Grid.Col span={2}>
                            <Text fw={700}>T.C</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>{details.tc}</Grid.Col>

                        <Grid.Col span={2}><Text fw={700}>Fec. Cancelación</Text></Grid.Col>
                        <Grid.Col span={4}>{details.fechaCan}</Grid.Col>

                        <Grid.Col span={2}><Text fw={700}>Req. Valid.</Text></Grid.Col>
                        <Grid.Col span={4}>{details.opcTk === 'K' ? 'No': 'Si'}</Grid.Col>

                        <Grid.Col span={2}><Text fw={700}>Forma de entrega</Text></Grid.Col>
                        <Grid.Col span={4}>{details.idFentrega}</Grid.Col>

                        <Grid.Col span={2}><Text fw={700}>Flete</Text></Grid.Col>
                        <Grid.Col span={4}>{details.flete ? details.flete: '-'}</Grid.Col>

                        <Grid.Col span={2}><Text fw={700}>Método Pago</Text></Grid.Col>
                        <Grid.Col span={4}>{details.metPago}</Grid.Col>

                        <Grid.Col span={2}><Text fw={700}>Observaciones</Text></Grid.Col>
                        <Grid.Col span={4}>{details.notas}</Grid.Col>

                        <Grid.Col span={2}><Text fw={700}>Uso de CFDI</Text></Grid.Col>
                        <Grid.Col span={4}>{details.usoCfdi}</Grid.Col>
                    </Grid>
                    {(details.invoice !== 0 || details.deliveryProof) &&
                        <DeliveryProofViewer request={request} requestAdditionalData={details} deliveryProof={details.deliveryProof}/>
                    }
                    { scope.scopeContext === ContextEnum.ACTIVE_REQUEST &&
                    <>
                        <Divider my="xs" />
                        <LogisticsStatus request={request}/>
                    </>
                    }
                </>
            }
            </Drawer>
            <Tooltip label="Ver detalle del pedido">
                { showControlledInput(controlInfo) ? (
                    <Anchor onClick={open} target="_blank">
                        <Text size="sm" styles={getButtonRequestIdStyles(request)}>{request.opcIncidencia === 1 ? `${request.idPedido}-W`: request.idPedido}</Text>
                    </Anchor>
                ) : (
                    <Text size="sm" styles={getButtonRequestIdStyles(request)}>{request.opcIncidencia === 1 ? `${request.idPedido}-W`: request.idPedido}</Text>
                )}
                
            </Tooltip>
        </Table.Td>
    );
};

export default RequestDetails;