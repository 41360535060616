import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { ExtendedInputProps } from '../../commons/interfaces/SelectInterfaces';
import { NumberInput } from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';


const AmountInput: React.FC<ExtendedInputProps> = ({id, label, value, defaultValue, onChange}) => {

    const [_value, handleChange] = useUncontrolled({
      value,
      defaultValue,
      finalValue: 'Final',
      onChange,
    });

    const handleNumberInputChange = (val: string | number) => {
      if (typeof val === 'number') {
        handleChange(val.toString()); // Convertimos a string si es un número
      } else {
        handleChange(val); // Mantenemos el valor como string
      }
    };


    return (
        <NumberInput
          id={id}
          label={label}
          rightSection={<FontAwesomeIcon icon={faDollarSign} />}
          decimalSeparator="."
          thousandSeparator=","
          decimalScale={2}
          value={_value}
          onChange={handleNumberInputChange}
        />
      );
};

export default AmountInput;