import { Box, Button, Group, Modal, TextInput } from "@mantine/core";
import ClientSimpleSelect from "../select/ClientSimpleSelect";
import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useLocation } from "react-router-dom";
import { notifications } from "@mantine/notifications";

interface ModalCopyOrderProps {
  opened: boolean;
  onClose: () => void;
}

export const ModalCopyOrder: React.FC<ModalCopyOrderProps> = ({
  opened,
  onClose,
}) => {
  const [idCliente, setIdCliente] = useState<string>();
  const [idPedido, setIdPedido] = useState<string>();
  const { data, executeRequest } = useAxios({});

  const handleSelectChange = (id: any) => {
    setIdCliente(id);
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tkmov = queryParams.get("tkmov");

  const copyOrder = () => {
    const requestData = {
      clientId: idCliente,
      pedido: idPedido,
      tkmov: tkmov,
    };
    executeRequest("/sales/copiar-pedido", "post", requestData);
  };

  useEffect(() => {
    if (data) {
      notifications.show({
        title: "Copiar pedido",
        message: data.message,
        color: "green",
      });
      onClose();
    }
  }, [data]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Copiar Pedido"
      centered
      size="md"
    >
      <Box>
        <ClientSimpleSelect
          id="clienteCopiarPedido"
          label="Cliente"
          onChange={(e) => handleSelectChange(e)}
        />
        <TextInput
          label="# Pedido"
          onChange={(e) => setIdPedido(e.target.value)}
        />
        <Group justify="flex-end" mt="md">
          <Button onClick={copyOrder}>Copiar</Button>
          <Button onClick={onClose}>Cerrar</Button>
        </Group>
      </Box>
    </Modal>
  );
};
