import { Flex, Skeleton, Text } from '@mantine/core';
import React from 'react';

interface TotalSectionSkeletonProps {
    // Define any props you need for your component here
}

const TotalSectionSkeleton: React.FC<TotalSectionSkeletonProps> = () => {
    return (
        <div>
            <Flex
                mih={50}
                gap="md"
                justify="flex-end"
                align="center"
                direction="row"
                wrap="wrap"
                >
                    <Skeleton height={20} width={50}/>
                    <Skeleton height={20} width={50}/>
                    <Skeleton height={20} width={50}/>
                    <Skeleton height={20} width={50}/>
                    <Skeleton height={20} width={15}/>
                </Flex>
        </div>
    );
};

export default TotalSectionSkeleton;