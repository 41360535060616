import { Button, Divider, Flex, Grid, Table, Text } from "@mantine/core";
import { IPedido } from "../../../commons/interfaces/Pedidos";
import { ResupplyRSDTO } from "../../../commons/interfaces/Movements";

interface ErrorModalContentProps {
    onClose: () => void;
    pedido: IPedido;
    resupplyResponse: ResupplyRSDTO;
}

const ErrorModalContent: React.FC<ErrorModalContentProps> = ({ onClose, pedido, resupplyResponse }) => {
    return (
    <>
        <Divider my="sm" />
        <Text fw={700} c={'red'}>No se puede continuar con el pedido.</Text>
        <Grid pt={20} gutter="lg" justify="center">
            {resupplyResponse && resupplyResponse.needSupply &&
                <Grid.Col span={12}>
                    <Text fw={700} c="red">{`Imposible continuar con el pedido ${pedido.idPedido}, alguno de los articulos relacionados tiene stock insuficiente:`}</Text>
                </Grid.Col>
            }

            <Grid.Col span={12}>
                <Table>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>id Articulo</Table.Th>
                            <Table.Th>Detalle requisicion</Table.Th>
                        </Table.Tr>
                    </Table.Thead>

                    {resupplyResponse && resupplyResponse.outOfStockDetail &&
                        <Table.Tbody>
                            {resupplyResponse.outOfStockDetail.map(detail => {
                                return (
                                    <Table.Tr key={detail.productId}>
                                        <Table.Td>{detail.productId}</Table.Td>
                                        <Table.Td>{detail.message}</Table.Td>
                                    </Table.Tr>
                                )
                            })}
                        </Table.Tbody>
                    }
                </Table>
            </Grid.Col>

        </Grid>
        <Divider my="sm" />
        <Flex
            mih={50}
            gap="md"
            justify="center"
            align="flex-end"
            direction="row"
            wrap="wrap"
        >
            <Button variant="danger" onClick={() => onClose()}>Entendido</Button>
        </Flex>
    </>
    );
}

export default ErrorModalContent;