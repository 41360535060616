import { CheckIcon, Combobox, Group, Loader, Pill, PillsInput, useCombobox } from '@mantine/core';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { fetchProductColors } from '../../../commons/utils/ActionsSubject';
import { ProductInfoDetailDTO } from '../../../commons/interfaces/Products';
import { ExtendedInputProps } from '../../../commons/interfaces/SelectInterfaces';
import GlobalContext from '../../providers/GlobalContext';
import { useUncontrolled } from '@mantine/hooks';
import ScopeContext from '../../providers/ScopeContext';

const ProductColorSelect: React.FC<ExtendedInputProps> = (props) => {
    const [value, setValue] = useState<Map<number, string>>(new Map<number, string>());
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ProductInfoDetailDTO[]>([]);
    const [search, setSearch] = useState('');
    const [selectedProduct, setSelectedProduct] = useState<string>('');
    const [disabled, setDisabled] = useState(true);
    const {globalContext,} = useContext(GlobalContext);
    const {scope} = useContext(ScopeContext);

    const [_value, handleChange] = useUncontrolled({
        value: props.value,
        defaultValue: props.defaultValue,
        finalValue: 'Final',
        onChange: props.onChange,
    });

    function getAsyncData(productId: string) {
        return new Promise<ProductInfoDetailDTO[]>((resolve) => {
            
            if(productId){
                axios(
                    {
                        url: `${document.referrer}${globalContext.parentContext}/products?action=find-product-colors&productId=${productId}`,
                        method: 'get',
                        withCredentials: true,
                    }).then((response) => {
                        const data = response.data;
                        if(data){
                            resolve(data);
                        }else{
                            resolve([]);
                        }
                    }).catch((error) => {
                        resolve([]);
                    });
            }
            });
        }

    useEffect(() => {
        const subscription = fetchProductColors.subscribe((payloadValue) => {
            if (payloadValue?.scopeContext === scope.scopeContext && payloadValue.productId) {
                setSelectedProduct(payloadValue.productId);
                setDisabled(false);
            }else{
                setSearch('');
                setDisabled(true);
            }
            setValue(new Map<number, string>());
            setData([]);
        });
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownOpen: () => {
            if (data.length === 0 && !loading) {
                setLoading(true);
                getAsyncData(selectedProduct).then((response) => {
                    setData(response);
                    setLoading(false);
                    combobox.resetSelectedOption();
                });
            }
        },
    });

    useEffect(() => {
        if(value.size !== 0){
            const values: number[] = Array.from(value).map(([key, value]) => key);
            // const skus = data.filter((item) => values.includes(item.sku)).map((item) => item.sku);
            handleChange(values.join(','));
        }else{
            handleChange('');
        }
    }, [value]);

    const handleValueSelect = (val: string) => {
        const splitVal = val.split("@");
        const productId = Number(splitVal[0]);
        const productColor = splitVal[1];
        setValue((prevMap) => new Map(prevMap.set(productId, productColor)));
        setSearch('');
    }


    const handleValueRemove = (key: number) =>{
        const colorKey = key;
        setValue((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.delete(colorKey);
            return newMap;
        });
    }

    const values = Array.from(value).map(([key, value]) => (
        <Pill key={`${key}@${value}`} withRemoveButton onRemove={() => handleValueRemove(key)}>
            {value}
        </Pill>
    ));

    const options = data
        .filter((item) => item.color.toLowerCase().includes(search.trim().toLowerCase()))
            .map((item) => (
            <Combobox.Option value={`${item.sku}@${item.color}`} key={`${item.sku}@${item.color}`} active={value.has(item.sku)}>
                <Group gap="sm">
                {value.has(item.sku) ? <CheckIcon size={12} /> : null}
                <span>{item.color}</span>
                </Group>
            </Combobox.Option>
            ));

    return (
            <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false}>
            <Combobox.DropdownTarget>
                <PillsInput
                id={props.id}
                label={props.label}
                disabled={disabled}
                rightSection={loading ? <Loader size={18} /> : <Combobox.Chevron />}
                onClick={() => combobox.openDropdown()}>
                <Pill.Group>
                    {values}
                    <Combobox.EventsTarget>
                    <PillsInput.Field
                        onFocus={() => combobox.openDropdown()}
                        onBlur={() => combobox.closeDropdown()}
                        value={search}
                        onChange={(event) => {
                            combobox.updateSelectedOptionIndex();
                            setSearch(event.currentTarget.value);
                            }}
                        onKeyDown={(event) => {
                            if (event.key === 'Backspace' && search.length === 0) {
                                event.preventDefault();
                                //handleValueRemove(value[value.length - 1]);
                            }
                        }}
                    />
                    </Combobox.EventsTarget>
                </Pill.Group>
                </PillsInput>
            </Combobox.DropdownTarget>
        
            <Combobox.Dropdown>
                <Combobox.Options>
                {!disabled && options.length > 0 ? options : <Combobox.Empty>Loading...</Combobox.Empty>}
                </Combobox.Options>
            </Combobox.Dropdown>
            </Combobox>
        );
};

export default ProductColorSelect;