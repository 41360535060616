import { useCallback, useContext } from "react";
import GlobalContext from "../components/providers/GlobalContext";
import { FullTableActionProps } from "../commons/types/MasterTable";


interface UseRequestResponse{
    showControlledInput: (controlProps: FullTableActionProps) => boolean;
}

const useRights = (): UseRequestResponse => {
    const {globalContext} = useContext(GlobalContext);
    
    const showControlledInput = useCallback((controlProps: FullTableActionProps) => {
        const rights = globalContext.userInfo?.rights;
        if(rights && rights.has(controlProps.rightControlId)){
            const show: boolean = (rights.get(controlProps.rightControlId) || 0) === 1;
            return show && !controlProps.disabled;
        }
        return !controlProps.disabled;
    }, [globalContext]);

    return {
        showControlledInput
    };
};

export default useRights;