import React, { useEffect, useState } from 'react';
import { IPedido } from '../../commons/interfaces/Pedidos';

export interface SelectedRequestsContent {
    checkedRequests: IPedido[];
    setCheckedRequests: React.Dispatch<React.SetStateAction<IPedido[]>>;
}

const defaultValue: SelectedRequestsContent = {
    checkedRequests: [],
    setCheckedRequests: () => {} // Función vacía como valor predeterminado
};

const TableCheckActionContext = React.createContext<SelectedRequestsContent>(defaultValue);

type TableCheckActionProviderProps = {
    children?: React.ReactNode
};

  // Este componente provee el contexto para la selección de pedidos en la tabla
export const TableCheckActionProvider: React.FC<TableCheckActionProviderProps> = ({ children }) => {
    const [checkedRequests, setCheckedRequests] = useState<IPedido[]>([]);

    return (
        <TableCheckActionContext.Provider value={{ checkedRequests, setCheckedRequests }}>
        {children}
        </TableCheckActionContext.Provider>
    );
};

export default TableCheckActionContext;