import { Button, Divider, Flex, Grid, Modal, NumberInput, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { getFormattedCcy } from "../../commons/utils/Formatters";
import { useForm } from "@mantine/form";
import useAxios from "../../hooks/useAxios";
import { IPedido } from "../../commons/interfaces/Pedidos";

interface ModalAnticipoProps {
    openModal: boolean;
    continueCallback: () => void;
    onCloseCallback: () => void;
    totalAnticipo: number;
    pedido: IPedido;
}

export const ModalAnticipo: React.FC<ModalAnticipoProps> = ({
    openModal,
    continueCallback,
    onCloseCallback,
    totalAnticipo,
    pedido
}) => {

    const { data, executeRequest, loading, responseError } = useAxios({});
    const [opened, { open, close }] = useDisclosure(openModal);

    const form = useForm({
        initialValues: { montoAnticipo: 0 },
        validate: {
            montoAnticipo: (value) => {
                return (value < 0 || value > pedido.total || value === 0 ? 'Ingresa un monto valido' : null)
            },
        },

    });

    const onSubmit = (amount: number) => {
        if (amount >= 0) {
            executeRequest(`/sales/anticipos?action=select-anticipo`, "post",
                {
                    pedido: pedido.idPedido,
                    tkmov: pedido.tkmov,
                    empresa: pedido.idEmpresa,
                    idCliente: pedido.idCliente,
                    montoAnticipo: amount
                });
            continueCallback();
        }
    }

    const continueWithout = () => {
        continueCallback();
    }

    const onClose = () => {
        onCloseCallback();
    }


    return (
        <>
            <Modal opened={opened} onClose={onClose} title="¿Deseas utilizar anticipo?">
                <Divider my="sm" />
                <form onSubmit={form.onSubmit((values) => onSubmit(Number(values.montoAnticipo)))}>
                    <Grid gutter="lg" justify="center">
                        <Grid.Col span={12}>
                            <Text fw={700}>{`¡El cliente tiene ${getFormattedCcy(totalAnticipo || 0)} anticipo!`}</Text>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Text >{`El total del pedido es de ${getFormattedCcy(pedido.total || 0)} y total de anticipos es de ${getFormattedCcy(totalAnticipo || 0)}, escribir la cantidad utilizar de anticipo:`}</Text>
                        </Grid.Col>

                        <Grid.Col span={6} >
                            <NumberInput
                                label="Monto del anticipo"
                                placeholder="Monto"
                                decimalSeparator="."
                                thousandSeparator=","
                                decimalScale={2}
                                max={pedido.total}
                                {...form.getInputProps('montoAnticipo')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>

                        </Grid.Col>

                    </Grid>
                    <Divider my="sm" />
                    <Flex
                        mih={50}
                        gap="md"
                        justify="center"
                        align="flex-end"
                        direction="row"
                        wrap="wrap"
                    >
                        <Button variant="danger" onClick={() => continueWithout()}>No</Button>
                        <Button variant="primary" type="submit">Si, aplicar anticipo</Button>
                    </Flex>
                </form>
            </Modal>
        </>
    );
}