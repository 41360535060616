import { Menu, Text } from '@mantine/core';
import React, { useCallback, useContext } from 'react';
import { IPedido } from '../../../commons/interfaces/Pedidos';
import { modals } from '@mantine/modals';
import ScopeContext from '../../providers/ScopeContext';
import { refreshTable } from '../../../commons/utils/ActionsSubject';

interface ItemMarkAsLostProps {
    request: IPedido;
}

const ItemMarkAsLost: React.FC<ItemMarkAsLostProps> = ({request}) => {
    const {scope} = useContext(ScopeContext);


    const markAsLost = useCallback(() => {
        refreshTable.next({scopeContext: scope.scopeContext})
    }, [scope]);

    const showModal = useCallback(() => {
        modals.openContextModal({
            modal: 'markAsLostOrActivate',
            title: 'Cotización Perdida',
            innerProps: {
                multiRequests: false,
                request: [request],
                confirmCallback: markAsLost,
            },
            });
    }, [markAsLost, request]);

    return (
        <>
        <Menu.Item onClick={showModal}>
            <Text size="sm">Cotización perdida</Text>
        </Menu.Item>
        </>
    );
};

export default ItemMarkAsLost;