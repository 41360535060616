import React from 'react';
import { ContextModalProps } from '@mantine/modals';
import { Button, Flex, rem, Space } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

const ConfirmContextModal = ({
    context,
    id,
    innerProps,
    }: ContextModalProps<{ modalBody: React.ReactNode, confirmCallback: () => void }>) => {

        const handleConfirmCallback = () => {
            innerProps.confirmCallback();
            context.closeModal(id);
        }

        return (
        <>
            <Flex
                mih={50}
                gap="md"
                justify="center"
                align="center"
                direction="column"
                wrap="wrap"
            >
                <FontAwesomeIcon style={{ width: rem(50), height: rem(50), color: "#FFD43B", }} icon={faCircleExclamation} />
            <Space h="md" />
            {innerProps.modalBody}
            </Flex>
            <Space h="md" />
            <Flex
                mih={50}
                gap="md"
                justify="center"
                align="flex-end"
                direction="row"
                wrap="wrap"
            >
                <Button mt="md" onClick={() => context.closeModal(id)}>
                    NO
                </Button>
                <Button mt="md" onClick={handleConfirmCallback}>
                    SI
                </Button>
            </Flex>
            
        </>
        );
    }

export default ConfirmContextModal;