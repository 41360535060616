import { Button, Divider, Flex, Select, Text } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { useCallback, useState } from "react";
import useRequest from "../../../hooks/useRequest";
import { IPedido } from "../../../commons/interfaces/Pedidos";

const MarkAsLostOrActiveContextModal = ({
    context,
    id,
    innerProps,
}: ContextModalProps<{ multiRequests: boolean, reactivate: boolean, request: IPedido[], confirmCallback: () => void}>) => {
    const {multiRequests, reactivate = false, request, confirmCallback} = innerProps;
    const motivosMaskAsLost = ['Motivo 1','Motivo 2','Motivo 3'];
    const motivosReactivate = ['Motivo 1','Motivo 2','Motivo 3'];
    const [motivo, setMotivo] = useState(reactivate  ? motivosReactivate[0]: motivosMaskAsLost[0]);
    const {executeRequest, executeRequestAndCallbackData} = useRequest();

    const handleConfirmCallback = () => {
        if(multiRequests){
            markAsLostOrActiveBatch();
        }else{
            markAsLostOrActiveSingle();
        }
        context.closeModal(id);
    }

    const markAsLostOrActiveSingle = useCallback(() => {
        const payload = {
            pedido: request[0].idPedido,
            tkmov: request[0].tkmov,
            empresa: request[0].idEmpresa,
            motivo: motivo
            };
        const actionPrefix = reactivate ? 'markAsActive': 'markAsLost';
        executeRequest({
            url: `/sales/quotation-actions?action=${actionPrefix}-single`,
            method: 'POST',
            body: payload,
            callback: confirmCallback
        });
    }, [executeRequest, request, motivo, confirmCallback]);

    const markAsLostOrActiveBatch = useCallback(() => {
        const requestArray = request.map((request) => ({
            pedido: request.idPedido,
            tkmov: request.tkmov,
            empresa: request.idEmpresa,
            motivo: motivo
        }));
        const actionPrefix = reactivate ? 'markAsActive': 'markAsLost';
        executeRequestAndCallbackData({
            url: `/sales/quotation-actions?action=${actionPrefix}-batch`,
            method: 'post',
            body: {requestsForMark: requestArray, motivo: motivo},
            callback: confirmCallback
        });
    }, [request, executeRequestAndCallbackData, motivo, confirmCallback]);

    const getPrimaryButtonText = () => {
        const textContextPrefix = reactivate ? 'Reactivar ': 'Cerrar ';
        const textContextSuffix = multiRequests ? 'cotizaciones': 'cotización';
        return textContextPrefix + textContextSuffix
    }

    return (
    <>
        <Divider my="sm" />
        { reactivate ? (
            <Text>Se procedera con la reactivacion {multiRequests? 'de las cotizaciones': 'de la cotización'}</Text>
        ) : (
            <Select 
            label="Motivo"
            value={motivo}
            data={motivosMaskAsLost}
            allowDeselect={false}
            onChange={(value)=>setMotivo(value as string)}
        />
        )}
        

        <Flex
            mih={50}
            gap="md"
            justify="flex-end"
            align="flex-end"
            direction="row"
            wrap="wrap"
        >
        <Divider my="sm" />
            <Button variant="outline" onClick={() => context.closeModal(id)}>Cerrar</Button>
            <Button variant="primary" onClick={handleConfirmCallback}>{getPrimaryButtonText()}</Button>
        </Flex>
    </>
    );
}

export default MarkAsLostOrActiveContextModal;