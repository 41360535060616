import axios from 'axios';
import { useCallback, useContext } from 'react';
import useNotifier from './useNotifier';
import { showLoadingOverlaySubject } from '../commons/utils/ActionsSubject';
import GlobalContext from '../components/providers/GlobalContext';

interface usePrintReportResponse{
    printReport: (url: string, body?: any) => void; 
}

const usePrintReport = (): usePrintReportResponse => {
    const {globalContext} = useContext(GlobalContext);
    const { processAxiosError } = useNotifier();

    const printReport = useCallback((url: string, body: any) => {
        showLoadingOverlaySubject.next(true);
        axios({
            url: `${document.referrer}${globalContext.parentContext}${url}`,
            method: 'POST',
            data: body,
            responseType: 'blob',
            withCredentials: true
        }).then((response) => {
            const pdfUrl = URL.createObjectURL(response.data);
            window.open(pdfUrl, '_blank');
        }).catch((error) => {
            processAxiosError(error);
        }).finally(() => {
            showLoadingOverlaySubject.next(false);
        });
    }, [processAxiosError]);

    return {
        printReport
    };
};

export default usePrintReport;