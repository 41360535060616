import React from 'react';
import { ContextModalProps } from '@mantine/modals';
import { Button, Flex, Grid, rem, Space } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

const AlertContextModal = ({
    context,
    id,
    innerProps,
    }: ContextModalProps<{ modalBody: React.ReactNode }>) => (
        <>
            <Flex
                mih={50}
                gap="md"
                justify="center"
                align="flex-start"
                direction="row"
                wrap="wrap"
            >
                <FontAwesomeIcon style={{ width: rem(50), height: rem(50), color: "#FFD43B", }} icon={faCircleExclamation} />
            </Flex>
            <Space h="md" />
            {innerProps.modalBody}
            <Space h="md" />
            <Button fullWidth mt="md" onClick={() => context.closeModal(id)}>
                Entendido
            </Button>
        </>
    );

export default AlertContextModal;