import { faArrowDown, faArrowsUpDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon } from '@mantine/core';
import React, { ReactNode, useCallback, useContext } from 'react';
import ScopeContext from '../../providers/ScopeContext';

interface ColumnOrderProps {
    columnId: string;
}

const ColumnOrder: React.FC<ColumnOrderProps> = ({columnId}) => {
    const {scope, setScope} = useContext(ScopeContext);

    const orderAsc = useCallback(() => {
        setScope((prevScope) => ({...prevScope, order: {column: columnId, direction: 'ASC'}}));
    }, [columnId, setScope]);

    const orderDesc = useCallback(() => {
        setScope((prevScope) => ({...prevScope, order: {column: columnId, direction: 'DESC'}}));
    }, [columnId, setScope]);

    const noOrder = useCallback(() => {
        setScope((prevScope) => ({...prevScope, order: undefined}));
    }, [setScope]);

    const getArrow = useCallback((): ReactNode => {
        if(scope.order?.column === columnId){
            if (scope.order.direction === 'DESC') {
                return <FontAwesomeIcon icon={faArrowUp} onClick={() => orderAsc()}/>
            }
            if (scope.order.direction === 'ASC') {
                return <FontAwesomeIcon icon={faArrowDown} onClick={() => orderDesc()}/>
            }
        }else{
            return <FontAwesomeIcon icon={faArrowsUpDown} onClick={() => orderAsc()}/>;
        }
    }, [noOrder, orderAsc, orderDesc, scope.order, columnId]);

    return (
        <ActionIcon variant="transparent" size="xs" aria-label="Settings">
            {getArrow()}
        </ActionIcon>
    );
};

export default ColumnOrder;