import React, { useCallback, useContext } from 'react';
import GlobalContext from '../providers/GlobalContext';
import { Button } from '@mantine/core';
import useRights from '../../hooks/useRights';

interface NewQuotationButtonProps {
    // Define the props for your component here
}

const NewQuotationButton: React.FC<NewQuotationButtonProps> = (props) => {
    const {globalContext} = useContext(GlobalContext);
    const {showControlledInput} = useRights();
    
    function goToNewQuotation() {
        const resourceName = globalContext.parentResourceName
        window.parent.location.href = `${document.referrer}${globalContext.parentContext}/pedidos.jsp?redirectFrom=${resourceName}`;
    }

    const show = useCallback(() => {
        return showControlledInput({rightControlId: 'BtnNvoPed', disabled: false});
    }, [showControlledInput]);

    return (
        <Button children="Nueva cotización" onClick={goToNewQuotation} size="xs" disabled={!show}></Button>
    );
};

export default NewQuotationButton;