import React, { useContext } from 'react';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Table, Tooltip } from '@mantine/core';
import { IPedido } from '../../../commons/interfaces/Pedidos';
import { notifications } from '@mantine/notifications';
import GlobalContext from '../../providers/GlobalContext';

interface ActionButtonEditProps {
    request: IPedido;
}

const ActionButtonEdit: React.FC<ActionButtonEditProps> = (props) => {
    const {globalContext} = useContext(GlobalContext);

    const handleEdit = () => {
        const request = props.request;
        let opcAuto = 0;
        if(request.status === 'C'){
            showErrorNotification('El pedido esta Cancelado');
            return;
        }
        if(request.validacionStatusPrepedido !== 0 && request.prepedido !== 0){
            showErrorNotification('Este Pre-Pedido no se Puede Editar');
            return;
        }
        if(request.autoEnt !== 0){
            showErrorNotification('Este Pedido Ya Se Entregó no se Puede Editar');
            return;
        }
        if(request.opcAsigna === 1){
            if(request.opcStatusValidacion !== 0){
                showErrorNotification('Este Pedido Esta En Proceso de Respuesta De Validacion');
                return;
            }
            opcAuto = doOpcAsignaValidationsAndReturnOpcAuto(request);
        }else{
            if(request.autoCxc !== 0){
                showErrorNotification('Este Pedido no se Puede Editar"');
                return;
            }
            if(request.autoVta !== 0){
                showErrorNotification('Este Pedido no se Puede Editar"');
                return;
            }
            if(request.opcAsigna !== 0){
                showErrorNotification('Este Pedido Ya Tiene Surtido no se Puede Editar');
                return;
            }
            opcAuto = doRegularValidationsAndReturnOpcAuto(request);
        }
        const resourceName = globalContext.parentResourceName
        const params = `opc_insupd=U&upd_tkmov=${request.tkmov}&upd_idpedido=${request.idPedido}&upd_idempresa=${request.idEmpresa}&opc_auto=${opcAuto}`;
        window.parent.location.href = document.referrer + `${globalContext.parentContext}/pedidos.jsp?redirectFrom=${resourceName}&${params}`;
    }

    const doOpcAsignaValidationsAndReturnOpcAuto = (request: IPedido): number =>{
        if(request.autoCxc !== 0 && request.opcAsigna !== 0){
            return 1;
        }
        return 0;
    }

    const doRegularValidationsAndReturnOpcAuto = (request: IPedido):number => {
        if(request.apartadoOpc !== 0){
            return 2;
        }
        return 0;
    }

    const showErrorNotification = (bodyMessage: string) => {
        notifications.show({
            title: 'No se puede editar este pedido',
            message: bodyMessage,
            color: 'red',
        });
    }

    return (
            <Table.Td pr={3} pl={3}>
                <Tooltip label="Editar cotización">
                    <ActionIcon variant="light" size="sm" aria-label="Settings" onClick={handleEdit}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </ActionIcon>
                </Tooltip>
            </Table.Td>
        
    );
};

export default ActionButtonEdit;