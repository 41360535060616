import { Accordion, ActionIcon, Anchor, Container, Flex, Table, Text } from '@mantine/core';
import React, { ReactNode } from 'react';
import { DeliveryProofDTO, IPedido, IPedidoBaseDetailDTO } from '../../../commons/interfaces/Pedidos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileContract } from '@fortawesome/free-solid-svg-icons';
import usePrintReport from '../../../hooks/usePrintReport';
import useLegacyStorage from '../../../hooks/useLegacyStorage';

interface DeliveryProofViewerProps {
    request: IPedido;
    requestAdditionalData: IPedidoBaseDetailDTO;
    deliveryProof?: DeliveryProofDTO;
}

const DeliveryProofViewer: React.FC<DeliveryProofViewerProps> = ({request, requestAdditionalData, deliveryProof}) => {
    const {printReport} = usePrintReport();
    const {getResourceUrl} = useLegacyStorage();

    const containerProps = {
        bg: 'white',
        mt: 'md',
    };

    const tableStyles = {
        table: {
            backgroundColor: "white",
        }
    };

    const printSignedInvoice = () => {
        printReport(`/sales/print-report?action=signed-invoice`, {
            pedido: request.idPedido,
            empresa: request.idEmpresa,
            tkmov: request.tkmov,
            series: requestAdditionalData.series,
            invoice: requestAdditionalData.invoice
        
        });
    };

    const printRegularInvoice = () => {
        printReport(`/sales/print-report?action=regular-invoice`, {
            pedido: request.idPedido,
            empresa: request.idEmpresa,
            tkmov: request.tkmov,
            series: requestAdditionalData.series,
            invoice: requestAdditionalData.invoice
        
        });
    };

    const deliveryProofrows = (): ReactNode => {
        return (
        <Table.Tr key={`element.name`}>
            <Table.Td fz={13}>{deliveryProof?.fecha}</Table.Td>
            <Table.Td fz={13}>{deliveryProof?.receiver}</Table.Td>
            <Table.Td fz={13}>{deliveryProof?.status}</Table.Td>
            <Table.Td fz={13}>{deliveryProof?.nroCajas}</Table.Td>
            <Table.Td fz={13} align='center'>{deliveryProof?.notes || '--'}</Table.Td>
            <Table.Td fz={13}>{deliveryProof?.rating}</Table.Td>
            <Table.Td fz={13}>{deliveryProof?.sugestions || '--'}</Table.Td>
            <Table.Td><img src={getResourceUrl(deliveryProof?.sign?.url || '', deliveryProof?.sign.legacyStorage || false)} width="250px" height="80px" alt='X'/></Table.Td>
        </Table.Tr>);
    };

    return (
        <>
            <Accordion pt={20} variant="contained">
                <Accordion.Item key={`comprobante-de-entrega`} value={`Comprobantes de entrega y factura`}>
                    <Accordion.Control >{`Comprobantes de entrega y factura`}</Accordion.Control>
                    <Accordion.Panel>
                        <Flex
                        gap="md"
                        justify="flex-end"
                        align="center"
                        direction="column"
                        wrap="wrap"
                        >
                            <Text size="xl" fw={700}>Recepción de Producto</Text>
                            <Container {...containerProps}>
                                <Table align='center' styles={tableStyles}>
                                <Table.Thead bg={'#212529'}>
                                    <Table.Tr>
                                    <Table.Th w={'100'} c={'white'}>Fecha</Table.Th>
                                    <Table.Th w={'100'} c={'white'}>Recibió</Table.Th>
                                    <Table.Th c={'white'}>Estatus</Table.Th>
                                    <Table.Th w={'70'} c={'white'}>N. Cajas</Table.Th>
                                    <Table.Th c={'white'}>Observaciones</Table.Th>
                                    <Table.Th c={'white'}>Calificación</Table.Th>
                                    <Table.Th c={'white'}>Mejoras</Table.Th>
                                    <Table.Th c={'white'}>Firma</Table.Th>
                                    </Table.Tr>
                                </Table.Thead>
                                <Table.Tbody>{deliveryProof && deliveryProofrows()}</Table.Tbody>
                                </Table>
                            </Container>
                        </Flex>
                        <Flex pt={10} gap="sm" align="center">
                            {request.invoice !== 0 && 
                            <>
                                <Anchor onClick={printRegularInvoice} target="_blank">
                                    <ActionIcon variant="light" aria-label="Settings">
                                        <FontAwesomeIcon icon={faFileContract}/>
                                    </ActionIcon>
                                    Ver factura regular
                                </Anchor>
                                { requestAdditionalData.photo &&
                                <Anchor href={getResourceUrl(requestAdditionalData.photo.url || '', requestAdditionalData.photo.legacyStorage || false)} target="_blank">
                                    <ActionIcon variant="light" aria-label="Settings">
                                        <FontAwesomeIcon icon={faDownload} />
                                    </ActionIcon>
                                    Descargar comprobante de entrega
                                </Anchor>
                            }
                            </>
                            }
                            {(deliveryProof && request.invoice !== 0) &&
                            <>
                                    <Anchor onClick={printSignedInvoice} target="_blank">
                                        <ActionIcon variant="light" aria-label="Settings">
                                            <FontAwesomeIcon icon={faFileContract}/>
                                        </ActionIcon>
                                        Ver factura firmada
                                    </Anchor>
                            </>
                            }
                            
                        </Flex>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </>
    );
};

export default DeliveryProofViewer;