export const dropZone = {
    root: {
        backgroundColor: "#F8F9FA",
        border: "1px dotted #CCC",
        borderRadius: 'var(--radius)',
        '--radius': '0.5rem'
        // min-height: rem(120px),
        // display: flex,
        // justify-content: center,
        // align-items: center,
        // border: 0,F8F9FA
    },
};