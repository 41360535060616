import React, { CSSProperties, useEffect, useState } from 'react';
import { IPedido } from '../../../commons/interfaces/Pedidos';
import { ActionIcon, Table, Tooltip } from '@mantine/core';
import { getButtonSStyles } from '../../../commons/utils/ActionButtonsStyleHelper';

interface ActionButtonSProps {
    request: IPedido;
}

const ActionButtonS: React.FC<ActionButtonSProps> = ({ request }) => {

    return (
        <Table.Td pr={3} pl={3}>
            <Tooltip label="Surtido">
                <ActionIcon variant="light" size="sm" aria-label="Settings" style={getButtonSStyles(request)}>
                    S
                </ActionIcon>
            </Tooltip>
        </Table.Td>
    );
};

export default ActionButtonS;