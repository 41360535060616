import { useCallback, useContext } from "react";
import GlobalContext from "../components/providers/GlobalContext";

interface useLegacyStorageResponse{
    getResourceUrl: (url: string, isLegacy: boolean) => string;
}

const useLegacyStorage = (): useLegacyStorageResponse => {
    const {globalContext} = useContext(GlobalContext);

    const getResourceUrl = useCallback((url: string, isLegacy: boolean) => {
        if(isLegacy){
            return `${document.referrer}${globalContext.parentContext}/${url}`;
        }else{
            return url;
        }
    }, [globalContext]);

    return {
        getResourceUrl
    }
}

export default useLegacyStorage;