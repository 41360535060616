import React from "react";
import MasterFilter from "../Filters/MasterFilter";
import { FormFieldConfigInterface } from "../../commons/interfaces/MasterFilterInterfaces";
import { TableBaseProps } from "../../commons/types/MasterTable";

interface ApartadosProps {
  forwardedRef: React.MutableRefObject<{ setActiveMethod: () => void } | null>;
}

const Apartados: React.FC<ApartadosProps> = ({forwardedRef}) => {

  // Useful method to configure actions on tab selection
  const setActiveMethod = () => {
    console.log("[Cotizaciones] setActiveMethod");
  }
  
  forwardedRef.current = { setActiveMethod };

  const formFieldsConfig: FormFieldConfigInterface[] = [
    {
      id: "idClientes",
      type: "client-select",
      label: "Cliente",
      span: 3,
    },

    {
      id: "idArticulo",
      type: "product-select",
      label: "Artículo",
      span: 3,
    },
    {
      id: "sku",
      type: "productColor-select",
      label: "Color",
      span: 3,
    },
    { id: "fechaDesde", type: "datePicker", label: "Inicial", span: 1.5 },
    { id: "fechaHasta", type: "datePicker", label: "Final", span: 1.5 },
    { 
      id: "idAsesor",
      type: "thirdParty-select",
      label: "Asesor C.",
      thirdPartyContext: "advisors",
      span: 3
    },
    { 
      id: "idVendedor",
      type: "thirdParty-select",
      label: "Vendedor",
      thirdPartyContext: "sellers",
      span: 3
    },

  ];

  const buttonsConfig: TableBaseProps = {
    p: {disabled: true, rightControlId: "BtnGridAutCxc", hideable: false, key: "p"},
    s: {disabled: true, rightControlId: "BtnGridAutCeo", hideable: false, key: "s"},
    t: {disabled: true, rightControlId: "BtnGridAutCeo", hideable: false, key: "t"},
    e: false,
    clienteEnRecepcion: {disabled: true, rightControlId: "BtnNotCliente", hideable: false, key: "clienteEnRecepcion"},
    apartarMercancia: {disabled: true, rightControlId: "BtnGridReq", hideable: false, key: "apartarMercancia"},
    editarCotizacion: {disabled: true, rightControlId: "BtnGridEdit", hideable: false, key: "editarCotizacion"},
    eventosMovimientos: {disabled: false, rightControlId: "BtnGridEventPed", hideable: false, key: "eventosMovimientos"},
    verArchivos: false,
    generarFacturas: {disabled: true, rightControlId: "BtnGridGenFact", hideable: false, key: "generarFacturas"},
    numeroPedido: {disabled: false, rightControlId: "BtnGridViewFact", columnOrderName: "pedido", hideable: false, key: "numeroPedido"},
    tipoPedido: false,
    cliente: {disabled: false, columnOrderName: "Nomcte", hideable: true, key: "cliente"},
    fecha: {disabled: false, columnOrderName: "fecha", hideable: true, key: "fecha"},
    fechaApartado: {disabled: false, columnOrderName: "apartado_fecha", hideable: true, key: "fechaApartado"},
    fechaEntrega: {disabled: true, columnOrderName: "apartado_fecha", hideable: true, key: "fechaEntrega"},
    pedCliente: {disabled: true, columnOrderName: "Nomcte", hideable: true, key: "pedCliente"},
    factura: {disabled: true, columnOrderName: "porfac", hideable: true, key: "factura"},
    ruta: {disabled: false, columnOrderName: "ruta", hideable: true, key: "ruta"},
    subtotal: {disabled: true, columnOrderName: "SubTotal", hideable: true, key: "subtotal"},
    iva: {disabled: true, columnOrderName: "IVA", hideable: true, key: "iva"},
    total: {disabled: true, columnOrderName: "Total", hideable: true, key: "total"},
    vendedor: {disabled: false, columnOrderName: "nombre_vendedor", hideable: true, key: "vendedor"},
    acciones: false,
  };

  return (
        <MasterFilter
          buttonsConfig={buttonsConfig}
          filtersConfig={formFieldsConfig}
        />
  );
};

export default Apartados;
