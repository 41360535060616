import { faPeopleCarryBox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Table, Tooltip } from '@mantine/core';
import React, { useCallback, useEffect, useState } from 'react';
import { getButtonReserveStyles } from '../../../commons/utils/ActionButtonsStyleHelper';
import { IPedido } from '../../../commons/interfaces/Pedidos';
import { useDisclosure } from '@mantine/hooks';
import { ModalSeparatedWithAuth } from '../../modals/separated/ModalSeparatedWithAuth';
import { ModalSeparatedWithoutAuth } from '../../modals/separated/ModalSeparatedWithoutAuth';
import useRequest from '../../../hooks/useRequest';
import { refreshTable } from '../../../commons/utils/ActionsSubject';
import { ContextEnum } from '../../../commons/enums/RequestContextEnum';

interface ActionButtonSeparatedProps {
    request: IPedido;
}

const ActionButtonSeparated: React.FC<ActionButtonSeparatedProps> = (props) => {
  const [request, setRequest] = useState<IPedido>(props.request);
  const { executeRequestAndCallbackData } = useRequest();
  const [ opened, {open, close} ] = useDisclosure(false);
  const [needAuthorization, setNeedAuthorization] = useState(false);
  const [authCancelDate, setAuthCancelDate] = useState<Date | null>(null);

  const handleOnClick = (pedido: number, tkmov: string, empresa: number, status: string) => {
      const requestData = { pedido, tkmov, empresa, status};
      executeRequestAndCallbackData({url: "/sales/separated?action=need-auth", method: "post", body: requestData, callback: actionPostNeedSeparateQuery});
  }

  const actionPostNeedSeparateQuery = (response: any) => {
    if (response) {
      setNeedAuthorization(response.needAuthorization);
      open();
    }
  }

  const needAuthCallBack = useCallback((cancelDate: Date) => {
    close();
    setAuthCancelDate(cancelDate);
    setNeedAuthorization(true);
    open();
  }, [close, open]);

  const onCloseSeparatedSucess = useCallback(() => {
    setRequest((prevRequest) => ({...prevRequest, blnapartado: 0, apartadoOpc: 1, autoVta: 0}));
    setAuthCancelDate(null);
    refreshTable.next({scopeContext: ContextEnum.SEPARATED});
    close();
  }, [close]);

  const onCloseCancelSeparatedSucess = useCallback(() => {
    setRequest((prevRequest) => ({...prevRequest, blnapartado: 0, apartadoOpc: 0, autoVta: 0}));
    setAuthCancelDate(null);
    refreshTable.next({scopeContext: ContextEnum.SEPARATED});
    close();
  }, [close]);

  return (
    <>
      <Table.Td pr={3} pl={3}>
        <Tooltip label="Apartar mercancia">

        <ActionIcon
          variant="light"
          size="sm"
          aria-label="Settings"
          style={getButtonReserveStyles(request)}
          onClick={() => handleOnClick(request.idPedido, request.tkmov, request.idEmpresa, request.status)}
          >
          <FontAwesomeIcon icon={faPeopleCarryBox} />
        </ActionIcon>

        </Tooltip>
      </Table.Td>
      {needAuthorization ? 
        (<ModalSeparatedWithAuth request={request} opened={opened} cancelDate={authCancelDate} closeModal={close} onSeparateCallBack={onCloseSeparatedSucess} onCancelSeparateCallBack={onCloseCancelSeparatedSucess}/>)
        :
        (<ModalSeparatedWithoutAuth request={request} opened={opened} closeModal={close} needAuthCallBack={needAuthCallBack} onSeparateCallBack={onCloseSeparatedSucess} onCancelSeparateCallBack={onCloseCancelSeparatedSucess}/>)
      }
    </>
  );
};

export default ActionButtonSeparated;