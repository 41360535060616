import { Dispatch, SetStateAction, useCallback, useContext, useState } from "react";
import { FilterDataInterface, FilterRequestDTO } from "../commons/interfaces/MasterFilterInterfaces";
import axios, { AxiosError } from "axios";
import { parseFormDataListToDTO } from "../commons/filters/FiltersParser";
import { IPedidosResponse, ITotalSum } from '../commons/interfaces/Pedidos';
import GlobalContext from "../components/providers/GlobalContext";
import useNotifier from "./useNotifier";
import { isRequestLoadingSubject } from "../commons/utils/ActionsSubject";
import RefreshTabsContext from "../components/providers/RefreshTabsContext";

const useMasterFilterConfig = (contextUrl: string) => {
  const {globalContext} = useContext(GlobalContext);
  const [pedidosResponse, setPedidosResponse] = useState<IPedidosResponse>();
  const [totalsResponse, setTotalsResponse] = useState<ITotalSum | null>(null);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isLoadingTotal, setIsLoadingTotal] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { processAxiosError } = useNotifier();
  const { registerAbortController } = useContext(RefreshTabsContext);

  const handleRequest = useCallback(async (data: any, stateToUpdate: Dispatch<SetStateAction<any | undefined>>, loadingState: Dispatch<SetStateAction<any | undefined>>, onlyTotals= false) => {
    try {
      isRequestLoadingSubject.next(isRequestLoadingSubject.getValue() + 1);
      loadingState(true);
      const url = `${document.referrer}${globalContext.parentContext}${contextUrl}${onlyTotals ? '?action=get-totals': ''}`;
      const abortController = new AbortController();
      registerAbortController(contextUrl, abortController);
      const config = {
        method: "post",
        url,
        data: data,
        withCredentials: true,
        signal:  abortController?.signal,
      };
      const response = await axios(config);

      stateToUpdate(response.data);
      loadingState(false);
      isRequestLoadingSubject.next(isRequestLoadingSubject.getValue() - 1);
    } catch (error) {
      processAxiosError(error as AxiosError<string>);
      isRequestLoadingSubject.next(isRequestLoadingSubject.getValue() - 1);
    }
  }, [globalContext]);

  
  
  const handleFormData = useCallback(async (formFieldsData: FilterDataInterface) => {
    formFieldsData.formFields.push({ id: "tkmov", value: globalContext.tkmov }); // Se agrega el tkmove al formFieldsData
    const filterReqDTO: FilterRequestDTO = parseFormDataListToDTO(formFieldsData);
    handleRequest(filterReqDTO, setPedidosResponse, setIsLoadingData);
  }, [globalContext]);

  const handleRefreshTotals = useCallback(async (formFieldsData: FilterDataInterface) => {
    formFieldsData.formFields.push({ id: "tkmov", value: globalContext.tkmov }); // Se agrega el tkmove al formFieldsData
    const filterReqDTO: FilterRequestDTO = parseFormDataListToDTO(formFieldsData);
    handleRequest(filterReqDTO, setTotalsResponse, setIsLoadingTotal, true);
  }, [globalContext]);

  return {
    handleFormData,
    handleRefreshTotals,
    pedidosResponse,
    totalsResponse,
    setTotalsResponse,
    isLoadingData,
    isLoadingTotal,
    error,
  };
};

export default useMasterFilterConfig;
