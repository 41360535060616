import React, { useState } from 'react';
import { IPedido } from '../../../commons/interfaces/Pedidos';
import { ActionIcon, Tooltip, Text, Table } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserClock } from '@fortawesome/free-solid-svg-icons';
import { modals } from '@mantine/modals';
import { getButtonClientRecoStyles } from '../../../commons/utils/ActionButtonsStyleHelper';
import useRequest from '../../../hooks/useRequest';

interface ActionButtonClientRecolectProps {
    request: IPedido;
}

const ActionButtonClientRecolect: React.FC<ActionButtonClientRecolectProps> = (props) => {
    const [request, setRequest] = useState<IPedido>(props.request);
    const { executeRequest } = useRequest();

    const executeTableRefresh = () => {
        console.log('[ActionButtonClientRecolect] updating request');
        setRequest((prevRequest) => ({...prevRequest, clienteRecolecta: prevRequest.clienteRecolecta === 0 ? 1 : 0}));
    }

    const handleClientRecolect = () => {
        if(request.clienteRecolecta <= 0 ){
            executeClientRecolectRequest(1);
        }else{
            modals.openContextModal({
                size: 'sm',
                modal: 'confirm',
                title: 'Atencion',
                innerProps: {
                    modalBody: (
                        <>
                            <Text>
                            ¿Deseas cancelar?   
                            </Text>
                        </>
                    ),
                    confirmCallback: () => executeClientRecolectRequest(0),
                },
                });
        }
    }

    const executeClientRecolectRequest = (clientRecolect: number) => {
        
        executeRequest({url:`/sales/client-recolect`, method: 'POST',
            body:{
                pedido: request.idPedido,
                tkmov: request.tkmov,
                empresa: request.idEmpresa,
                opcClientReco: clientRecolect,
            }, callback: executeTableRefresh});
    }

    return (
        <Table.Td pr={3} pl={3}>
            <Tooltip label="Cliente en recepción">
                <ActionIcon variant="filled" aria-label="Settings" style={getButtonClientRecoStyles(request)} onClick={handleClientRecolect}>
                    <FontAwesomeIcon icon={faUserClock} />
                </ActionIcon>
            </Tooltip>
        </Table.Td>
    );
};

export default ActionButtonClientRecolect;