export const colors = {
  black: "#2D3748",
  lightGray: "#2D3748",
  gray: "#f8f9fa",
  darkGray: "#495057",
  secondaryGray: "#54595E",
  blue: "#0D6EFD",
  darkBlue: "#3182CE",
  lightBlue: "#c2e1ff",
  yellow: "#FFF3CD",
  darkYellow: "#664D03",
  lightGreen: "#D1E7DD",
  darkGreen: "#0A3622",
  lightRed: "#f5c6cb",
  darkRed: "#6e020e",
};
