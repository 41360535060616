import { FC, useState } from "react";
import { Group, Pagination } from "@mantine/core";

export interface IPaginationBarProps {
  totalPages: number;
  activePage: number;
  handlePageChange: (value: number) => void;
}

const PaginationBar: FC<IPaginationBarProps> = ({ totalPages, activePage, handlePageChange }: IPaginationBarProps) => {

  return (
    <Group justify="center" mt="md">
      <Pagination
        total={totalPages}
        size="sm"
        radius="xs"
        value={activePage}
        onChange={handlePageChange}
        withEdges />
    </Group>
  );
};

export default PaginationBar;
