import { useCallback, useContext } from "react";
import useAxios from "./useAxios";
import axios from "axios";
import useNotifier from "./useNotifier";
import { BaseAction } from "../commons/interfaces/Pedidos";
import GlobalContext from "../components/providers/GlobalContext";

type MenuActionsHook = {
    handlePrintReport: (withAmounts: boolean) => void;
    redirectFactAnticipo: () => void;
    redirectToIncident: () => void;
};

/**
 * Este hook se encarga de manejar todas las acciones que se pueden realizar en el menú de opciones de un pedido.
 * @param baseActionContent  Contenido base usado en las acciones
 * @returns MenuActionsHook
 */
const useMenuActions = (baseActionContent: BaseAction): MenuActionsHook  => {
    const {globalContext} = useContext(GlobalContext);
    const { executeRequest: printReportRequest } = useAxios({});

    const { processAxiosError } = useNotifier();

    const handlePrintReport = useCallback((withAmounts: boolean) => {
        printReportRequest(`/sales/print-report?action=request&printType=${withAmounts ? '1' : '100'}`,
        'post',
        baseActionContent,
        'blob');
    }, [baseActionContent]);

    const handleRedirectWithValidations = useCallback((preValidationsUrl: string, urlToGo: string, body: any) => {
        axios({
            url: `${document.referrer}${globalContext.parentContext}${preValidationsUrl}`,
            method: 'post',
            data: body,
            withCredentials: true
        }).then((response) => {
            // const { pedido, tkmov, empresa} = baseActionContent;
            const url = `${document.referrer}${globalContext.parentContext}${urlToGo}`;
            window.parent.location.href = url;
        }).catch((error) => {
            processAxiosError(error);
        });
    }, [processAxiosError, globalContext]);

    const redirectFactAnticipo = useCallback(() => {
        const { pedido, tkmov, empresa} = baseActionContent;
        handleRedirectWithValidations(
            '/sales/anticipos?action=fact-anticipo-validate',
            `/factura.jsp?opc_anticipo=S&tkmov=${tkmov}&id_empresa=${empresa}&id_pedido=${pedido}`,
            baseActionContent);
    }, [baseActionContent, handleRedirectWithValidations]);

    const redirectToIncident = useCallback(() => {
        const { pedido, tkmov, empresa} = baseActionContent;
        handleRedirectWithValidations(
            '/sales/incidentes?action=pre-incident-validate',
            `/gen_incidencia_dialog.jsp?opc_anticipo=S&pedido_tkmov=${tkmov}&pedido_empresa=${empresa}&pedido_pedido=${pedido}&redirectFrom=${globalContext.parentResourceName}`,
            baseActionContent);
    }, [baseActionContent, handleRedirectWithValidations, globalContext]);
    
    return {
        handlePrintReport,
        redirectFactAnticipo,
        redirectToIncident,
    }
}

export default useMenuActions;