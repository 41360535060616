import { ActionIcon, Button, Divider, Flex, Grid, Group, Modal, rem, Text } from '@mantine/core';
import { Dropzone, FileWithPath } from '@mantine/dropzone';
import React, { useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { faFile, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dropZone } from '../../../styles';
import { IPedido } from '../../../commons/interfaces/Pedidos';
import useNotifier from '../../../hooks/useNotifier';
import useRequest from '../../../hooks/useRequest';

interface UploadPurchaseOrderFilesProps {
    openModal: boolean;
    request: IPedido;
    onCloseCallback: () => void;
}

const UploadPurchaseOrderFiles: React.FC<UploadPurchaseOrderFilesProps> = (props) => {
    const [opened, { open, close }] = useDisclosure(props.openModal, {});
    const { showMessage } = useNotifier();
    const { executeRequest } = useRequest();
    const [uploadedFile, setUploadedFile] = useState<File>();
    // const { data, executeRequest, loading, responseError } = useAxios({headers: { 'Content-Type': 'multipart/form-data' }});

    const onClose = () => {
        props.onCloseCallback();
        close();
    }

    const onSubmit = () => {

        executeRequest({url: `/files/request?action=order`,
        method:'POST',
        body: {
            requestNumber: props.request.idPedido,
            tkmov: props.request.tkmov,
            companyId: props.request.idEmpresa,
            file: uploadedFile,
            fileContentType: uploadedFile?.type,
        }, headers: {'Content-Type': 'multipart/form-data'}, callback: showMessageSuccessMessage});

        props.onCloseCallback();
        close();
    }

    const showMessageSuccessMessage = () => {
        showMessage('Resultado', 'Archivo subido correctamente', 'success');
    }

    const handleFileDelete = (fileName: string) => {
        //const filteredfiles = uploadedFiles.filter(file => file.name !== fileName);
        //setUploadedFiles(filteredfiles);
        setUploadedFile(undefined);
    }

    const onFileUpload = (files: FileWithPath[]) => {
        //setUploadedFiles(prevFiles => [...prevFiles, ...files]);
        setUploadedFile(files[0]);
    }


    return (
        <Modal opened={opened} onClose={onClose} size={'lg'} title="Orden de compra">
            <Divider my="sm" />
                <Grid gutter="lg" justify="center">
                    <Grid.Col span={12} >
                        <Dropzone 
                        styles={dropZone}
                        onDrop={onFileUpload}>
                            <Flex
                                pt={10}
                                pb={10}
                                gap="md"
                                justify="center"
                                align="center"
                                direction="column"
                            >
                                <FontAwesomeIcon icon={faUpload} />
                                <Text ta="center">Subir archivo</Text>
                            </Flex>
                        </Dropzone>
                    </Grid.Col>
                    <Grid.Col span={12} >

                    </Grid.Col>
                </Grid>
                <Grid justify="center" >
                    {uploadedFile && (
                        <Grid.Col span={12} style={{border: "1px solid #CCC", 'bordeRadius': '2px'}}>
                            <Group gap="sm" grow>
                                <Grid.Col span={1}> <FontAwesomeIcon style={{ width: rem(30), height: rem(30) }} color="#ABB5BE" icon={faFile} /> </Grid.Col>
                                <Grid.Col span={9}> <Text size="lg">{uploadedFile.name}</Text> </Grid.Col>
                                <Grid.Col span={1}>
                                    <ActionIcon size={40} variant="default" onClick={()=> handleFileDelete(uploadedFile.name)}>
                                        <FontAwesomeIcon color="#8a939c" icon={faTrash} />
                                    </ActionIcon>
                                </Grid.Col>
                            </Group>
                        </Grid.Col>
                    )}
                </Grid>
                <Divider my="sm" />
                <Flex
                    mih={50}
                    gap="md"
                    justify="center"
                    align="flex-end"
                    direction="row"
                    wrap="wrap"
                >
                    <Button variant="outline" color="gray" onClick={() => onClose()}>Cerrar</Button>
                    <Button variant="primary" onClick={() => onSubmit()}>Guardar</Button>
                </Flex>
        </Modal>
    );
};

export default UploadPurchaseOrderFiles;