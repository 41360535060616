import { Table } from '@mantine/core';
import React from 'react';
import ColumnOrder from './ColumnOrder';
import { SortableColumn } from '../../../commons/types/MasterTable';

interface SortableColumnHeadProps {
    columnName: string
    columnProps: SortableColumn;
}

const SortableColumnHead: React.FC<SortableColumnHeadProps> = ({ columnName, columnProps }) => {
    return (
        <>
            <Table.Th>{columnName}
                {columnProps.columnOrderName  &&
                    <ColumnOrder columnId={columnProps.columnOrderName}/>
                }
            </Table.Th>
        </>
    );
};

export default SortableColumnHead;