import { MutableRefObject, useCallback, useRef } from 'react';

type TabRef = {
    cotizacionesTabRef: MutableRefObject<{ setActiveMethod: () => void } | null>;
    pedidosActivosTabRef: MutableRefObject<{ setActiveMethod: () => void } | null>;
    cotizacionesPerdidasTabRef: MutableRefObject<{ setActiveMethod: () => void } | null>;
    pedidosFacturadosTabRef: MutableRefObject<{ setActiveMethod: () => void } | null>;
    apartadosTabRef: MutableRefObject<{ setActiveMethod: () => void } | null>;
    todosTabRef: MutableRefObject<{ setActiveMethod: () => void } | null>;
    handleTabChange: (activeTab: string) => void;
};

const useTabListener = (): TabRef => {

    const cotizacionesTabRef = useRef<{ setActiveMethod: () => void } | null>(null);
    const pedidosActivosTabRef = useRef<{ setActiveMethod: () => void } | null>(null);
    const cotizacionesPerdidasTabRef = useRef<{ setActiveMethod: () => void } | null>(null);
    const pedidosFacturadosTabRef = useRef<{ setActiveMethod: () => void } | null>(null);
    const apartadosTabRef = useRef<{ setActiveMethod: () => void } | null>(null);
    const todosTabRef = useRef<{ setActiveMethod: () => void } | null>(null);

    const handleTabChange = useCallback((activeTab: string) => {
        switch (activeTab) {
            case 'cotizaciones':
                if (cotizacionesTabRef.current && cotizacionesTabRef.current.setActiveMethod) {
                    cotizacionesTabRef.current.setActiveMethod();
                }
                break;
            case 'pedidosActivos':
                if (pedidosActivosTabRef.current && pedidosActivosTabRef.current.setActiveMethod) {
                    pedidosActivosTabRef.current.setActiveMethod();
                }
                break;
            case 'cotizacionesPerdidas':
                if (cotizacionesPerdidasTabRef.current && cotizacionesPerdidasTabRef.current.setActiveMethod) {
                    cotizacionesPerdidasTabRef.current.setActiveMethod();
                }
                break;
            case 'pedidosFacturados':
                if (pedidosFacturadosTabRef.current && pedidosFacturadosTabRef.current.setActiveMethod) {
                    pedidosFacturadosTabRef.current.setActiveMethod();
                }
                break;
            case 'apartados':
                if (apartadosTabRef.current && apartadosTabRef.current.setActiveMethod) {
                    apartadosTabRef.current.setActiveMethod();
                }
                break;
            case 'todos':
                if (todosTabRef.current && todosTabRef.current.setActiveMethod) {
                    todosTabRef.current.setActiveMethod();
                }
                break;
        }
    }, []);

    return {
        cotizacionesTabRef,
        pedidosActivosTabRef,
        cotizacionesPerdidasTabRef,
        pedidosFacturadosTabRef,
        apartadosTabRef,
        todosTabRef,
        handleTabChange,
    };
    
};

export default useTabListener;