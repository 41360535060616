import {  useCallback, useContext, useState } from "react";
import { DateTimePicker, DateValue } from "@mantine/dates";
import { Box, Button, Group, Modal, rem, Text } from "@mantine/core";
import { addDay, getFormattedDateString, getFormattedDateTimeString } from "../../../commons/utils/Formatters";
import { IPedido } from "../../../commons/interfaces/Pedidos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import useRequest from "../../../hooks/useRequest";
import useSeparated from "../../../hooks/useSeparated";
import axios, { AxiosError } from "axios";
import { showLoadingOverlaySubject } from "../../../commons/utils/ActionsSubject";
import useNotifier from "../../../hooks/useNotifier";
import GlobalContext from "../../providers/GlobalContext";

interface ModalSeparatedWithoutAuthProps {    
    request: IPedido;
    opened: boolean;
    needAuthCallBack: (cancelDate: Date) => void;
    onSeparateCallBack: () => void;
    onCancelSeparateCallBack: () => void;
    closeModal: () => void;
}

interface SeparatedResponse{
    needAuthorization: boolean;
    message: string;
}

export const ModalSeparatedWithoutAuth: React.FC<ModalSeparatedWithoutAuthProps> = ({
    request,
    opened,
    needAuthCallBack,
    onSeparateCallBack,
    onCancelSeparateCallBack,
    closeModal,
}) => {
    const title: string = 'Aparta mercancía';
    const { showSucessMessage, showSucessCancelMessage, showErrorMessage } = useSeparated({title, onSeparateCallBack, onCancelSeparateCallBack});
    const [cancelationDate, setCancelationDate] = useState<Date>(addDay(new Date(), 1));
    const { processAxiosError, showMessage } = useNotifier();
    const {globalContext} = useContext(GlobalContext);
    const { executeRequest } = useRequest();

    const separatedDate = getFormattedDateTimeString(new Date());
    const icon = <FontAwesomeIcon icon={faCalendar}  style={{ width: rem(18), height: rem(18) }} />;


    const executeSeparatedRequest = useCallback(() => {
        const requestData = {
            pedido: request.idPedido,
            tkmov: request.tkmov,
            empresa: request.idEmpresa,
            separatedDate: separatedDate,
            cancelationDate: getFormattedDateTimeString(cancelationDate)
            };
        showLoadingOverlaySubject.next(true);
        axios({
            url: `${document.referrer}${globalContext.parentContext}/sales/separated?action=separate`,
            method: "POST",
            data: requestData,
            withCredentials: true
        }).then((response) => {
            showSucessMessage();
        }).catch((error) => {
            const axiosError = error as AxiosError;
            if(axiosError.response?.data){
                const responseData = axiosError.response?.data;
                if(typeof responseData === 'string'){
                    processAxiosError(error as AxiosError<string>);
                }else{
                    const parsedResponse = responseData as SeparatedResponse;
                    if(parsedResponse.needAuthorization){
                        showMessage('Alerta', parsedResponse.message, 'warning');
                        needAuthCallBack(cancelationDate);
                    }
                }
            }
        }).finally(() => {
            showLoadingOverlaySubject.next(false);
        });
    }, [cancelationDate, processAxiosError]);

    const handleCancel = () => {
        const requestData = {
            pedido: request.idPedido,
            tkmov: request.tkmov,
            empresa: request.idEmpresa,
        };
        executeRequest({
            url:`/sales/separated?action=cancel-separate`,
            method: "POST",
            body: requestData,
            callback: showSucessCancelMessage,
            errorCallback: handleError
        });
    }

    const handleError = () => {
        showErrorMessage();
        closeModal();
    }

    const handleFechaDeCancelacionChange = (date: DateValue) => {
        setCancelationDate(date || new Date());
    }

    return (
        <Modal
        opened={opened}
        onClose={closeModal}
        title={title}
        centered
        size="md"
        >
        <Box>
            <Text>Fecha: {getFormattedDateString(new Date())}</Text>
            <DateTimePicker
                    valueFormat="DD-MM-YYYY HH:mm"
                    leftSection={icon}
                    clearable
                    value={cancelationDate}
                    label="Fecha de cancelación"
                    onChange={handleFechaDeCancelacionChange}
                />
            <Group justify="center" mt="md">
            <Button onClick={handleCancel} variant="outline">Cancelar apartado</Button>
            <Button onClick={executeSeparatedRequest}>Apartar</Button>
            </Group>
        </Box>
        </Modal>
    );
};