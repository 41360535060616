import { NumberFormatter } from "@mantine/core";

interface CurrencyProps {
    amount: number | string;
    currency?: string | undefined;
    decimalsSeparator?: string | undefined;
    thousandSeparator?: string | undefined;
};

const Currency: React.FC<CurrencyProps> = ({
    amount,
    currency,
    decimalsSeparator,
    thousandSeparator
}) => {
    return (<NumberFormatter thousandSeparator={thousandSeparator || ","} decimalSeparator={decimalsSeparator || "."} 
        decimalScale={2} prefix={currency || "$ "} value={amount} fixedDecimalScale={true} />);
}

export default Currency;