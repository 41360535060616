import React, { createContext, useState } from 'react';
import { ContextEnum } from '../../commons/enums/RequestContextEnum';
import { ColumnOrderInterface } from '../../commons/interfaces/MasterFilterInterfaces';

export interface ScopeContext{
    scopeUrl: string;
    scopeContext: ContextEnum;
    lazyFetch: boolean;
    order?: ColumnOrderInterface;
}

interface ScopeContextState {
    scope: ScopeContext;
    setScope: React.Dispatch<React.SetStateAction<ScopeContext>>;
}

const defaultValue: ScopeContextState = {
    scope: {scopeUrl: '', scopeContext: ContextEnum.UNKNOWN, lazyFetch: false},
    setScope: () => {}, // Función vacía como valor predeterminado
};


const ScopeContext = createContext<ScopeContextState>(defaultValue);

type ScopeProviderProps = {
    scopeUrl: string;
    scopeContext: ContextEnum;
    lazyFetch?: boolean;
    order?: ColumnOrderInterface;
    children?: React.ReactNode;
};


export const ScopeProvider: React.FC<ScopeProviderProps> = ({ children, scopeUrl, scopeContext, lazyFetch = false}) => {
    const [scope, setScope] = useState<ScopeContext>({scopeUrl: scopeUrl, scopeContext: scopeContext,lazyFetch: lazyFetch});

    return (
        <ScopeContext.Provider value={{scope, setScope}}>
            {children}
        </ScopeContext.Provider>
    );
};

export default ScopeContext;