import { Accordion, Drawer, Grid, Space, Table, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { forwardRef, ReactNode, useImperativeHandle, useState } from 'react';
import useRequest from '../../../hooks/useRequest';
import { IPedido, PackagingSupplyInfo } from '../../../commons/interfaces/Pedidos';
import { notifications } from '@mantine/notifications';
import useLegacyStorage from '../../../hooks/useLegacyStorage';

export interface SupplyDetailsProps {
    request: IPedido;
}

export interface SupplyDetailMethods {
    toggleDrawer: () => void;
}

const SupplyDetailsDrawer = forwardRef<SupplyDetailMethods, SupplyDetailsProps>(({request}, ref) => {
    const {getResourceUrl} = useLegacyStorage();
    const {executeRequestAndCallbackData} = useRequest();
    const [supplyDetails, setSupplyDetails] = useState<PackagingSupplyInfo>();
    const [opened, { open, close }] = useDisclosure(false, {
        onOpen: () => executeRequestAndCallbackData({url:`/warehouse/packaging-supply?request=${request.idPedido}&tkmov=${request.tkmov}&company=${request.idEmpresa}`, method:'get', callback: requestCallback}),
    });

    useImperativeHandle(ref, () => {
        return {
            toggleDrawer() {
                if (opened) {
                    close();
                }else{
                    open();
                }
            }
        };
    }, []);

    const requestCallback = (response: any) => {
        if(!response){
            notifications.show({
                color: 'orange',
                title: 'No se encontrados datos.',
                message: `No se encontrados datos del surtido para el pedido ${request.idPedido}`,
            })
            close();
            return;
        }else{
            const castedResponse = response as PackagingSupplyInfo;
            setSupplyDetails(castedResponse);
        }
        
    }

    const packagingDetailsrows = supplyDetails?.packagingDetails?.map((detail, index) => {
        return (
            <Table.Tr key={index}>
                <Table.Td>{detail.productId}</Table.Td>
                <Table.Td>{detail.color}</Table.Td>
                <Table.Td>{detail.piecesPerBox}</Table.Td>
                <img src={getResourceUrl(detail.imgUrl, detail.urlLegacy)} width="50" alt='X'/>
                <Table.Td>{detail.requestPieces}</Table.Td>
                <Table.Td>{detail.requestBoxes}</Table.Td>
                <Table.Td>{detail.extraProd}</Table.Td>
                <Table.Td>{detail.requestePiecesNet}</Table.Td>
                <Table.Td>{detail.piecesSurtNet}</Table.Td>
                <Table.Td>{detail.description}</Table.Td>
            </Table.Tr>
        );
    });

    const packagingExtraDetailsRows = supplyDetails?.packagingDetails?.map((detail) => detail.extraDetails?.map((extraDetail, index) => {
            return (
                <Table.Tr key={index}>
                    <Table.Td>{detail.productId}</Table.Td>
                    <Table.Td>{detail.color}</Table.Td>
                    <Table.Td>{extraDetail.user}</Table.Td>
                    <Table.Td>{extraDetail.piecesPerBox}</Table.Td>
                    <Table.Td>{extraDetail.closedBoxes}</Table.Td>
                    <Table.Td>{extraDetail.detailedPieces}</Table.Td>
                    <Table.Td>{extraDetail.piecesNet}</Table.Td>
                </Table.Tr>
            );
        }));

    return (
        <>
        <Drawer
                offset={0}
                opened={opened}
                onClose={close}
                position="right"
                size="80%"
                title="Detalle del surtido"
                >
                {supplyDetails?.packagingDetails && 
                    <>
                        <Grid gutter="lg">
                            <Grid.Col span={2}>
                                <Text fw={700}>No Pedido</Text>
                            </Grid.Col>
                            <Grid.Col span={4}>{request.idPedido}</Grid.Col>

                            <Grid.Col span={2}>
                                <Text fw={700}>No Cajas</Text>
                            </Grid.Col>
                            <Grid.Col span={4}>{request.cajas}</Grid.Col>

                            <Grid.Col span={2}>
                                <Text fw={700}>Cliente</Text>
                            </Grid.Col>
                            <Grid.Col span={4}>{request.nombreCliente}</Grid.Col>

                            <Grid.Col span={2}>
                                <Text fw={700}>Ejecutivo</Text>
                            </Grid.Col>
                            <Grid.Col span={4}>{request.nombreVendedor}</Grid.Col>

                            <Grid.Col span={2}><Text fw={700}>Ruta</Text></Grid.Col>
                            <Grid.Col span={4}>{request.ruta}</Grid.Col>
                            <Grid.Col span={2}><Text fw={700}>Fecha de Entrega</Text></Grid.Col>
                            <Grid.Col span={4}>{request.fentrega}</Grid.Col>
                        </Grid>
                        <Space h="lg" />
                        <Table>
                            <Table.Thead>
                                <Table.Tr>
                                    <Table.Th>Articulo</Table.Th>
                                    <Table.Th>Color</Table.Th>
                                    <Table.Th>Pzas x cj</Table.Th>
                                    <Table.Th>img</Table.Th>
                                    <Table.Th>Ped Pzas</Table.Th>
                                    <Table.Th>Ped Cjs</Table.Th>
                                    <Table.Th>Extra Prod.</Table.Th>
                                    <Table.Th>Ped Pzas Neto</Table.Th>
                                    <Table.Th>Pzas Surt. Neto</Table.Th>
                                    <Table.Th>Descripcion</Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>{packagingDetailsrows}</Table.Tbody>
                        </Table>
                        <Space h="lg" />
                        <Accordion pt={20} variant="contained">
                            <Accordion.Item key={`supply-details`} value={`Detalles adicionales del surtido`}>
                                <Accordion.Control >{`Detalles adicionales del surtido`}</Accordion.Control>
                                <Accordion.Panel>
                                <Table>
                                    <Table.Thead>
                                        <Table.Tr>
                                            <Table.Th>Articulo</Table.Th>
                                            <Table.Th>Color</Table.Th>
                                            <Table.Th>Usuario</Table.Th>
                                            <Table.Th>Pzas x cj</Table.Th>
                                            <Table.Th>Cajas cerradas</Table.Th>
                                            <Table.Th>Pzas sueltas</Table.Th>
                                            <Table.Th>Pzas Neto</Table.Th>
                                        </Table.Tr>
                                    </Table.Thead>
                                    <Table.Tbody>{packagingExtraDetailsRows}</Table.Tbody>
                                </Table>
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                    </>
                }
            </Drawer>
        </>
    );
});

export default SupplyDetailsDrawer;