import React, { createContext, useState, Dispatch, SetStateAction } from 'react';

interface RefreshTabsContextState {
    refreshTabs: boolean;
    setRefreshTabs: Dispatch<SetStateAction<boolean>>;
    abortControllers: Record<string, AbortController>;
    registerAbortController: (name: string, signal: AbortController) => void;
    getAbortController: (name : string) => AbortController | null |void;
}

const defaultValue: RefreshTabsContextState = {
    refreshTabs: false,
    setRefreshTabs: () => {},
    abortControllers: {},
    registerAbortController: () => {},
    getAbortController: () => {}
};


const RefreshTabsContext = createContext<RefreshTabsContextState>(defaultValue);

type RefreshProviderProps = {
    children?: React.ReactNode;
};


export const RefreshTabsProvider = ({ children }: RefreshProviderProps) => {
    const [refreshTabs, setRefreshTabs] = useState<boolean>(false);
    const [abortControllers, setAbortControllers] = useState<Record<string, AbortController>>({});

    const getAbortController = (name: string) => {
        const _name: string | undefined = Object.keys(abortControllers).find(k => k == name);
        return (_name) ? abortControllers[_name] : undefined;
    }

    const registerAbortController = (name: string, signal: AbortController) => {
        const _cs = {...abortControllers};
        _cs[name] = signal;
        setAbortControllers(_cs);
    }

    return (
        <RefreshTabsContext.Provider value={{refreshTabs, setRefreshTabs, abortControllers: abortControllers, getAbortController, registerAbortController}}>
            {children}
        </RefreshTabsContext.Provider>
    );
};

export default RefreshTabsContext;