import {useCallback} from 'react';
import { notifications } from '@mantine/notifications';
import { AxiosError } from 'axios';

type MessageType = 'warning' | 'error' | 'success';

type NotifierHook = {
    showMessage: (title: string, message: string, type: MessageType) => void;
    processAxiosError: (axiosError: AxiosError<string>) => string;
};

const useNotifier = (): NotifierHook => {

    const showMessage = useCallback((title: string, message: string, type: MessageType) => {
        let color = 'blue';
        if (type === 'warning') {
            color = 'yellow';
        } else if (type === 'error') {
            color = 'red';
        } else if (type === 'success') {
            color = 'green';
        }
        notifications.show({
            title,
            message,
            color,
        });
    }, []);

    const processAxiosError = useCallback((axiosError: AxiosError<string>): string => {
        const title = 'Error';
        const errorColor = 'red'
        const genericMessage = 'Ocurrio un error al procesar su petición.';
        if (axiosError.message == 'canceled') {
            return "";
        }
        if (axiosError.response) {
            if (axiosError.response.status >= 400 && axiosError.response.status < 500) {
            console.log('[useNotifier] This is a 4xx error');
            notifications.show({
                title: title,
                message: axiosError.response.data,
                color: errorColor,
            });
            } else {
            console.log("[useNotifier] This isn't a 4xx error");
            notifications.show({
                title: title,
                message: genericMessage,
                color: errorColor,
            });
            }
            return axiosError.response.data;
        } else {
            notifications.show({
            title: title,
            message: genericMessage,
            color: errorColor,
            });
            return 'Unknown error';
        }
    }, []);



    return { showMessage, processAxiosError };
};

export default useNotifier;