import { Button, Menu, Text } from '@mantine/core';
import React, { useContext, useRef, useState } from 'react';

import { iconStyles } from "../../styles";
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadPaymentFile from '../modals/files/UploadPaymentFile';
import UploadPurchaseOrderFiles from '../modals/files/UploadPurchaseOrderFiles';
import { IPedido } from '../../commons/interfaces/Pedidos';
import useMenuActions from '../../hooks/useMenuActions';
import { ContextEnum } from '../../commons/enums/RequestContextEnum';
import ScopeContext from '../providers/ScopeContext';
import ItemPrint from './menuActionsItems/ItemPrint';
import ItemNewIncident from './menuActionsItems/ItemNewIncident';
import ItemCancelSupply from './menuActionsItems/ItemCancelSupply';
import ItemMarkAsLost from './menuActionsItems/ItemMarkAsLost';
import useRights from '../../hooks/useRights';
import SupplyDetailsDrawer, { SupplyDetailMethods } from '../drawer/supply-details/SupplyDetails';

interface MenuActionsProps {
    lostQuotations?: boolean;
    request: IPedido;
}

const MenuActions: React.FC<MenuActionsProps> = (props) => {
    const ref = useRef<SupplyDetailMethods>(null);
    const [opened, setOpened] = useState(false);
    const [paymentFileModalOpen, setPaymentFileModalOpened] = useState(false);
    const [OrderFileModalOpen, setOrderFileModalOpened] = useState(false);
    const {showControlledInput} = useRights();
    const { scope } = useContext(ScopeContext);

    const { handlePrintReport, redirectFactAnticipo, redirectToIncident } = useMenuActions({
        pedido: props.request.idPedido,
        tkmov: props.request.tkmov,
        empresa: props.request.idEmpresa
    });

    const openSupplyDetailsDrawer = () => {
        console.log('ref', ref);
        if (ref.current) {
            ref.current.toggleDrawer();
        }
    };

    const onCloseModal = () => {
        setPaymentFileModalOpened(false);
        setOrderFileModalOpened(false);
    }

    return (
        <>
            <Menu opened={opened} onChange={setOpened} position="left" withArrow>
                <Menu.Target>
                    <Button style={iconStyles.normal}>
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                    </Button>
                </Menu.Target>

                <Menu.Dropdown>
                    {props.request.validacionStatusPrepedido === 0 &&
                        <ItemPrint request={props.request} disabled={false} controlId='BtnGridImp'/>
                    }

                    <Menu.Item closeMenuOnClick={false}>
                        <Menu shadow="md" position="left" withArrow>
                            <Menu.Target>
                                <Text size="sm">Subir Archivos</Text>
                            </Menu.Target>
                            
                            <Menu.Dropdown>
                                <Menu.Item onClick={() => setPaymentFileModalOpened(true)}>Archivo de pago</Menu.Item>
                                <Menu.Item onClick={() => setOrderFileModalOpened(true)}>Orden de compra</Menu.Item>
                            </Menu.Dropdown>
                        
                        </Menu>
                    </Menu.Item>

                    { (scope.scopeContext !== ContextEnum.INVOICED_REQUEST && showControlledInput({rightControlId: 'BtnGridGenFact'})) &&
                        <Menu.Item onClick={() => redirectFactAnticipo()}><Text size="sm">Generar anticipo</Text></Menu.Item>
                    }

                    {(scope.scopeContext === ContextEnum.QUOTATION || scope.scopeContext === ContextEnum.ALL_REQUESTS) && 
                        <ItemMarkAsLost request={props.request}/>
                    }
                    {(scope.scopeContext === ContextEnum.INVOICED_REQUEST || scope.scopeContext === ContextEnum.ALL_REQUESTS || scope.scopeContext === ContextEnum.ACTIVE_REQUEST) && 
                        <>
                            <Menu.Item onClick={openSupplyDetailsDrawer}><Text size="sm">Detalle de surtido</Text></Menu.Item>
                            <ItemNewIncident request={props.request} disabled={false} controlId='BtnGridGenInci'/>
                        </>
                    }
                    {(scope.scopeContext === ContextEnum.ACTIVE_REQUEST || scope.scopeContext === ContextEnum.ALL_REQUESTS) && 
                        <>
                            <ItemCancelSupply request={props.request} disabled={false} controlId='BtnGridGenInci' />
                        </>
                    }
                </Menu.Dropdown>
            </Menu>
            {paymentFileModalOpen && 
                <UploadPaymentFile request={props.request} openModal={paymentFileModalOpen} onCloseCallback={onCloseModal}/>
            }
            {OrderFileModalOpen && 
                <UploadPurchaseOrderFiles request={props.request} openModal={OrderFileModalOpen} onCloseCallback={onCloseModal}/>
            }
            <SupplyDetailsDrawer ref={ref} request={props.request}/>
        </>
    );
};

export default MenuActions;