import { BehaviorSubject } from "rxjs";
import { ContextEnum } from "../enums/RequestContextEnum";

interface RefreshTablePayload{
    scopeContext: ContextEnum;
}

interface ProductColorPayload{
    scopeContext: ContextEnum;
    productId: string;
}


export const showLoadingOverlaySubject = new BehaviorSubject<boolean>(false);
export const isRequestLoadingSubject = new BehaviorSubject<number>(0);
export const refreshTable = new BehaviorSubject<RefreshTablePayload>({scopeContext: ContextEnum.UNKNOWN});
export const fetchProductColors = new BehaviorSubject<ProductColorPayload | null>(null);
