
const baseStyle = {
    backgroundColor: "#E9ECEF",
};

export const filterZoneBackground = {
    root: {
        ...baseStyle,
    },
};

export const tableZoneBackground = {
    root: {
        backgroundColor: "#F8F9FA",
    },
};

export const tableStyles = {
    table: {
        backgroundColor: "white",
    },
    td: {
        whiteSpace: "nowrap",
    },
    tr: {
        whiteSpace: "nowrap",
    }
};