import { CloseButton, Combobox, Loader, TextInput, useCombobox } from '@mantine/core';
import React, { useContext, useRef, useState } from 'react';
import { BasicInputProps, ClientsInfoDTO, ExtendedInputProps } from '../../commons/interfaces/SelectInterfaces';
import axios from 'axios';
import GlobalContext from '../providers/GlobalContext';


    

const ClientSimpleSelect: React.FC<ExtendedInputProps> = (props) => {
    const [value, setValue] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ClientsInfoDTO[]>([]);
    const [empty, setEmpty] = useState(false);
    const {globalContext} = useContext(GlobalContext);
    const abortController = useRef<AbortController>();

    function getAsyncData(searchQuery: string, signal: AbortSignal) {
        return new Promise<ClientsInfoDTO[]>((resolve, reject) => {
            signal.addEventListener('abort', () => {
                reject(new Error('Request aborted'));
            });
            if(searchQuery){
                axios(
                    {
                        url: `${document.referrer}${globalContext.parentContext}/clients?action=find-client&criteria=${searchQuery.toUpperCase()}`,
                        method: 'get',
                        withCredentials: true,
                    }).then((response) => {
                        const data = response.data as ClientsInfoDTO[];
                        resolve(data);
                    });
            }
        });
    }

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });

    const getRightSection = () => {
        if (loading) {
            return <Loader size={18} />;
        }else{
            if(value){
                return (<CloseButton
                size="sm"
                onMouseDown={(event) => event.preventDefault()}
                onClick={() => {
                    setValue('');
                    // props.onChange('');
                }}
                aria-label="Clear value"
                />);
            }else{
                return <Combobox.Chevron />;
            }
        }
    }

    const fetchOptions = (query: string) => {
        abortController.current?.abort();
        abortController.current = new AbortController();
        setLoading(true);
    
        getAsyncData(query, abortController.current.signal)
            .then((result) => {
                setData(result);
                setLoading(false);
                setEmpty(result.length === 0);
                abortController.current = undefined;
            }).catch(() => {});
    };

    const options = data.map((item) => (
        <Combobox.Option value={`${item.idCliente}@${item.nombreCliente}`} key={item.idCliente}>
        {item.nombreCliente}
        </Combobox.Option>
    ));

    return (
        <Combobox
        store={combobox}
        withinPortal={false}
        onOptionSubmit={(val) => {
            const [idCliente, nombreCliente] = val.split('@');
            setValue(nombreCliente);
            props.onChange(idCliente);
            combobox.closeDropdown();
        }}
        >
        {/* <Combobox.Target>
            <InputBase
                id={props.id}
                label={props.label}
                component="button"
                type="button"
                pointer
                // rightSection={loading ? <Loader size={18} /> : <Combobox.Chevron />}
                rightSection={
                    value !== null ? (
                        <CloseButton
                        size="sm"
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={() => {
                            setValue(null)
                            props.onChange('');
                            }
                        }
                        aria-label="Clear value"
                        />
                    ) : (
                        <Combobox.Chevron />
                    )
                }
                onClick={() => combobox.toggleDropdown()}
                rightSectionPointerEvents={value === null ? 'none' : 'all'}
                >
                {value || <Input.Placeholder></Input.Placeholder>}
            </InputBase>
        </Combobox.Target> */}
        <Combobox.Target>
            <TextInput
                id={props.id}
                label={props.label}
                value={value || ''}
                onChange={(event) => {
                    setValue(event.currentTarget.value);
                    fetchOptions(event.currentTarget.value);
                    combobox.resetSelectedOption();
                    combobox.openDropdown();
                }}
                onClick={() => combobox.openDropdown()}
                onFocus={() => {
                    combobox.openDropdown();
                }}
                onBlur={() => combobox.closeDropdown()}
                rightSection={getRightSection()}
            />
        </Combobox.Target>

        <Combobox.Dropdown>
            <Combobox.Options>
            {loading ? <Combobox.Empty>Loading....</Combobox.Empty> : options}
            </Combobox.Options>
        </Combobox.Dropdown>
        </Combobox>
    );
};

export default ClientSimpleSelect;