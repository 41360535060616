import React, { useCallback } from 'react';
import { IPedido } from '../../../commons/interfaces/Pedidos';
import { Menu, Text } from '@mantine/core';
import useMenuActions from '../../../hooks/useMenuActions';
import useRights from '../../../hooks/useRights';

interface MenuItemActionsProps {
    disabled?: boolean;
    controlId: string;
    request: IPedido;
}

const ItemNewIncident: React.FC<MenuItemActionsProps> = (props) => {
    const { showControlledInput } = useRights();
    const { redirectToIncident } = useMenuActions({
        pedido: props.request.idPedido,
        tkmov: props.request.tkmov,
        empresa: props.request.idEmpresa
    });

    const show = useCallback(() => {
        return showControlledInput({rightControlId: props.controlId, disabled: props.disabled});
    }, [props.controlId, props.disabled, showControlledInput]);

    return (
        <>
            <Menu.Item onClick={() => redirectToIncident()} disabled={!show()}>
                <Text size="sm">Generar incidencia</Text>
            </Menu.Item>
        </>
    );
};

export default ItemNewIncident;