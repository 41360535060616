import { Select } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { BasicInputProps, ExtendedInputProps } from '../../commons/interfaces/SelectInterfaces';
import { SelectOptionInterface } from '../../commons/interfaces/MasterFilterInterfaces';
import { useUncontrolled } from '@mantine/hooks';

export interface ThreeStateFilterProps extends ExtendedInputProps {
    options?: SelectOptionInterface[];
}

const constOptions = [
    { value: 'T', label: 'Todos' },
    { value: '1', label: 'Si' },
    { value: '0', label: 'No' },
]

const ThreeStateFilter: React.FC<ThreeStateFilterProps> = ({id, label, value, options, defaultValue, onChange}) => {

    const [_value, handleChange] = useUncontrolled({
        value,
        defaultValue: defaultValue || 'T',
        finalValue: 'Final',
        onChange,
    });

    const getOptions = (): ({ value: string; label: string })[] | undefined => {
        return options?.map((option) => {
            return { value: option.value, label: option.label };
        });
    }

    return (
        <Select
            key={id}
            id={id}
            label={label}
            maw={100}
            checkIconPosition="right"
            data={options ? getOptions() : constOptions}
            value={_value}
            onChange={(value, option) => {
                handleChange( value || '');
            }}
        />
    );
};

export default ThreeStateFilter;