import { Button, Divider, Flex, Grid, Table, Text } from "@mantine/core";
import { ResupplyRSDTO } from "../../../commons/interfaces/Movements";


interface RegularModalContentProps {
    canContinueCallback: (canContinue: boolean) => void;
    resupplyResponse: ResupplyRSDTO;
}

const RegularModalContent: React.FC<RegularModalContentProps> = ({ canContinueCallback, resupplyResponse }) => {
    return (
    <>
        <Divider my="sm" />
        <Grid gutter="lg" justify="center">
            {resupplyResponse && resupplyResponse.needSupply &&
                <Grid.Col span={12}>
                    <Text fw={700}>{`El pedido ${resupplyResponse.pedido} requiere requisición para los articulos: `}</Text>
                </Grid.Col>
            }

            <Grid.Col span={12}>
                <Table>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>id Articulo</Table.Th>
                            <Table.Th>Info requisición</Table.Th>
                        </Table.Tr>
                    </Table.Thead>

                    {resupplyResponse && resupplyResponse.resupplyDetail &&
                        <Table.Tbody>
                            {resupplyResponse.resupplyDetail.map(detail => {
                                return (
                                    <Table.Tr key={detail.productId}>
                                        <Table.Td>{detail.productId}</Table.Td>
                                        <Table.Td>{detail.message}</Table.Td>
                                    </Table.Tr>
                                )
                            })}
                        </Table.Tbody>
                    }
                </Table>
            </Grid.Col>

        </Grid>
        <Divider my="sm" />
        <Text >Deseas continuar con la requisición?</Text>
        <Divider my="sm" />
        <Flex
            mih={50}
            gap="md"
            justify="center"
            align="flex-end"
            direction="row"
            wrap="wrap"
        >
            <Button variant="danger" onClick={() => canContinueCallback(false)}>No</Button>
            <Button variant="primary" onClick={() => canContinueCallback(true)}>Si, continuar</Button>
        </Flex>
    </>
    );
}

export default RegularModalContent;