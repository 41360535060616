import { CheckIcon, Combobox, Group, Pill, PillsInput, useCombobox } from '@mantine/core';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ClientsInfoDTO, ExtendedInputProps } from '../../commons/interfaces/SelectInterfaces';
import GlobalContext from '../providers/GlobalContext';
import { useUncontrolled } from '@mantine/hooks';


const ClientSelect: React.FC<ExtendedInputProps> = (props) => {
    const [selectedValues, setSelectedValues] = useState<Map<string, string>>(new Map<string, string>());
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ClientsInfoDTO[]>([]);
    const [search, setSearch] = useState('');
    const {globalContext} = useContext(GlobalContext);
    const abortController = useRef<AbortController>();

    const [_value, handleChange] = useUncontrolled({
        value: props.value,
        defaultValue: props.defaultValue,
        finalValue: 'Final',
        onChange: props.onChange,
    });

    function getAsyncData(searchQuery: string, signal: AbortSignal) {
        return new Promise<ClientsInfoDTO[]>((resolve, reject) => {
            signal.addEventListener('abort', () => {
                reject(new Error('Request aborted'));
            });
            if(searchQuery){
                axios(
                    {
                        url: `${document.referrer}${globalContext.parentContext}/clients?action=find-client&criteria=${searchQuery.toUpperCase()}`,
                        method: 'get',
                        withCredentials: true,
                    }).then((response) => {
                        const data = response.data as ClientsInfoDTO[];
                        resolve(data);
                    });
            }
        });
    }

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });

    const fetchOptions = (query: string) => {
        abortController.current?.abort();
        abortController.current = new AbortController();
        setLoading(true);
    
        getAsyncData(query, abortController.current.signal)
            .then((result) => {
                setData(result);
                setLoading(false);
                abortController.current = undefined;
            }).catch(() => {});
    };

    const handleValueSelect = (val: string) => {
        const splitVal = val.split("@");
        const clientId = splitVal[0];
        const clientName = splitVal[1];
        setSelectedValues((prevMap) => new Map(selectedValues.set(clientId, clientName)));
        setSearch('');
    }

    useEffect(() => {
        if(selectedValues.size !== 0){
            const values: string[] = Array.from(selectedValues).map(([key, value]) => key);
            // props.onChange(values);
            // values.join(',');
            handleChange(values.join(','));
        }else{
            handleChange('');
        }
    }, [selectedValues]);

    const removeEntry = (keyToRemove: string) => {
        setSelectedValues((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.delete(keyToRemove);
            return newMap;
        });
    };

    const handleValueRemove = () =>{
        setSelectedValues((prevMap) => {
            const entries = Array.from(prevMap.entries());
            entries.pop();
            return new Map(entries);
        });
    }

    const handleValueRemoveByKey = (key: string) =>{
        const clientKey = key;
        removeEntry(clientKey);
    }

    const values = Array.from(selectedValues).map(([key, value]) => (
        <Pill key={key} withRemoveButton onRemove={() => handleValueRemoveByKey(key)}>
        {value}
        </Pill>
    ));

    const options = (data || []).map((item) => (
            <Combobox.Option value={`${item.idCliente}@${item.nombreCliente}`} key={item.idCliente} active={selectedValues.has(item.idCliente)}>
                <Group gap="sm">
                {selectedValues.has(item.idCliente) ? <CheckIcon size={12} /> : null}
                <span>{item.nombreCliente}</span>
                </Group>
            </Combobox.Option>
            ));

    return (
            <Combobox
            store={combobox}
            onOptionSubmit={handleValueSelect}
            withinPortal={false}>
            <Combobox.DropdownTarget>
                <PillsInput
                    id={props.id}
                    label={props.label}
                    onClick={() => combobox.openDropdown()}
                >
                <Pill.Group>
                    {values}
        
                    <Combobox.EventsTarget>
                    <PillsInput.Field
                        onFocus={() => combobox.openDropdown()}
                        onBlur={() => combobox.closeDropdown()}
                        value={search}
                        onChange={(event) => {
                            fetchOptions(event.currentTarget.value);
                            setSearch(event.currentTarget.value);
                            combobox.updateSelectedOptionIndex();
                            combobox.openDropdown();
                        }}
                        onKeyDown={(event) => {
                        if (event.key === 'Backspace' && search.length === 0) {
                            event.preventDefault();
                            handleValueRemove();
                        }
                        }}
                    />
                    </Combobox.EventsTarget>
                </Pill.Group>
                </PillsInput>
            </Combobox.DropdownTarget>
        
            <Combobox.Dropdown hidden={data.length === 0}>
                <Combobox.Options>
                {options.length > 0 ? options : <Combobox.Empty>Loading...</Combobox.Empty>}
                </Combobox.Options>
            </Combobox.Dropdown>
            </Combobox>
        );
};

export default ClientSelect;