import { colors } from "./colors";

const baseStyle = {
  display: "inline-flex",
  height: "38px",
  padding: "0px 12px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flexShrink: "0",
  borderRadius: "4px",
};

export const buttonStyles = {
  primaryButton: {
    ...baseStyle,
    background: colors.darkBlue,
  },
  secondaryButton: {
    ...baseStyle,
    background: "#FFF",
    color: colors.darkBlue,
    border: colors.darkBlue,
  },
};
