import React, { useContext, useEffect, useState } from 'react';
import { CloseButton, Combobox, Input, InputBase, Loader, useCombobox } from '@mantine/core';
import { BasicInputProps, ExtendedInputProps } from '../../commons/interfaces/SelectInterfaces';
import { UserToNotifyInfoDTO } from '../../commons/interfaces/Users';
import axios from 'axios';
import GlobalContext from '../providers/GlobalContext';



interface AuthUsersSelectProps extends ExtendedInputProps {
    authType: number;
    isDataPreloaded?: boolean;
    preloadedData?: UserToNotifyInfoDTO[];
    selectedValue?: string;
}

const AuthUsersSelect: React.FC<AuthUsersSelectProps> = ({id, label, authType, isDataPreloaded, preloadedData, selectedValue, onChange}) => {
    const [data, setData] = useState<UserToNotifyInfoDTO[]>([]);
    const [value, setValue] = useState<string | null>(null);
    const {globalContext} = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (data.length === 0 && !isDataPreloaded) {
            setLoading(true);
            axios(
                {
                    url: `${document.referrer}${globalContext.parentContext}/user-session?action=auth-users&authType=${authType}`,
                    method: 'get',
                    withCredentials: true,
                }).then((response) => {
                    const data = response.data.map((item: any) => ({userId: item.userId, email: item.email, name: item.name}));
                    setValue(data.length > 0 ? `${data[0].email}` : null);
                    onChange(`${data[0].userId}|${data[0].email}`);
                    setData(data);
                    setLoading(false);
                });
        }else if(preloadedData){
            if(selectedValue){
                setValue(selectedValue);
            }else{
                setValue(preloadedData.length > 0 ? `${preloadedData[0].email}` : null);
            }
            setData(preloadedData);
        }
    }, [authType, data, globalContext, isDataPreloaded, preloadedData]);


    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownOpen: (eventSource) => {
            if (eventSource === 'keyboard') {
                combobox.selectActiveOption();
            } else {
                combobox.updateSelectedOptionIndex('active');
            }
        },
    });

    const options = data.map((user, index) => (
        <Combobox.Option value={`${user.userId}|${user.email}`} key={user.userId} active={index === 0}>
        {user.name} - {user.email}
        </Combobox.Option>
    ));

    return (
        <Combobox
        store={combobox}
        withinPortal={false}
        onOptionSubmit={(val) => {
            setValue(val.split('|')[1]);
            onChange(val);
            combobox.closeDropdown();
        }}
        >
        <Combobox.Target>
            <InputBase
                id={id}
                label={label}
                component="button"
                type="button"
                pointer
                rightSection={
                    value !== null ? (
                        <CloseButton
                        size="sm"
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={() => {
                            setValue(null)
                            onChange('');
                            }
                        }
                        aria-label="Clear value"
                        />
                    ) : (
                        loading ? <Loader size={18} /> : <Combobox.Chevron />
                    )
                }
                onClick={() => combobox.toggleDropdown()}
                rightSectionPointerEvents={value === null ? 'none' : 'all'}
                >
                {value || <Input.Placeholder>Seleccione un correo</Input.Placeholder>}
            </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown>
            <Combobox.Options>
            {loading ? <Combobox.Empty>Loading....</Combobox.Empty> : options}
            </Combobox.Options>
        </Combobox.Dropdown>
        </Combobox>
    );
};

export default AuthUsersSelect;