import React, { useCallback, useEffect, useState } from 'react';
import { Container, Image, Text, Card, Divider, Table, Group, Grid, Box, Title, Anchor, rem } from '@mantine/core';

import { useSearchParams } from 'react-router-dom';
import useRequest from '../../hooks/useRequest';

const formatDouble = (value: number) => {
    return value.toLocaleString('es-MX', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

interface BackEndResult{
    fecha:string;
    motivo:string;
    noPedido:number;
    nomCliente:string;
    nomSucursal:string;
    nomVendedor:string;
    pedCliente:string;
    departamento:string;
    opcProduccion:string;
    nomPaqueteria:string;
    cedisSurte:string;
    cajasEmpaque:number;

    opcIva:string;
    moneda:string;
    fechaEntrega:string;
    tipoCambio:number;
    fechaCancelacion:string;
    opcValidacion:string;
    formEntrega:string;
    metodoPago:string;
    flete:string;

    observacion:string;
    descuentoUno:number;
    preciodescUno:number;
    descuentoDos:number;
    preciodescDos:number;
    descuentoTres:number;
    preciodescTres:number;

    subtotal:number;
    iva:number;
    total:number;

    listArticulos: ArticleEntity[];
}

interface ArticleEntity{
    idArticulo:string;
    descripcion:string;
    cantidad:number;
    surtidoInv:number;
    diferencia:number;
    precio:number;
    descuento:number;
    precioDesc:number;
    importe:number;
}

interface ResponseMessage {
    code: number;
    message: string;
}


const RequestOrderDetails: React.FC = () => {
    const [searchParams] = useSearchParams();
    const baseUrl = searchParams.get('baseUrl');
    const backUrl = searchParams.get('backUrl');
    const token = searchParams.get('token');

    const [backResponse, setBackResponse] = useState<BackEndResult | ResponseMessage | null>(null);

    const {executeRowRequestAndUpdateState} = useRequest();

    useEffect(() => {
        sendBackRequest();
    }, []);

    const sendBackRequest = () => {
        executeRowRequestAndUpdateState(
            {
                url: `${backUrl}?token=${token}`,
                method: 'GET',
                body: null,
                stateToUpdate: setBackResponse
            }
        );
    }

    const DetailOrderFor: React.FC<{ backResponse: BackEndResult }> = ({ backResponse }) => {
        return (
            <Container size="lg" py="md">
                <Group style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Image src={`${baseUrl}/images/logo_fp.png`} alt="For Promotional" w="25%" style={{ display: 'block' }}/>
                </Group>
                <Card shadow="sm" padding="lg" mt="md">
                    <Card.Section>
                        <Text ta="center" size="lg" style={{ fontWeight: 700, backgroundColor: '#023971', color:'white' }}>
                            Información
                        </Text>
                    </Card.Section>
                    <Group justify="center" grow>
                        <Text ta="center" size="md">
                            <span style={{ fontWeight: 700 }}>Motivo:</span> {backResponse.motivo} - {backResponse.nomVendedor}
                        </Text>
                    </Group>
                    <Divider my={20} style={{ borderTop: '2px solid #023971' }} />
                    <Grid>
                        <Grid.Col span={6}>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 700, width: 120}}>Fecha:</Text>
                                <Text size="sm">{backResponse.fecha}</Text>
                            </Group>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 700, width: 120}}>Cliente:</Text>
                                <Text size="sm">{backResponse.nomCliente}</Text>
                            </Group>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 700, width: 120}}>Vendedor:</Text>
                                <Text size="sm">{backResponse.nomVendedor}</Text>
                            </Group>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 700, width: 120}}>Departamento:</Text>
                                <Text size="sm">{backResponse.departamento}</Text>
                            </Group>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 600, width: 120}}>Paqueteria:</Text>
                                <Text size="sm">{backResponse.nomPaqueteria}</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 700, width: 120}}>Pedido:</Text>
                                <Text size="sm">{backResponse.noPedido}</Text>
                            </Group>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 700, width: 120}}>Sucursal:</Text>
                                <Text size="sm">{backResponse.nomSucursal}</Text>
                            </Group>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 700, width: 120}}>Ped. Cliente:</Text>
                                <Text size="sm">{backResponse.pedCliente}</Text>
                            </Group>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 700, width: 120}}>Producción:</Text>
                                <Text size="sm">{backResponse.opcProduccion}</Text>
                            </Group>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 700, width: 120}}>Surte:</Text>
                                <Text size="sm">{backResponse.cedisSurte}</Text>
                            </Group>
                        </Grid.Col>
                    </Grid>
                    <Divider my={20} style={{ borderTop: '2px solid #023971' }} />
                    <Grid>
                        <Grid.Col span={6}>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 700, width: 150}}>I.V.A. Incluido:</Text>
                                <Text size="sm">{backResponse.opcIva}</Text>
                            </Group>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 700, width: 150}}>Fecha Entrega:</Text>
                                <Text size="sm">{backResponse.fechaEntrega}</Text>
                            </Group>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 700, width: 150}}>Fecha Cancelación:</Text>
                                <Text size="sm">{backResponse.fechaCancelacion}</Text>
                            </Group>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 700, width: 150}}>Forma De Entrega:</Text>
                                <Text size="sm">{backResponse.formEntrega}</Text>
                            </Group>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 700, width: 150}}>Flete:</Text>
                                <Text size="sm">{backResponse.flete}</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 700, width: 150}}>Moneda Nacional:</Text>
                                <Text size="sm">{backResponse.moneda}</Text>
                            </Group>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 700, width: 150}}>T.C:</Text>
                                <Text size="sm">{backResponse.tipoCambio}</Text>
                            </Group>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 700, width: 150}}>Req. Valid.:</Text>
                                <Text size="sm">{backResponse.opcValidacion}</Text>
                            </Group>
                            <Group justify="apart">
                                <Text size="md" style={{fontWeight: 700, width: 150}}>Metodo de Pago:</Text>
                                <Text size="sm">{backResponse.metodoPago}</Text>
                            </Group>
                        </Grid.Col>
                    </Grid>
                    <Divider my={20} style={{ borderTop: '2px solid #023971' }} />
                    <Group justify="center" grow>
                        <Text ta="center" size="md">
                            <span style={{ fontWeight: 700 }}>Observaciones:</span> {backResponse.observacion}
                        </Text>
                    </Group>
                    <Divider my={20} style={{ borderTop: '2px solid #023971' }} />
                    <Table.ScrollContainer minWidth={500}>
                        <Table striped highlightOnHover withTableBorder withColumnBorders>
                            <Table.Thead style={{backgroundColor: '#023971', color:'white' }}>
                                <Table.Tr>
                                    <Table.Th ta="center">Clave</Table.Th>
                                    <Table.Th ta="center">Descripción</Table.Th>
                                    <Table.Th ta="center">Cantidad</Table.Th>
                                    <Table.Th ta="center">Surtido</Table.Th>
                                    <Table.Th ta="center">Diferencia</Table.Th>
                                    <Table.Th ta="center">Precio Unitario</Table.Th>
                                    <Table.Th ta="center">Desc</Table.Th>
                                    <Table.Th ta="center">Precio C/Desc</Table.Th>
                                    <Table.Th ta="center">Importe</Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                                {backResponse.listArticulos.map((articulo: ArticleEntity, index: number) => (
                                    <Table.Tr key={index}>
                                        <Table.Td ta="center">{articulo.idArticulo}</Table.Td>
                                        <Table.Td ta="center">{articulo.descripcion}</Table.Td>
                                        <Table.Td ta="center">{articulo.cantidad}</Table.Td>
                                        <Table.Td ta="center">{articulo.surtidoInv}</Table.Td>
                                        <Table.Td ta="center">{articulo.diferencia}</Table.Td>
                                        <Table.Td ta="center">${formatDouble(articulo.precio)}</Table.Td>
                                        <Table.Td ta="center">{formatDouble(articulo.descuento)}%</Table.Td>
                                        <Table.Td ta="center">${formatDouble(articulo.precioDesc)}</Table.Td>
                                        <Table.Td ta="right">${formatDouble(articulo.importe)}</Table.Td>
                                    </Table.Tr>
                                ))}
                            </Table.Tbody>
                            <Table.Tfoot>
                                <Table.Tr>
                                    <Table.Td ta="center" colSpan={6}></Table.Td>
                                    <Table.Td ta="center" colSpan={2} style={{fontWeight: 700, backgroundColor: '#023971', color:'white' }}>Desc Uno: {formatDouble(backResponse.descuentoUno)}%</Table.Td>
                                    <Table.Td ta="right">${formatDouble(backResponse.preciodescUno)}</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td ta="center" colSpan={6}></Table.Td>
                                    <Table.Td ta="center" colSpan={2} style={{fontWeight: 700, backgroundColor: '#023971', color:'white' }}>Desc Dos: {formatDouble(backResponse.descuentoDos)}%</Table.Td>
                                    <Table.Td ta="right">${formatDouble(backResponse.preciodescDos)}</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td ta="center" colSpan={6}></Table.Td>
                                    <Table.Td ta="center" colSpan={2} style={{fontWeight: 700, backgroundColor: '#023971', color:'white' }}>Desc Dos: {formatDouble(backResponse.descuentoDos)}%</Table.Td>
                                    <Table.Td ta="right">${formatDouble(backResponse.preciodescTres)}</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td ta="center" colSpan={7}></Table.Td>
                                    <Table.Td ta="center" style={{fontWeight: 700, backgroundColor: '#023971', color:'white' }}>Subtotal</Table.Td>
                                    <Table.Td ta="right">${formatDouble(backResponse.subtotal)}</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td ta="center" colSpan={7}></Table.Td>
                                    <Table.Td ta="center" style={{fontWeight: 700, backgroundColor: '#023971', color:'white' }}>I.V.A</Table.Td>
                                    <Table.Td ta="right">${formatDouble(backResponse.iva)}</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td ta="center" colSpan={7}></Table.Td>
                                    <Table.Td ta="center" style={{fontWeight: 700, backgroundColor: '#023971', color:'white' }}>Total</Table.Td>
                                    <Table.Td ta="right">${formatDouble(backResponse.total)}</Table.Td>
                                </Table.Tr>
                            </Table.Tfoot>
                        </Table>
                    </Table.ScrollContainer>
                </Card>
            </Container>
        );
    };

    const ErrorMessage: React.FC<{ message: string }> = ({ message }) => {
        return (
            <Box style={{ margin: 0, padding: 0, backgroundColor: 'white' }}>
                <Container style={{ padding: `${rem(20)} 0 ${rem(30)} 0` }}>
                    <Box
                        style={{
                            maxWidth: rem(550),
                            margin: '0 auto',
                            border: '1px solid #cccccc',
                            borderCollapse: 'collapse',
                        }}
                    >
                        <Box style={{ padding: `${rem(40)} 0 ${rem(30)} 0`, backgroundColor: '#e6e6e6', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Image src={`${baseUrl}/images/logo_fp.png`} alt="For Promotional" w="70%" style={{ display: 'block' }}/>
                        </Box>
                        <Box style={{ padding: `${rem(20)} ${rem(30)} ${rem(40)} ${rem(30)}`, backgroundColor: 'white' }}>
                            <Title order={2}>Respuesta</Title>
                            <Text dangerouslySetInnerHTML={{ __html: message }} />
                        </Box>
                        <Box style={{ padding: rem(30), backgroundColor: '#013971', color: 'white', textAlign: 'center' }}>
                            <Text>&reg; For Promotional, S.A. DE C.V.</Text>
                            <Text>
                                Entra{' '}<Anchor href="https://4promotional.net" style={{ color: 'white', textDecoration: 'none' }}>Aquí</Anchor>. Todos los derechos reservados
                            </Text>
                        </Box>
                    </Box>
                </Container>
            </Box>
        );
    };    


    const renderResult = useCallback(() => {
        if (backResponse && 'listArticulos' in backResponse) {
            return <DetailOrderFor backResponse={backResponse} />;
        } else if (backResponse && 'message' in backResponse) {
            return <ErrorMessage message={(backResponse as ResponseMessage).message} />;
        } else {
            return <div>No token or action found</div>;
        }
    
    },[backResponse]);

    return (
        <div>
            {renderResult()}
        </div>
    );

};

export default RequestOrderDetails;