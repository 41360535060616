import React, { useContext, useEffect, useState } from "react";
import { Button, Tabs, rem, Flex, LoadingOverlay } from "@mantine/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faClipboardCheck,
  faFileInvoice,
  faFileInvoiceDollar,
  faPeopleCarryBox,
  faRectangleXmark,
  faTableList,  } from '@fortawesome/free-solid-svg-icons';

import {
  Apartados,
  Cotizaciones,
  CotizacionesPerdidas,
  PedidosActivos,
  PedidosFacturados,
  Todos,
} from "../components/PedidosTabs";
import { showLoadingOverlaySubject, isRequestLoadingSubject } from "../commons/utils/ActionsSubject";
import { useDisclosure } from "@mantine/hooks";
import { ModalCopyOrder } from "../components/modals/ModalCopyOrder";
import GlobalContext from "../components/providers/GlobalContext";
import { ScopeProvider } from "../components/providers/ScopeContext";
import { ContextEnum } from "../commons/enums/RequestContextEnum";
import useTabListener from "../hooks/useTabListener";
import MasterFilterSkeleton from "../components/skeletons/MasterFilterSkeleton";
import TotalSectionSkeleton from "../components/skeletons/TotalSectionSkeleton";
import axios from "axios";
import useNotifier from "../hooks/useNotifier";
import NewQuotationButton from "../components/bottons/NewQuotationButton";
import { SessionUserInfoDTO } from "../commons/interfaces/Users";
import RefreshTabsContext from "../components/providers/RefreshTabsContext";

const overlayStyles = {
  width: '100%',
  height: '100%',
};


const Pedidos: React.FC = () => {
  const {globalContext, setGlobalContext} = useContext(GlobalContext);
  const {refreshTabs ,setRefreshTabs} = useContext(RefreshTabsContext);
  const { processAxiosError } = useNotifier();
  const [visible, setVisible] = useState(false);
  const [loadingRights, setLoadingRights] = useState(true);
  const iconStyle = { width: rem(12), height: rem(12) };
  const [ opened, {open, close} ] = useDisclosure(false); // used in Modal Copy Order
  const [refreshTabsBtnDisabled ,setRefreshTabsBtnDisabled] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string | null>('cotizaciones');

  const {
    cotizacionesTabRef,
    pedidosActivosTabRef,
    cotizacionesPerdidasTabRef,
    pedidosFacturadosTabRef,
    apartadosTabRef,
    todosTabRef,
    handleTabChange,
  } = useTabListener();

  useEffect(() => {
    if(activeTab){
      handleTabChange(activeTab);
    }
    if(!globalContext.userInfo){
      const inputString: string = globalContext.parentResourceName;
      // Expresión regular para encontrar "_rip" seguido de cualquier cosa hasta ".jsp"
      const pattern: RegExp = /_rip.*?(?=\.jsp)/;
      // Realizar limpieza del resource
      const module: string = inputString.replace(pattern, "");
      axios({
          url: `${document.referrer}${globalContext.parentContext}/user-session?action=user-info&module=${module}`,
          method: 'GET',
          withCredentials: true
      }).then((response) => {
          const result = response.data as SessionUserInfoDTO;
          setGlobalContext((prevState) => (
            {...prevState, userInfo: {
              username: result.email,
              email: result.email,
              userType: result.userType,
              rights: new Map(Object.entries(result.rights))
            }}));
          setLoadingRights(false);
      }).catch((error) => {
          processAxiosError(error);
      });
    }
  }, [activeTab]);

  useEffect(() => {
    const subscription = isRequestLoadingSubject.subscribe((value) => {
      console.log(`[RefreshTabs] ${value} requests waiting`);
      setRefreshTabsBtnDisabled(value == 0 ? false : true);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [refreshTabs]);

  useEffect(() => {
    const subscription = showLoadingOverlaySubject.subscribe((value) => {
      console.log(value ? '[Pedidos] Show Everlay' : '[Pedidos] Hide Everlay');
      setVisible(value);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  function goToNewQuotation() {
    const resourceName = globalContext.parentResourceName
    window.parent.location.href = `${document.referrer}${globalContext.parentContext}/pedidos.jsp?redirectFrom=${resourceName}`;
  }

  function handleRefreshTabs() {
    setRefreshTabs(true);
  }

  return (
    <>
      { loadingRights && globalContext.userInfo ? (
          <>
          <MasterFilterSkeleton/>
          <TotalSectionSkeleton/>
          </>
        ) : (
          <>
          <LoadingOverlay visible={visible} w={'100%'} h={'100%'} overlayProps={{ radius: "sm", blur: 2 }} style={overlayStyles} />
          <Tabs value={activeTab} onChange={setActiveTab}>
            <Tabs.List>
              <Tabs.Tab
                value="cotizaciones"
                leftSection={<FontAwesomeIcon icon={faFileInvoiceDollar} style={iconStyle}/>}
              >
                Cotizaciones
              </Tabs.Tab>
              <Tabs.Tab
                value="pedidosActivos"
                leftSection={<FontAwesomeIcon icon={faClipboardCheck} />}
              >
                Pedidos Activos
              </Tabs.Tab>
              <Tabs.Tab
                value="cotizacionesPerdidas"
                leftSection={<FontAwesomeIcon icon={faRectangleXmark} />}
              >
                Cotizaciones Perdidas
              </Tabs.Tab>
              <Tabs.Tab
                value="pedidosFacturados"
                leftSection={<FontAwesomeIcon icon={faFileInvoice} />}
              >
                Pedidos Facturados
              </Tabs.Tab>
              <Tabs.Tab
                value="apartados"
                leftSection={<FontAwesomeIcon icon={faPeopleCarryBox} />}
              >
                Apartados
              </Tabs.Tab>
              <Tabs.Tab
                value="todos"
                leftSection={<FontAwesomeIcon icon={faTableList} />}
              >
                Todos
              </Tabs.Tab>
              <Flex
                flex={1}
                gap="xs"
                justify="flex-end"
                direction="row"
                wrap="wrap"
                px="sm"
                py="sm"
              >
                <Button
                  children="Refrescar Tabs"
                  variant="outline"
                  justify="flex-end"
                  size="xs"
                  onClick={handleRefreshTabs}
                  disabled={refreshTabsBtnDisabled}
                />
                <Button
                  children="Copiar pedido"
                  variant="outline"
                  justify="flex-end"
                  size="xs"
                  onClick={open}
                />
                <NewQuotationButton/>
              </Flex>
            </Tabs.List>

            <Tabs.Panel value="cotizaciones">
              <ScopeProvider scopeUrl={"/sales/quotation"} scopeContext={ContextEnum.QUOTATION}>
                <Cotizaciones forwardedRef={cotizacionesTabRef}/>
              </ScopeProvider>
            </Tabs.Panel>

            <Tabs.Panel value="pedidosActivos">
              <ScopeProvider scopeUrl={"/sales/active-request"} scopeContext={ContextEnum.ACTIVE_REQUEST}>
                <PedidosActivos forwardedRef={pedidosActivosTabRef}/>
              </ScopeProvider>
            </Tabs.Panel>

            <Tabs.Panel value="cotizacionesPerdidas">
              <ScopeProvider scopeUrl={"/sales/lost-quotation"} scopeContext={ContextEnum.LOST_QUOTATION} lazyFetch={true}>
                <CotizacionesPerdidas forwardedRef={cotizacionesPerdidasTabRef}/>
              </ScopeProvider>
            </Tabs.Panel>

            <Tabs.Panel value="pedidosFacturados">
              <ScopeProvider scopeUrl={"/sales/invoiced-request"} scopeContext={ContextEnum.INVOICED_REQUEST} lazyFetch={true}>
                <PedidosFacturados forwardedRef={pedidosFacturadosTabRef}/>
              </ScopeProvider>
            </Tabs.Panel>

            <Tabs.Panel value="apartados">
              <ScopeProvider scopeUrl={"/sales/separated-request"} scopeContext={ContextEnum.SEPARATED}>
                <Apartados forwardedRef={apartadosTabRef}/>
              </ScopeProvider>
            </Tabs.Panel>

            <Tabs.Panel value="todos">
              <ScopeProvider scopeUrl={"/sales/all-requests"} scopeContext={ContextEnum.ALL_REQUESTS} lazyFetch={true}>
                <Todos forwardedRef={todosTabRef}/>
              </ScopeProvider>
            </Tabs.Panel>
          </Tabs>
          <ModalCopyOrder opened={opened} onClose={close} />
          </>
      )}
    </>
  );
};

export default Pedidos;
