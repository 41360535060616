import { ChangeEvent, useState } from "react";
import { DateTimePicker, DateValue } from "@mantine/dates";
import { Box, Button, Group, Modal, Textarea, Text, rem, Divider } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { addDay, getFormattedDateTimeString } from "../../../commons/utils/Formatters";
import AuthUsersSelect from "../../select/AuthUsersSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import useRequest from "../../../hooks/useRequest";
import { IPedido } from "../../../commons/interfaces/Pedidos";
import useSeparated from "../../../hooks/useSeparated";

interface ModalApartaPedidoProps {
    request: IPedido;
    opened: boolean;
    cancelDate: Date | null;
    onSeparateCallBack: () => void;
    onCancelSeparateCallBack: () => void;
    closeModal: () => void;
}

export const ModalSeparatedWithAuth: React.FC<ModalApartaPedidoProps> = ({
    request,
    opened,
    cancelDate,
    onSeparateCallBack,
    onCancelSeparateCallBack,
    closeModal
}) => {
    const title: string = 'Autorizar apartado';
    const { showSucessMessage, showSucessCancelMessage, showErrorMessage } = useSeparated({title, onSeparateCallBack, onCancelSeparateCallBack});
    const [cancelationDate, setCancelationDate] = useState<Date>(cancelDate || addDay(new Date(), 1));
    const [reason, setReason] = useState<string>('');
    const [email, setEmail] = useState<string>();
    const [user, setUser] = useState<string>();
    const { executeRequest } = useRequest();    

    const icon = <FontAwesomeIcon icon={faCalendar}  style={{ width: rem(18), height: rem(18) }} />;

    const separatedDate = getFormattedDateTimeString(new Date());

    const handleCancel = () => {
        const requestData = {
            pedido: request.idPedido,
            tkmov: request.tkmov,
            empresa: request.idEmpresa,
        };
        executeRequest({url: `/sales/separated?action=cancel-separate`,
            method: "POST",
            body: requestData,
            callback: handleResponseCancel,
            errorCallback: handleError
        });
    }

    const handleError = () => {
        showErrorMessage();
        closeModal();
    }

    const handleResponseCancel = () => {
        showSucessCancelMessage();
    }

    const autorizarApartado = () => {
        if (!email || !reason) {
            notifications.show({
                title: title,
                message: 'Favor de llenar todos los campos',
                color: "red",
            });
        }else{
            const requestData = {
                pedido: request.idPedido,
                tkmov: request.tkmov,
                empresa: request.idEmpresa,
                email: email,
                user: user,
                reason: reason,
                separatedDate: separatedDate,
                cancelationDate: getFormattedDateTimeString(cancelationDate),
            };
            executeRequest({
                url: "/sales/separated?action=auth-separated",
                method: "POST",
                body: requestData,
                callback: showSucessMessage,
                errorCallback: handleError
            });
        }
    }

    const handleEmailInputChange = (value: string | string[] | number | number[]) => {
        const userAuth = (value as string).split('|');
        setEmail(userAuth[1]);
        setUser(userAuth[0]);
    }

    const handleMotivoInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setReason(event.target.value);
    }

    const handleFechaDeCancelacionInputChange = (date: DateValue) => {
        setCancelationDate(date || new Date());
    }

    return (
        <Modal
            opened={opened}
            onClose={closeModal}
            title={title}
            centered
            size="md"
        >
            <Text c="dimmed" pb={5}>Este pedido ya fue apartado anteriormente, Necesita solicitar autorización</Text>
            <Divider my="sm" variant="dotted" />
            <Box>
                <Text>Fecha: {separatedDate}</Text>
                <DateTimePicker
                    valueFormat="DD-MM-YYYY HH:mm"
                    leftSection={icon}
                    clearable
                    value={cancelationDate}
                    label="Fecha de cancelación"
                    onChange={handleFechaDeCancelacionInputChange}
                />

                <AuthUsersSelect
                    id="correo"
                    authType={2}
                    label="Correo"
                    onChange={value => handleEmailInputChange(value)}
                />
                <Textarea
                    label="Motivo"
                    autosize
                    minRows={6}
                    onChange={handleMotivoInputChange}
                />
                <Group justify="center" mt="md">
                    <Button onClick={handleCancel} variant="outline">Cancelar apartado</Button>
                    <Button onClick={autorizarApartado}>Apartar</Button>
                </Group>
            </Box>
        </Modal>
    );
};