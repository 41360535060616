import { Button, Divider, Flex, Grid, List, Modal, Select, Table, Text, Textarea } from "@mantine/core";
import useAxios from "../../hooks/useAxios";
import { useDisclosure } from "@mantine/hooks";
import { AutoCxCValidationsRSDTO } from "../../commons/interfaces/Movements";
import { useEffect, useState } from "react";
import { IPedido } from "../../commons/interfaces/Pedidos";
import Currency from "../currency/Currency";
import useRequest from "../../hooks/useRequest";
import AuthUsersSelect from "../select/AuthUsersSelect";

interface ModalAutorizacionCxCProps {
    openModal: boolean;
    continueCallback: () => void;
    onCloseCallback: () => void;
    pedido: IPedido;
}

interface SelectedMail {
    user: string | null;
    email: string | null;
}

export const ModalAutorizacionCxC: React.FC<ModalAutorizacionCxCProps> = ({
    openModal,
    continueCallback,
    onCloseCallback,
    pedido
}) => {
    const [selectedEmail, setSelectedEmail] = useState<SelectedMail | null>(null);
    const [motivo, setMotivo] = useState('');
    const [motivoError, setMotivoError] = useState<string | null>(null);
    const [autoCxCResult, setAutoCxCResult] = useState<AutoCxCValidationsRSDTO>();
    const { data: dataValCredit, executeRequest: requestValCredit, loading: loadingValCredit, responseError: errorValCredit } = useAxios({});
    const { data: dataSendAutoCxC, executeRequest: requestSendAutoCxC, loading: loadingSendAutoCxC, responseError: errorSendAutoCxC } = useAxios({});
    const { executeRequest } = useRequest();
    const [opened, { open, close }] = useDisclosure(openModal);

    useEffect(() => {
        requestValCredit('/sales/autocxc-process?action=validate-credit', "post",
            {
                pedido: pedido.idPedido,
                tkmov: pedido.tkmov,
                empresa: pedido.idEmpresa,
                idCliente: pedido.idCliente,
                shiftRoute: pedido.shiftRoute,
                fechaEntrega: pedido.fentrega
            });
    }, []);

    useEffect(() => {
        if (dataValCredit) {
            if (dataValCredit.needConfirmValidation) {
                setAutoCxCResult(dataValCredit);
            } else {
                //Entra aqui si el cliente no dispara ninguna validacion que requiera autorizacion de cxc
                executeRequest({url: '/sales/autocxc-process?action=init-direct-auth', method: "post",
                    body:{
                        pedido: pedido.idPedido,
                        tkmov: pedido.tkmov,
                        empresa: pedido.idEmpresa,
                        idCliente: pedido.idCliente,
                        shiftRoute: pedido.shiftRoute,
                        fechaEntrega: pedido.fentrega
                    },
                    callback: () => {
                        continueCallback();
                        close();
                    },
                    
                });
            }

        }
        if (errorValCredit) {
            onClose();
        }
    }, [dataValCredit]);

    useEffect(() => {
        if (dataSendAutoCxC) {
            continueCallback();
            close();
        }
        if (errorSendAutoCxC) {
            onClose();
        }
    }, [dataSendAutoCxC]);

    const onClose = () => {
        onCloseCallback();
        close();
    }

    const onSubmit = () => {
        if (motivo === '' || motivo.length < 5) {
            setMotivoError('Ingrese un motivo');
            return;
        }
        requestSendAutoCxC('/sales/autocxc-process?action=init-auto-cxc', "post",
            {
                pedido: pedido.idPedido,
                tkmov: pedido.tkmov,
                empresa: pedido.idEmpresa,
                idCliente: pedido.idCliente,
                shiftRoute: pedido.shiftRoute,
                fechaEntrega: pedido.fentrega,
                authEmail: selectedEmail?.email || autoCxCResult?.usersToNotify[0].email,
                authUser: selectedEmail?.user || autoCxCResult?.usersToNotify[0].userId,
                razon: autoCxCResult?.messages,
                motivo: motivo || 'No se especifica el motivo',
                nombreCliente: pedido.nombreCliente,
                nombreVendedor: pedido.nombreVendedor,
            });
    }

    return (
        <>
            <Modal opened={opened} onClose={onClose} size="auto" title="Confirmación De Autorización">
                <Divider my="sm" />
                <div style={{ display: autoCxCResult ? 'block' : 'none' }}>
                <Grid gutter="lg" justify="flex-start">
                    <Grid.Col span={12}>
                        <Text fw={700} c="red">Se necesita validación por las siguientes razones:</Text>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <List>
                            {autoCxCResult?.messages.map((message, index) => {
                                return (<List.Item key={index}>{message}</List.Item>)
                            })
                            }

                        </List>
                    </Grid.Col>

                    <Grid.Col span={12}>
                        <Table>
                            <Table.Thead>
                                <Table.Tr>
                                    <Table.Th>Pedido</Table.Th>
                                    <Table.Th>Cliente</Table.Th>
                                    <Table.Th>Ejecutivo</Table.Th>
                                    <Table.Th>Monto Total</Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                                <Table.Tr key={pedido.idPedido}>
                                    <Table.Td>{pedido.idPedido}</Table.Td>
                                    <Table.Td>{pedido.nombreCliente}</Table.Td>
                                    <Table.Td>{pedido.nombreVendedor}</Table.Td>
                                    <Table.Td><Currency amount={pedido.total} /></Table.Td>
                                </Table.Tr>
                            </Table.Tbody>
                        </Table>
                    </Grid.Col>
                    <Grid.Col span={5}>
                        <AuthUsersSelect
                            id="correo"
                            authType={2}
                            label="Correo"
                            isDataPreloaded
                            {...(selectedEmail && { selectedValue: selectedEmail.email || '' })}
                            preloadedData={autoCxCResult?.usersToNotify}
                            onChange={value => {
                                const returnValue = value as string;
                                const [userId, email] = returnValue.split('|');
                                setSelectedEmail({ user: userId, email: email });
                            }}
                        />
                    </Grid.Col>
                </Grid>
                <Divider my="sm" />
                <Textarea
                    label="Motivo"
                    value={motivo}
                    onChange={(event) => {
                        setMotivoError(null);
                        setMotivo(event.currentTarget.value);
                    }}
                    error={motivoError ? motivoError : null}
                />
                <Divider my="sm" />
                <Flex
                    mih={50}
                    gap="md"
                    justify="center"
                    align="flex-end"
                    direction="row"
                    wrap="wrap"
                >
                    <Button variant="primary" onClick={() => onSubmit()}>Enviar</Button>
                    <Button variant="danger" onClick={() => onClose()}>Cancelar</Button>
                </Flex>
                </div>
            </Modal>
        </>
    );
}