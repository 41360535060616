import { colors } from "./colors";

const baseAnchor = {

};

export const anchorStyles={
    blueBackGround: {
        root: {
            backgroundColor: "#CFE2FF",
        },
    },
    yellowBackGround: {
        root: {
            backgroundColor: "#FFE8A1",
        },
    },
    redBackGround: {
        root: {
            backgroundColor: colors.lightRed,
        },
    },
    greenBackGround: {
        root: {
            backgroundColor: colors.lightGreen,
        },
    },
}