import { colors } from "./colors";

export const fontStyles = {
  table: {
    color: colors.black,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
};
