import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

/**
 *  Formatea un numero a moneda
 * @param amount  Cantidad a formatear
 * @param currency  Moneda a utilizar
 * @returns  Cantidad formateada a moneda
 */
export function getFormattedCcy(amount: number | string, currency: string = "MXN") {
    return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency,
    }).format(Number(amount));
}

/**
 * Add days to the given Date
 * @param date Date to minipulate
 * @returns Date plus days
 */
export function addDay(date: Date, daysToAdd: number): Date {
    return dayjs(date).add(daysToAdd, 'day').toDate();
}

/**
 * formatea una fecha tipo string a Date
 * @param date  Fecha a formatear
 * @returns  Fecha formateada
 */
export function getFormattedDate(date: string): Date {
    return dayjs(date, 'DD-MM-YYYY').toDate();
}

/**
 * Formatea una fecha a string
 * @param date  Fecha a formatear
 * @returns Fecha formateada
 */
export function getFormattedDateString(date: Date): string {
    return dayjs(date).format('DD-MM-YYYY');
}

/**
 * Formatea una fecha + tiempo a string
 * @param date  Fecha a formatear
 * @returns Fecha formateada
 */
export function getFormattedDateTimeString(date: Date): string {
    return dayjs(date).format('DD-MM-YYYY HH:mm');
}

/**
 *  Compar si la fecha1 es anterior a la fecha2
 * @param date1 fecha a comparar
 * @param date2 fecha a partir de la que se compara
 * @returns  true si la fecha1 es anterior a la fecha2
 */
export function isBefore(date1: Date, date2: Date, withTime: boolean = false): boolean {
    if (!withTime) {
        const date1WithoutTime = dayjs(date1).startOf('day');
        const date2WithoutTime = dayjs(date2).startOf('day');
        return date1WithoutTime.isBefore(date2WithoutTime);
    } else {
        return dayjs(date1).isBefore(dayjs(date2));
    }
}

/**
 *  Compar si la fecha1 es igual a la fecha2
 * @param date1 fecha a comparar
 * @param date2 fecha a partir de la que se compara
 * @returns  true si la fecha1 es igual a la fecha2
 */
export function isSame(date1: Date, date2: Date, withTime: boolean = false): boolean {
    if (!withTime) {
        const date1WithoutTime = dayjs(date1).startOf('day');
        const date2WithoutTime = dayjs(date2).startOf('day');
        return date1WithoutTime.isSame(date2WithoutTime);
    } else {
        return dayjs(date1).isSame(dayjs(date2));
    }
}

/**
 * Obtiene la hora actual
 * @returns Hora actual
 */
export function getNowHour() {
    return Number(dayjs().format('HH'));
}

/**
 * Minutos actuales
 * @returns Minutos actuales
 */
export function getNowMinutes() {
    return Number(dayjs().format('mm'));
}
