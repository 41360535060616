import { actionIconStyles, anchorStyles, iconStyles } from "../../styles";
import { IPedido } from "../interfaces/Pedidos";


/**
 * Estilos para el boton P de la tabla de pedidos
 * @param request  Pedido
 * @returns estilo correspondiente
 */
export const getButtonPStyles = (request: IPedido) => {
    if(request.status === 'V' && request.autoCxc === 1 && request.opcStatusValidacion === 0 ){
        return actionIconStyles.activoVerde;
    }else if(request.status === 'V' && (request.autoCxc === 0 || request.autoCxc === 1) && request.opcStatusValidacion === 1){
        return actionIconStyles.enEspera;
    }else if(request.status === 'C' && (request.autoCxc === 0 || request.autoCxc === 1) && (request.opcStatusValidacion === 1 || request.opcStatusValidacion === 0)){
        return actionIconStyles.canceladoRojo;
    }else{
        return actionIconStyles.normal;
    }
}

/**
 * Estilos para el boton S de la tabla de pedidos
 * @param request  Pedido
 * @returns estilo correspondiente
 */
export const getButtonSStyles = (request: IPedido) => {
    if(request.status === 'V' && request.autoVta === 1 ){
        return actionIconStyles.activoVerde;
    }else if(request.status === 'C' && (request.autoCeo === 0 || request.autoCeo === 1)){
        return actionIconStyles.canceladoRojo;
    }else{
        return actionIconStyles.normal;
    }
}

/**
 * Estilos para el boton T de la tabla de pedidos
 * @param request Pedido
 * @returns estilo correspondiente
 */
export const getButtonTStyles = (request: IPedido) => {
    if(request.status === 'V' && request.autoCeo === 1 ){
        return actionIconStyles.activoVerde;
    }else if(request.status === 'C' && request.autoCeo === 0 ){
        return actionIconStyles.canceladoRojo;
    }else{
        return actionIconStyles.normal;
    }
}

/**
 * Estilos para el boton E de la tabla de pedidos
 * @param request  Pedido
 * @returns estilo correspondiente
 */
export const getButtonEStyles = (request: IPedido) => {
    if(request.status === 'V' && request.autoEnt === 1 ){
        return actionIconStyles.activoVerde;
    }else if(request.status === 'C' && (request.autoCeo === 0 || request.autoCeo === 1)){
        return actionIconStyles.canceladoRojo;
    }else{
        return actionIconStyles.normal;
    }
}

/**
 * Estilos para el boton de apartado de mercancia de la tabla de pedidos
 * @param request  Pedido
 * @returns estilo correspondiente
 */
export const getButtonReserveStyles = (request: IPedido) => {
    if(request.blnapartado === 1 && request.apartadoOpc === 0 &&  request.autoVta === 1){
        return actionIconStyles.activoVerde;
    }else if(request.blnapartado === 1 && request.apartadoOpc === 1 &&  request.autoVta === 0){
        return actionIconStyles.enEspera;
    }else if(request.blnapartado === 0 && request.apartadoOpc === 1 &&  request.autoVta === 0){
        return actionIconStyles.selectedAzul;
    }else{
        return actionIconStyles.normal;
    }
}

/**
 * Estilos para el boton de cliente recolecta de la tabla de pedidos
 * @param request  Pedido
 * @returns estilo correspondiente
 */
export const getButtonClientRecoStyles = (request: IPedido) => {
    if(request.clienteRecolecta === 1 || request.clienteRecolecta === 2){
        return actionIconStyles.activoVerde;
    }else{
        return actionIconStyles.normal;
    }
}

/**
 * Estilos para el boton de generar factura de la tabla de pedidos
 * @param request  Pedido
 * @returns estilo correspondiente
 */
export const getButtonInvoiceStyles = (request: IPedido) => {
    if((request.status === 'V' || request.status === 'C' ) && request.opcTk === 'T'){
        return actionIconStyles.canceladoRojo;
    }else{
        return actionIconStyles.normal;
    }
}

/**
 * Estilos para el boton nro de pedido de la tabla de pedidos
 * @param request  Pedido
 * @returns estilo correspondiente
 */
export const getButtonRequestIdStyles = (request: IPedido) => {
    if(request.candSurtido === 1 && request.autoCxc === 0 && request.prepedido === 0){
        return anchorStyles.redBackGround;
    }
    if(request.envFactura === 'S' && request.autoCxc !== 0 && request.pedFactura !== 0 && request.status !== 'C'){
        return anchorStyles.greenBackGround;
    }
    if(request.prepedido === 1){
        return anchorStyles.yellowBackGround;
    }
    if(request.stProduc === 1){
        return anchorStyles.blueBackGround;
    }
}