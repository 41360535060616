import React, { CSSProperties, useCallback, useContext, useEffect, useState } from 'react';
import { IPedido } from '../../../commons/interfaces/Pedidos';
import { actionIconStyles } from '../../../styles';
import { ActionIcon, Table, Tooltip } from '@mantine/core';
import { AnticipoResponseDTO } from '../../../commons/interfaces/Movements';
import { ModalAnticipo } from '../../modals/ModalAnticipo';
import { ModalSelectRuta } from '../../modals/ModalSelectRuta';
import { ModalConfirmResupply } from '../../modals/resupply/ModalConfirmResupply';
import { ModalAutorizacionCxC } from '../../modals/ModalAutorizacionCxC';
import { notifications } from '@mantine/notifications';
import ScopeContext from '../../providers/ScopeContext';
import useRequest from '../../../hooks/useRequest';
import { refreshTable } from '../../../commons/utils/ActionsSubject';
import { ContextEnum } from '../../../commons/enums/RequestContextEnum';

interface ActionButtonPProps {
    pedido: IPedido;
}

const ActionButtonP: React.FC<ActionButtonPProps> = (props) => {
    const { scope } = useContext(ScopeContext);
    const [showModalsFlow, setShowModalsFlow] = useState<boolean>(false);
    const [montoAnticipo, setMontoAnticipo] = useState<number>(0.0);
    const [pedido, setPedido] = useState<IPedido>(props.pedido);
    const [styles, setStyles] = useState<CSSProperties>({});
    const [disabled, setDisabled] = useState<boolean>(false);
    const [openModalAnticipo, setOpenModalAnticipo] = useState<boolean>(false);
    const [openModalSelectRuta, setOpenModalSelectRuta] = useState<boolean>(false);
    const [openModalConfirmRequisicion, setOpenModalConfirmRequisicion] = useState<boolean>(false);
    const [openModalAutorizacionCxC, setOpenModalAutorizacionCxC] = useState<boolean>(false);

    const {executeRequest, executeRequestAndCallbackData: executeRequestAndResponseCallback} = useRequest();

    useEffect(() => {
        if (pedido.status === 'C') {
            setStyles(actionIconStyles.canceladoRojo);
            setDisabled(true);
        }
        if (pedido.autoCxc === 1) {
            switch (pedido.opcStatusValidacion) {
                case 0:
                    setStyles(actionIconStyles.activoVerde);
                    setDisabled(false);
                    break;
                case 1:
                    setStyles(actionIconStyles.enEspera);
                    setDisabled(false);
                    break;
                default:
                    // handle other cases if necessary
                    break;
            }
        } else if (pedido.autoCxc === 0 && pedido.opcStatusValidacion === 1) {
            setStyles(actionIconStyles.enEspera);
            setDisabled(false);
        } else {
            setStyles(actionIconStyles.normal);
            setDisabled(false);
        }
    }, [pedido, montoAnticipo]);

    const actionPostSimpleVal = () => {
        console.log('[ActionButtonP] ActionButtonP, Termino de mandar request de validaciones simples.');
        console.log('[ActionButtonP] Paso validaciones simples');
        executeRequestAndResponseCallback({
            url: `/sales/anticipos?action=anticipo-disponible`,
            method: "POST",
            body: { pedido: pedido.idPedido, tkmov: pedido.tkmov, empresa: pedido.idEmpresa, idCliente: pedido.idCliente },
            callback: actionPostAnticipoDisp
        });
    }

    const actionPostAnticipoDisp = (dataAnticipoDisp: any) => {
        console.log('[ActionButtonP] ActionButtonP, Termino de mandar request de anticipos.');
        validateAndApplyAnticipo((dataAnticipoDisp as AnticipoResponseDTO));
    }

    // const actionPostResuply = () => {
    //     console.log('[ActionButtonP] ActionButtonP, Termino de mandar request de validacion de requisicion');
    //     setOpenModalConfirmRequisicion(true);
    //     // if (resuply.needSupply) {
    //     //     setOpenModalConfirmRequisicion(true);
    //     // } else {
    //     //     setOpenModalAutorizacionCxC(true);
    //     // }
    // }

    const initAutoCxC = async () => {
        console.log('[ActionButtonP] Iniciar proceso de AutoCxC');
        executeRequest({
            url: `/sales/validations?action=simple-validations`,
            method: "POST",
            body:{ pedido: pedido.idPedido, tkmov: pedido.tkmov, empresa: pedido.idEmpresa, idCliente: pedido.idCliente },
            callback: actionPostSimpleVal
        });
    }

    /**
     * Esta funcion valida si el anticipo es mayor a 0 y muestra el modal de anticipo para que el usuario lo aplique
     * Luego de aplicarlo el modal se cierra y se ejecuta el proceso de AutoCxC
     * @param anticipoData Datos con el anticipo disponible
     */
    const validateAndApplyAnticipo = (anticipoData: AnticipoResponseDTO) => {
        console.log('[ActionButtonP] Iniciar proceso de validar y aplicar anticipo en caso de tenerlo disponible');
        if (anticipoData.montoAnticipo > 0) {
            console.log('[ActionButtonP] Hay anticipos para aplicar, mostrando modal...');
            setOpenModalAnticipo(true);
            setMontoAnticipo(anticipoData.montoAnticipo);
        } else {
            console.log('[ActionButtonP] No hay anticipos que aplicar, continuando con el proceso de AutoCxC (Confirmar requisicion si es necesaria)');
            executeAutoCxCPostAnticipo();
        }
    }

    /**
     * Callback invocado al completar el modal de anticipo
     * Si es pedido de produccion se va directo a confirmacion 
     * de requisicion, de lo contrario va al modal de seleccion 
     * de ruta y fecha de entrega
     */
    const executeAutoCxCPostAnticipo = () => {
        console.log('[ActionButtonP] Continua el proceso de seleccion de ruta.');
        if (openModalAnticipo) {

            setOpenModalAnticipo(false);
        }
        // Si es pedido de produccion se va directo a la confirmacion de requisicion
        if (pedido.stProduc !== 0) {
            console.log('[ActionButtonP] Pedido de produccion, ignorar seleccion de ruta e ir a confirmacion de requisicion');
            setOpenModalConfirmRequisicion(true);
        } else { // Sino hay que pedir seleccion de ruta
            console.log('[ActionButtonP] Se muestra modal de Seleccion de Ruta');
            setOpenModalSelectRuta(true);
        }
    }

    /**
     * Callback invocado al completar el modal de seleccion de ruta.
     * Este metodo invoca entonces el proceso que se encarga de validar si hay que
     * solicitar requisiciones para alguno de los articulos del pedido
     */
    const executeAutoCxCPostSelectRuta = (selectedDate: string, selectedRoute: string) => {
        console.log('[ActionButtonP] Continua el proceso de aprobar requisicion de haberla. ')
        console.log('[ActionButtonP] (Adicionalmente seteamos la informacion de la ruta y fecha de entrega en el pedido)');
        // executeRequestAndResponseCallback({
        //     url: '/sales/warehouse-supply?action=resupply-needed',
        //     method: "POST",
        //     body: { pedido: pedido.idPedido, tkmov: pedido.tkmov, empresa: pedido.idEmpresa },
        //     callback: actionPostResuply
        // });
        setPedido(prevPedido => ({...prevPedido, fentrega: selectedDate, shiftRoute: selectedRoute}));
        setOpenModalSelectRuta(false);
        setOpenModalConfirmRequisicion(true);
    }

    /**
     * Metodo que maneja el callback del modal de confirmacion de requisicion
     * Si aqui llega unn true, se ejecuta el proceso de pedir requisicion para 
     * los articulos del pedido y se continua con el proceso de autorizacion del 
     * pedido, de lo contrario se cierra el modal y se detiene el proceso de auto CxC
     * @param canContinue 
     */
    const handleContinueConfirmResupply = (canContinue: boolean) => {
        console.log(canContinue ? '[ActionButtonP] Se continua con el proceso de requisicion y autorizacion' : '[ActionButtonP] Se detiene el proceso de requisicion y autorizacion del pedido');
        setOpenModalConfirmRequisicion(false);
        if (canContinue) {
            executeRequest({
                url:'/sales/warehouse-supply?action=execute-resupply',
                method: "POST",
                body: { pedido: pedido.idPedido, tkmov: pedido.tkmov, empresa: pedido.idEmpresa },
            });
            setOpenModalAutorizacionCxC(true);
        } else {
            notifications.show({
                title: 'Alerta',
                message: 'Proceso de Autorizacion detenido por el usaurio (No se solicitaron requisiciones cuando se necesitaban)',
                color: 'yellow',
            });
        }
    }

    const handleSkipResupply = () => {
        console.log('[ActionButtonP] Se continua con el proceso de autorizacion sin necesidad de requisicion.' );
        setOpenModalConfirmRequisicion(false);
        setOpenModalAutorizacionCxC(true);
    }

    /**
     * Metodo que maneja el callback del modal de autorizacion de CxC
     */
    const handleEndAutorizacionCxC = () => {
        console.log('[ActionButtonP] Mostrando notificacion...');
        notifications.show({
            title: 'Info',
            message: 'Proceso de solicitud de Autorizacion culminado satisfactoriamente',
            color: 'green',
        });
        setShowModalsFlow(false);
        // setPedido((prevRequest) => ({
        //     ...prevRequest,
        //     autoCxc: prevRequest.autoCxc === 0 ? 1 : 0,
        //     opcStatusValidacion: prevRequest.opcStatusValidacion === 0 ? 1 : 0
        // }));
        refreshTable.next({scopeContext: ContextEnum.ACTIVE_REQUEST});
        refreshTable.next({scopeContext: scope.scopeContext});
    }

    const autoCxCRollBackCallBack = useCallback(() => {
        if(pedido.autoCxc === 1 && pedido.opcStatusValidacion === 0){
            refreshTable.next({scopeContext: scope.scopeContext});
        }else{
            setPedido((prevRequest) => ({
                ...prevRequest,
                autoCxc: 0,
                opcStatusValidacion:  0
            }));
        }
    }, [pedido, scope.scopeContext]);

    /**
     * Maneja el evento de clickear en el boton de autorizacion de un pedido.
     * Esto desencadena el proceso de AutoCxC, si el pedido ya tiene una autorizacion, sino
     * dispara el proceso de desmarcar el pedido como no autorizado por CxC
     */
    const handleOnClick = () => {
        if ((pedido.autoCxc === 0 && pedido.opcStatusValidacion === 1) || (pedido.autoCxc === 1 && pedido.opcStatusValidacion === 0)) {
            console.log('[ActionButtonP] Rollback peticion AutoCxC');
            executeRequest({
                url:`/sales/autocxc-process?action=revert-auto-cxc`,
                method: "POST",
                body: { pedido: pedido.idPedido, tkmov: pedido.tkmov, empresa: pedido.idEmpresa },
                callback: autoCxCRollBackCallBack
            });
            
            
        } else {
            setShowModalsFlow(true);
            initAutoCxC();
        }
    }


    return (
        <>
            <Table.Td pr={3} pl={3}>
                <div style={{ display: showModalsFlow ? 'none' : 'block' }}>
                    {openModalAnticipo &&
                        <ModalAnticipo
                        openModal={openModalAnticipo}
                        continueCallback={executeAutoCxCPostAnticipo}
                        onCloseCallback={() => setOpenModalAnticipo(false)}
                        pedido={pedido}
                        totalAnticipo={montoAnticipo} />}
                    {openModalSelectRuta &&
                        <ModalSelectRuta
                        openModal={openModalSelectRuta}
                        continueCallback={executeAutoCxCPostSelectRuta}
                        onCloseCallback={() => setOpenModalSelectRuta(false)}
                        pedido={pedido} />}
                    {openModalConfirmRequisicion &&
                        <ModalConfirmResupply
                        openModal={openModalConfirmRequisicion}
                        continueCallback={handleContinueConfirmResupply}
                        onCloseCallback={() => setOpenModalConfirmRequisicion(false)}
                        onSkipResupplyCallback={handleSkipResupply}
                        pedido={pedido} />}
                    {openModalAutorizacionCxC &&
                        <ModalAutorizacionCxC
                        openModal={openModalAutorizacionCxC}
                        continueCallback={handleEndAutorizacionCxC}
                        onCloseCallback={() => setOpenModalAutorizacionCxC(false)}
                        pedido={pedido} />}
                </div>
                <Tooltip label="Autorizacion de Pedido">
                    <ActionIcon variant="light" size="sm" aria-label="Settings" style={styles} disabled={disabled} onClick={handleOnClick}>
                        P
                    </ActionIcon>
                </Tooltip>
            </Table.Td>
        </>
    );
};

export default ActionButtonP;