import React, { useCallback, useEffect, useState } from 'react';
import { Container, Box, Image, Text, Title, Anchor, rem } from '@mantine/core';
import { useSearchParams } from 'react-router-dom';
import useRequest from '../../hooks/useRequest';

interface BackEndResult{
    code: BigInteger;
    message: string;
}

const RequestDetails: React.FC = () => {
    const [searchParams] = useSearchParams();
    const baseUrl = searchParams.get('baseUrl');
    const backUrl = searchParams.get('backUrl');
    const token = searchParams.get('token');
    const action = searchParams.get('action');

    const [backResponse, setBackResponse] = useState<BackEndResult>();

    const {executeRowRequestAndUpdateState} = useRequest();

    useEffect(() => {
        sendBackRequest();
    }, []);

    const sendBackRequest = () => {
        executeRowRequestAndUpdateState(
            {
                url: `${backUrl}?token=${token}`,
                method: 'GET',
                body: null,
                stateToUpdate: setBackResponse
            }
        );
    }

    const PromotionalEmail: React.FC<{ message: string }> = ({ message }) => {
        return (
            <Box style={{ margin: 0, padding: 0, backgroundColor: 'white' }}>
                <Container style={{ padding: `${rem(20)} 0 ${rem(30)} 0` }}>
                    <Box
                        style={{
                            maxWidth: rem(550),
                            margin: '0 auto',
                            border: '1px solid #cccccc',
                            borderCollapse: 'collapse',
                        }}
                    >
                        <Box style={{ padding: `${rem(40)} 0 ${rem(30)} 0`, backgroundColor: '#e6e6e6', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Image src={`${baseUrl}/images/logo_fp.png`} alt="For Promotional" w="70%" style={{ display: 'block' }}/>
                        </Box>
                        <Box style={{ padding: `${rem(20)} ${rem(30)} ${rem(40)} ${rem(30)}`, backgroundColor: 'white' }}>
                            <Title order={2}>Respuesta</Title>
                            <Text dangerouslySetInnerHTML={{ __html: message }} />
                        </Box>
                        <Box style={{ padding: rem(30), backgroundColor: '#013971', color: 'white', textAlign: 'center' }}>
                            <Text>&reg; For Promotional, S.A. DE C.V.</Text>
                            <Text>
                                Entra{' '}<Anchor href="https://4promotional.net" style={{ color: 'white', textDecoration: 'none' }}>Aquí</Anchor>. Todos los derechos reservados
                            </Text>
                        </Box>
                    </Box>
                </Container>
            </Box>
        );
    };    

    const renderResult = useCallback(() => {
        if (token && action) {
            switch (action) {
                case 'approve':
                    return <PromotionalEmail message={backResponse?.message || ''} />;
                case 'reject':
                    return <PromotionalEmail message={backResponse?.message || ''} />;
                default:
                    return <PromotionalEmail message={backResponse?.message || 'Error al realizar la operación'} />;
            }
        } else {
            return <div>No token or action found</div>;
        }
    
    },[backResponse]);

    return (
        <div>
            {renderResult()}
        </div>
    );
};

export default RequestDetails;