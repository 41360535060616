import { useDisclosure } from "@mantine/hooks";
import {
    Drawer,
    Button,
    Grid,
    Divider,
    Table,
    Textarea,
    Text,
    Tooltip,
    ActionIcon,
} from "@mantine/core";
import { IPedido, IPedidoMovementsDTO } from "../../../commons/interfaces/Pedidos";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAxios from '../../../hooks/useAxios';
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";

interface RequestMovementsProps {
    pedido: IPedido;
}

export const RequestMovements: React.FC<RequestMovementsProps> = ({
    pedido,
}) => {

    const { data, executeRequest, loading } = useAxios({});

    const [opened, { open, close }] = useDisclosure(false, {
        onOpen: () => executeRequest(`/sales/request-movement?pedido=${pedido.idPedido}&tkmov=${pedido.tkmov}&empresa=${pedido.idEmpresa}`, 'get'),
    });


    const rows = (data as IPedidoMovementsDTO)?.movements.map((element, index) => (
        <Table.Tr key={index}>
            <Table.Td>{element.usuario}</Table.Td>
            <Table.Td>{element.tipo}</Table.Td>
            <Table.Td>{element.fecha}</Table.Td>
            <Table.Td>{element.hora}</Table.Td>
            <Table.Td>{element.concepto}</Table.Td>
        </Table.Tr>
    ));

    return (
        <Table.Td pr={3} pl={3}>
            
            <Drawer
                offset={0}
                opened={opened}
                onClose={close}
                position="right"
                size="xl"
                title="Movimientos y eventos del pedido"
                >
                <Grid gutter="lg">
                    <Grid.Col span={2}>
                        <Text fw={700}>No Pedido</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>{pedido.idPedido}</Grid.Col>

                    <Grid.Col span={2}>
                        <Text fw={700}>No Cajas</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>{pedido.cajas}</Grid.Col>

                    <Grid.Col span={2}>
                        <Text fw={700}>Cliente</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>{pedido.nombreCliente}</Grid.Col>

                    <Grid.Col span={2}>
                        <Text fw={700}>Ejecutivo</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>{pedido.nombreVendedor}</Grid.Col>

                    <Grid.Col span={2}><Text fw={700}>Ruta</Text></Grid.Col>
                    <Grid.Col span={4}>{pedido.ruta}</Grid.Col>
                    <Grid.Col span={2}><Text fw={700}>Fecha de Entrega</Text></Grid.Col>
                    <Grid.Col span={4}>{pedido.fentrega}</Grid.Col>
                </Grid>
                <Divider my="xs" label="Movimientos" labelPosition="center" />
                <Table>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>Usuario</Table.Th>
                            <Table.Th>Tipo</Table.Th>
                            <Table.Th>Fecha</Table.Th>
                            <Table.Th>Hora</Table.Th>
                            <Table.Th>Concepto</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    {data &&
                        <Table.Tbody>{rows}</Table.Tbody>
                    }
                </Table>
            </Drawer>
            <Tooltip label="Ver lista de eventos y movimientos">
                <ActionIcon variant="light" size="sm" aria-label="Settings" onClick={open}>
                    <FontAwesomeIcon icon={faCalendarCheck} />
                </ActionIcon>
            </Tooltip>
        </Table.Td>
    );
}