import { IPedido } from "../interfaces/Pedidos";

export const getRequestType = (request: IPedido) => {
    if(request.autoCxc === 0 && request.prepedido === 0 && request.candSurtido === 1){
        return "C. Perdida";
    }
    if(request.autoCxc === 0 && request.prepedido === 0){
        return "Cotización";
    }
    if(request.envFactura === 'S' && request.autoCxc !== 0 && request.pedFactura !== 0 && request.status !== 'C'){
        return "P. Facturado";
    }
    if(request.status === 'V' && request.statusResponse === 1){
        return "P. Activo";
    }
    return '--';
}