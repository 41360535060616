import { CloseButton, Combobox, Input, InputBase, Loader, useCombobox } from '@mantine/core';
import React, { useContext, useState } from 'react';
import { DeliveryWayInfoDTO, ExtendedInputProps } from '../../commons/interfaces/SelectInterfaces';
import axios from 'axios';
import GlobalContext from '../providers/GlobalContext';
import { useUncontrolled } from '@mantine/hooks';


const DeliveryWaySelect: React.FC<ExtendedInputProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const {globalContext} = useContext(GlobalContext);
    const [data, setData] = useState<DeliveryWayInfoDTO[]>([]);

    const [_value, handleChange] = useUncontrolled({
        value: props.value,
        defaultValue: props.defaultValue,
        finalValue: 'Final',
        onChange: props.onChange,
    });

    function getAsyncData() {
        return new Promise<DeliveryWayInfoDTO[]>((resolve) => {
            axios(
                {
                    url: `${document.referrer}${globalContext.parentContext}/delivery-way`,
                    method: 'get',
                    withCredentials: true,
                }).then((response) => {
                    const data = response.data.map((item: any) => ({id: item.id, description: item.description}));
                    resolve(data);
                });
            });
        }

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownOpen: () => {
        if (data.length === 0 && !loading) {
            setLoading(true);
            getAsyncData().then((response) => {
                setData(response);
                setLoading(false);
                combobox.resetSelectedOption();
            });
        }
        },
    });

    const options = data.map((item) => (
        <Combobox.Option value={`${item.id}@${item.description}`} key={item.id}>
        {item.description}
        </Combobox.Option>
    ));

    return (
        <Combobox
        store={combobox}
        withinPortal={false}
        onOptionSubmit={(val) => {
            // const [id, description] = val.split('@');
            handleChange(val.split('@')[0]);
            combobox.closeDropdown();
        }}
        >
        <Combobox.Target>
            <InputBase
                id={props.id}
                label={props.label}
                component="button"
                type="button"
                pointer
                // rightSection={loading ? <Loader size={18} /> : <Combobox.Chevron />}
                rightSection={
                    _value !== null ? (
                    <CloseButton
                    size="sm"
                    onMouseDown={(event) => event.preventDefault()}
                    onClick={() => {
                        handleChange('');
                        }
                    }
                    aria-label="Clear value"
                    />
                    ) : (
                        loading ? <Loader size={18} /> : <Combobox.Chevron />
                    )
                }
                onClick={() => combobox.toggleDropdown()}
                rightSectionPointerEvents={_value === null ? 'none' : 'all'}
                >
                {_value || <Input.Placeholder></Input.Placeholder>}
            </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown>
            <Combobox.Options>
            {loading ? <Combobox.Empty>Loading....</Combobox.Empty> : options}
            </Combobox.Options>
        </Combobox.Dropdown>
        </Combobox>
    );
};

export default DeliveryWaySelect;