export enum FileTypeEnum {
    PURCHASE_ORDER = "PURCHASE_ORDER",
    PAYMENT = "PAYMENT",
    PRODUCTION = "PRODUCTION",
  }

export interface FileInfoInterface {
    name: string;
    url: string;
    type: FileTypeEnum;
    legacyStorage: boolean;
}