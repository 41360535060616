import { Container, Flex, Grid, Group, Skeleton } from '@mantine/core';
import React from 'react';
import { tableZoneBackground } from '../../styles';


const MasterFilterSkeleton: React.FC = () => {

    return (
        <Container fluid px="sm" py="md" styles={tableZoneBackground} >
            <Grid grow gutter="xs">
                <Grid.Col span={6}>
                    <Skeleton height={20} width={100}/>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Flex justify="flex-end" align="center" direction="row">
                        <Skeleton height={20} width={50}/>
                        <Skeleton height={20} width={50}/>
                    </Flex>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Skeleton height={20}/>
                </Grid.Col>
            </Grid>

            <Skeleton height={200}/>
            <Flex
            mih={50}
            gap="md"
            justify="flex-end"
            align="flex-end"
            direction="row"
            wrap="wrap"
            >
                <Skeleton height={20} width={50}/>
                <Skeleton height={20} width={100}/>
            </Flex>
            <Group justify="center" mt="md">
                <Skeleton height={20} width={300}/>
            </Group>
        </Container>
    );
};

export default MasterFilterSkeleton;