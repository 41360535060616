import { FC } from "react";
import { FormFieldConfigInterface } from "../../../commons/interfaces/MasterFilterInterfaces";
import { Input, MultiSelect, Select } from "@mantine/core";
import AmountInput from "../../input/AmountInput";
import ThirdPartySelect from "../../select/ThirdPartySelect";
import ClientSelect from "../../select/ClientSelect";
import DeliveryWaySelect from "../../select/DeliveryWaySelect";
import ProductSelect from "../../select/products/ProductSelect";
import ProductColorSelect from "../../select/products/ProductColorSelect";
import { UseFormReturnType } from "@mantine/form";
import FormDatePicker from "../../input/FormDatePicker";

interface FormValues {
    [key: string]: any;
}

interface FieldsFactoryProps {
    fieldConfig: FormFieldConfigInterface;
    form: UseFormReturnType<FormValues>;
}

const FieldsFactory: FC<FieldsFactoryProps> = ({ fieldConfig, form }) => {

    switch (fieldConfig.type) {
        case "input":
            return (
                <Input.Wrapper label={fieldConfig.label}>
                    <Input
                        id={fieldConfig.id}
                        key={form.key(fieldConfig.id)}
                        {...form.getInputProps(fieldConfig.id)}
                    />
                </Input.Wrapper>
            );
        case "number-input":
            return (
                <AmountInput
                    id={fieldConfig.id}
                    label={fieldConfig.label}
                    key={form.key(fieldConfig.id)}
                    {...form.getInputProps(fieldConfig.id)}
                />
            );
        case "select":
            return (
                <Select
                    clearable
                    id={fieldConfig.id}
                    label={fieldConfig.label}
                    data={fieldConfig.data || []}
                    key={form.key(fieldConfig.id)}
                    {...form.getInputProps(fieldConfig.id)}
                />
            );
        case "thirdParty-select":
            return (
                <ThirdPartySelect
                    id={fieldConfig.id}
                    label={fieldConfig.label}
                    thirdPartyContext={fieldConfig.thirdPartyContext}
                    key={form.key(fieldConfig.id)}
                    {...form.getInputProps(fieldConfig.id)}
                />
            );
        case "client-select":
            return (
                <ClientSelect
                    id={fieldConfig.id}
                    label={fieldConfig.label}
                    key={form.key(fieldConfig.id)}
                    {...form.getInputProps(fieldConfig.id)}
                />
            );
        case "deliveryWay-select":
            return (
                <DeliveryWaySelect
                    id={fieldConfig.id}
                    label={fieldConfig.label}
                    key={form.key(fieldConfig.id)}
                    {...form.getInputProps(fieldConfig.id)}
                />
            );
        case "product-select":
            return (
                <ProductSelect
                    id={fieldConfig.id}
                    label={fieldConfig.label}
                    key={form.key(fieldConfig.id)}
                    {...form.getInputProps(fieldConfig.id)}
                />
            );
        case "productColor-select":
            return (
                <ProductColorSelect
                    id={fieldConfig.id}
                    label={fieldConfig.label}
                    key={form.key(fieldConfig.id)}
                    {...form.getInputProps(fieldConfig.id)}
                />
            );
        case "multiselect":
            return (
                <MultiSelect
                    id={fieldConfig.id}
                    label={fieldConfig.label}
                    data={fieldConfig.data || []}
                    searchable
                    key={form.key(fieldConfig.id)}
                    {...form.getInputProps(fieldConfig.id)}
                />
            );
        case "datePicker":
            return (
                <FormDatePicker
                    id={fieldConfig.id}
                    label={fieldConfig.label}
                    key={form.key(fieldConfig.id)}
                    {...form.getInputProps(fieldConfig.id)}
                />
            );
        default:
            return null;
    }
}

export default FieldsFactory