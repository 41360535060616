import { Button, Divider, Flex, Grid, Modal, Select, rem } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useForm, UseFormInput } from "@mantine/form";
import { IPedido } from "../../commons/interfaces/Pedidos";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { disableRoute, getFechaEntrega, getRutaEntrega, validateFechaEntrega } from "../../commons/utils/RouteUtils";
import { DatePickerInput } from "@mantine/dates";
import { useEffect, useState } from "react";
import { getFormattedDateString } from "../../commons/utils/Formatters";
import React from "react";
import useRequest from "../../hooks/useRequest";

const ROUTES_VALUES = [
    { value: 'RUTA 1', label: 'RUTA 1' },
    { value: 'RUTA 2', label: 'RUTA 2' },
]

interface ModalSelectRutaProps {
    openModal: boolean;
    continueCallback: (selectedDate: string, selectedRoute: string) => void;
    onCloseCallback: () => void;
    pedido: IPedido;
}

export const ModalSelectRuta: React.FC<ModalSelectRutaProps> = ({
    openModal,
    continueCallback,
    onCloseCallback,
    pedido
}) => {

    const [sinRuta, setSinRuta] = useState<boolean>(false);
    const [disableSelectRuta, setDisableSelectRuta] = useState<boolean>(true);
    const [opened, { open, close }] = useDisclosure(openModal);
    const { executeRequest } = useRequest();

    const icon = <FontAwesomeIcon icon={faCalendar}  style={{ width: rem(18), height: rem(18) }} />;

    const fechaEntrega = getFechaEntrega();

    const form = useForm({
        initialValues: {
            rutaEntrega: sinRuta ? 'Sin Ruta' : getRutaEntrega(pedido.idFentrega),
            fechaEntrega: fechaEntrega
        },
        validate: {
            rutaEntrega: (value) => {
                return (value === '' || value === undefined ? 'Ingresa una ruta valida.' : null);
            },
            fechaEntrega: (value) => {
                return validateFechaEntrega(value, pedido);
            }
        },
        onValuesChange(values, previous) {
            if (values.fechaEntrega !== null && values.fechaEntrega !== undefined) {
                setDisableSelectRuta(disableRoute(values.fechaEntrega));
            }
        },

    });

    useEffect(() => {
        if (pedido.idFentrega === "CLIENTE RECOLECTA" || pedido.idFentrega === "ALGARIN RECOLECTA") {
            setSinRuta(true);
            form.setFieldValue('fechaEntrega', new Date());
        }
    }, [pedido]);


    const onSubmit = (values: any) => {
        const selectedDate = getFormattedDateString(values.fechaEntrega);
        const selectedRoute = values.rutaEntrega;
        continueCallback(selectedDate, selectedRoute);
        close();
    }

    const onClose = () => {
        executeRequest({
            url: `/sales/anticipos?action=select-anticipo`,
            method: 'POST',
            body: {
                pedido: pedido.idPedido,
                tkmov: pedido.tkmov,
                empresa: pedido.idEmpresa,
                idCliente: pedido.idCliente,
                montoAnticipo: 0
            },
            silentFailure: true
        });
        onCloseCallback();
        close();
    }

    return (
        <>
            <Modal opened={opened} onClose={onClose} title="Fecha de entrega">
                <Divider my="sm" />
                <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
                    <Grid gutter="lg" justify="center">

                        <Grid.Col span={12} >
                            <DatePickerInput
                                valueFormat="DD-MM-YYYY"
                                leftSection={icon}
                                leftSectionPointerEvents="none"
                                placeholder="Fecha de entrega"
                                clearable
                                {...form.getInputProps('fechaEntrega')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Select
                                label="Elige una ruta"
                                data={sinRuta ? [...ROUTES_VALUES, { value: 'Sin Ruta', label: 'Sin Ruta' }] : ROUTES_VALUES}
                                disabled={sinRuta || disableSelectRuta}
                                {...form.getInputProps('rutaEntrega')}
                            />
                        </Grid.Col>

                    </Grid>
                    <Divider my="sm" />
                    <Flex
                        mih={50}
                        gap="md"
                        justify="center"
                        align="flex-end"
                        direction="row"
                        wrap="wrap"
                    >
                        <Button variant="danger" onClick={() => onClose()}>Cerrar</Button>
                        <Button variant="primary" type="submit">Aplicar</Button>
                    </Flex>
                </form>
            </Modal>
        </>
    );
}