import Pedidos from "./pages/Pedidos";
import { createTheme, MantineProvider } from "@mantine/core";
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import '@mantine/notifications/styles.css';
import AlertContextModal from "./components/modals/context/AlertContextModal";
import { GlobalContextProvider } from "./components/providers/GlobalContext";
import ConfirmContextModal from "./components/modals/context/ConfirmContextModal";
import MarkAsLostOrActiveContextModal from "./components/modals/context/MarkAsLostContextModal";
import RequestDetail from "./pages/AuthoPedidos/RequestDetails";
import RequestOrderDetails from "./pages/AuthoPedidos/RequestOrderDetails";
import { RefreshTabsProvider } from "./components/providers/RefreshTabsContext";

const theme = createTheme({
  primaryColor: "4p-blue",
  colors: {
    "4p-blue": [
      "#e7f6ff",
      "#d6e8fa",
      "#aeceed",
      "#82b3e1",
      "#5e9cd7",
      "#468ed2",
      "#3886d0",
      "#2974b8",
      "#1d67a7",
      "#005994",
    ],
  },
});


const queryParams = new URLSearchParams(window.location.search);
const tkmov = queryParams.get("tkmov") || '';
const parentResource = queryParams.get("parentResource") || '';
let parentContext = queryParams.get("parentContext") || '';
parentContext = parentContext.slice(1);

function App() {
  return (
    // context de la aplicacion
    <GlobalContextProvider parentResourceName={parentResource} tkmov={tkmov} parentContext={parentContext}>
      <MantineProvider theme={theme}>
        <Notifications position="top-center" limit={3} autoClose={10000} />
        <RefreshTabsProvider>
          <ModalsProvider modals={{ alert: AlertContextModal, confirm: ConfirmContextModal, markAsLostOrActivate: MarkAsLostOrActiveContextModal /* ...other modals */ }} >
            <Router>
              <Routes>
                <Route path="/" element={<Pedidos/>} />
                <Route path="/health" element={<><h3>Hey There!!! The App is Healthy</h3></>} />
                <Route path="/authoMail" element={<RequestDetail/>} />
                <Route path="/orderAuthoView" element={<RequestOrderDetails/>}/>
              </Routes>
            </Router>
          </ModalsProvider>
        </RefreshTabsProvider>
      </MantineProvider>
    </GlobalContextProvider>
  );
}

export default App;
