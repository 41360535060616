import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { ActionIcon, Flex, Text } from '@mantine/core';
import { IPedido, LogisticsStatusDTO } from '../../../commons/interfaces/Pedidos';
import useRequest from '../../../hooks/useRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { notifications } from '@mantine/notifications';

interface LogisticsStatusProps {
    request: IPedido;
}

const LogisticsStatus: React.FC<LogisticsStatusProps> = ({request}) => {
    const [logisticStatus, setLogisticStatus] = useState<LogisticsStatusDTO | undefined>();
    const {executeRequest, executeRequestAndUpdateState} = useRequest();

    const refreshLogisticsStatus = useCallback((): void => {
        executeRequestAndUpdateState({
            url: `/logistics?requestId=${request.idPedido}&companyId=${request.idEmpresa}&tkmov=${request.tkmov}`,
            method: 'GET',
            stateToUpdate: setLogisticStatus
        });
    }, [executeRequestAndUpdateState, request]);

    useEffect(() => {
        refreshLogisticsStatus();
    }, [refreshLogisticsStatus]);

    const getStatusText = useCallback((): ReactNode => {
        switch (logisticStatus?.status) {
            case 'SENDING':
                return <Text c={'green'}>{'Enviando'}</Text>
            case 'SENT':
                return <Text c={'green'}>{'Enviado'}</Text>
            case 'FAILED':
                return <Text c={'orange'}>{'Fallido'}</Text>
            case 'UNKNOW':
                return <Text>{'Desconocido'}</Text>
            default:
                return <Text>{'Desconocido'}</Text>
        }
    }, [logisticStatus]);

    const canRetry = useCallback((): boolean => {
        return logisticStatus?.status === 'FAILED';
    }, [logisticStatus]);

    const retryLogisticsProcess = useCallback(() => {
        notifications.show({
            color: 'orange',
            title: 'Funcionalidad no implementada',
            message: 'Disculpe, esta funcionalidad aun no esta disponible.',
        })
          // Descomentar si se quiere reactivar el reenvio de pedidos a logistica
        // executeRequest({
        //     url: `/logistics?requestId=${request.idPedido}&companyId=${request.idEmpresa}&tkmov=${request.tkmov}`,
        //     method: 'POST',
        //     body: {
        //         pedido: request.idPedido,
        //         empresa: request.idEmpresa,
        //         tkmov: request.tkmov
        //     },
        //     callback: refreshLogisticsStatus
        // });
    }, [executeRequest, request]);

    return (
        <Flex
            pl={10}
            mih={50}
            bg='var(--mantine-color-blue-light)'
            gap="sm"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
            >
            <Text fw={700}>Estado de logistica:</Text>
            {logisticStatus && 
            <>
                <Text fw={700}>{getStatusText()}</Text>
                { canRetry() &&
                    <>
                    <ActionIcon onClick={retryLogisticsProcess} variant="filled" aria-label="Settings" size="sm">
                        <FontAwesomeIcon icon={faArrowsRotate} />
                    </ActionIcon>
                    </>
                }
            </>
            }
        </Flex>
    );
};

export default LogisticsStatus;