import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Table, Checkbox, Tooltip, ActionIcon } from "@mantine/core";
import { fontStyles, tableStyles } from "../../styles";
import { FullTableActionProps, MasterTableProps, SimpleTableActionProps } from "../../commons/types/MasterTable";
import { RequestMovements } from "../drawer/movements/Movements";
import ActionButtonP from "./ActionButtons/ActionButtonP";
import Currency from "../currency/Currency";
import TableCheckActionContext from "../providers/TableCheckActionContext";
import { IPedido } from "../../commons/interfaces/Pedidos";
import MenuActions from "./MenuActions";
import ActionButtonEdit from "./ActionButtons/ActionButtonEdit";
import FilesList from "../modals/files/FilesList";
import RequestDetails from "../drawer/request-details/RequestDetails";
import { getButtonEStyles } from "../../commons/utils/ActionButtonsStyleHelper";
import ActionButtonInvoice from "./ActionButtons/ActionButtonInvoice";
import ActionButtonClientRecolect from "./ActionButtons/ActionButtonClientRecolect";
import ActionButtonAutoCEO from "./ActionButtons/ActionButtonAutoCEO";
import useRights from "../../hooks/useRights";
import ActionButtonSeparated from "./ActionButtons/ActionButtonSeparated";
import ActionButtonS from "./ActionButtons/ActionButtonS";
import { getRequestType } from "../../commons/utils/RequestUtils";
import SortableColumnHead from "./order/sortableColumnHead";
import ScopeContext from "../providers/ScopeContext";
import { ContextEnum } from "../../commons/enums/RequestContextEnum";

const MasterTable: FC<MasterTableProps> = ({
  checkbox,
  p,
  s,
  t,
  e,
  clienteEnRecepcion,
  apartarMercancia,
  editarCotizacion,
  eventosMovimientos,
  verArchivos,
  generarFacturas,
  numeroPedido,
  tipoPedido,
  cliente,
  fecha,
  fechaApartado,
  fechaEntrega,
  pedCliente,
  factura,
  ruta,
  subtotal,
  iva,
  total,
  vendedor,
  acciones,
  pedidosResponse,
  visibleColumns
}: MasterTableProps) => {
  const [selectedRows, setSelectedRows] = useState<IPedido[]>([]);
  const { setCheckedRequests } = useContext(TableCheckActionContext);
  const { scope } = useContext(ScopeContext);
  const {showControlledInput} = useRights();

  const isVisible = (column: SimpleTableActionProps) => {
    if (column.key && column.hideable) {
      return visibleColumns?.includes(column.key);
    }
    return true;
  };

  useEffect(() => {
    setCheckedRequests(selectedRows);
    console.log('[MasterTable] selectedRows', selectedRows);
  }, [selectedRows, setCheckedRequests]);

  const showCheckboxes = useCallback(() => {
    const showRemission =  showControlledInput({rightControlId: "BtnGridAutCeo", disabled: false, hideable: false});
    return showRemission || scope.scopeContext === ContextEnum.QUOTATION
  }, [scope, showControlledInput]);

  const ths = (
      <Table.Tr>
      {checkbox && showCheckboxes() && <Table.Th/>}
      {p && showControlledInput(p) && isVisible(p) && <Table.Th/>}
      {s && showControlledInput(s) && isVisible(s) && <Table.Th/>}
      {t && showControlledInput(t) && isVisible(t) && <Table.Th/>}
      {e && <Table.Th/>}
      {clienteEnRecepcion && showControlledInput(clienteEnRecepcion) && isVisible(clienteEnRecepcion) && <Table.Th/>}
      {apartarMercancia && showControlledInput(apartarMercancia) && isVisible(apartarMercancia) && <Table.Th/>}
      {editarCotizacion && showControlledInput(editarCotizacion) && isVisible(editarCotizacion) && <Table.Th/>}
      {eventosMovimientos && showControlledInput(eventosMovimientos) && isVisible(eventosMovimientos) && <Table.Th/>}
      {verArchivos && <Table.Th/>}
      {generarFacturas && showControlledInput(generarFacturas) && isVisible(generarFacturas) && <Table.Th></Table.Th>}
      {numeroPedido && !numeroPedido?.disabled  && isVisible(numeroPedido) && <SortableColumnHead columnName="Nro" columnProps={numeroPedido}/>}
      {tipoPedido && <Table.Th>Tipo</Table.Th>}
      {cliente && !cliente?.disabled && isVisible(cliente) && <SortableColumnHead columnName="Cliente" columnProps={cliente}/>}
      {fecha && !fecha?.disabled && isVisible(fecha) && <SortableColumnHead columnName="Fecha" columnProps={fecha}/>}
      {fechaApartado && !fechaApartado?.disabled && isVisible(fechaApartado) && <SortableColumnHead columnName="F. V. Apartado" columnProps={fechaApartado}/>}
      {fechaEntrega && !fechaEntrega?.disabled && isVisible(fechaEntrega) && <SortableColumnHead columnName="F. Entrega" columnProps={fechaEntrega}/>}
      {pedCliente && !pedCliente?.disabled && isVisible(pedCliente) && <SortableColumnHead columnName="Ped.Cliente" columnProps={pedCliente}/>}
      {factura && !factura?.disabled && isVisible(factura) && <SortableColumnHead columnName="Factura" columnProps={factura}/>}
      {ruta && !ruta?.disabled && isVisible(ruta) && <SortableColumnHead columnName="Ruta" columnProps={ruta}/>}
      {subtotal && !subtotal?.disabled && isVisible(subtotal) && <SortableColumnHead columnName="Subtotal" columnProps={subtotal}/>}
      {iva && !iva?.disabled && isVisible(iva) && <SortableColumnHead columnName="IVA" columnProps={iva}/>}
      {total && !total?.disabled && isVisible(total) && <SortableColumnHead columnName="Total" columnProps={total}/>}
      {vendedor && !vendedor?.disabled && isVisible(vendedor) && <SortableColumnHead columnName="Vendedor" columnProps={vendedor}/>}
      {acciones && <Table.Th/>}
    </Table.Tr>
  );

  useEffect(() => {
    console.log('[MasterTable] pedidosResponse', pedidosResponse);
  }, [pedidosResponse?.pedidos]);

  const rows = pedidosResponse?.pedidos.map((element) => (
    <Table.Tr key={element.idPedido} >
      {checkbox && showCheckboxes() && (
      <Table.Td>
        <Checkbox
          aria-label="Select row"
          checked={selectedRows.includes(element)}
          onChange={(event) =>
            setSelectedRows(
              event.currentTarget.checked
                ? [...selectedRows, element]
                : selectedRows.filter((position) => position.idPedido !== element.idPedido)
            )
          }
        />
      </Table.Td>
        )}
      {p && showControlledInput(p) && (
        <ActionButtonP pedido={element}/>
      )}
      {s && showControlledInput(s) && (
        <ActionButtonS request={element}/>
      )}
      {t && showControlledInput(t) && (
        <ActionButtonAutoCEO request={element}/>
      )}
      {e && (
        <Table.Td pr={3} pl={3}>
          <Tooltip label="Entrega de pedido">
            <ActionIcon variant="light" size="sm" aria-label="Settings" style={getButtonEStyles(element)}>
              E
            </ActionIcon>
          </Tooltip>
        </Table.Td>
      )}
      {clienteEnRecepcion && showControlledInput(clienteEnRecepcion) && (
        <ActionButtonClientRecolect request={element}/>
      )}
      {apartarMercancia && showControlledInput(apartarMercancia) && (
        <ActionButtonSeparated request={element}/>
      )}
      {editarCotizacion && showControlledInput(editarCotizacion) && (
        <ActionButtonEdit request={element}/>
      )}
      {eventosMovimientos && showControlledInput(eventosMovimientos) && (
        <RequestMovements pedido={element}/>
      )}
      {verArchivos && (
          <FilesList request={element}/>
      )}
      {generarFacturas && showControlledInput(generarFacturas) && (
          <ActionButtonInvoice request={element}/>
      )}
      {numeroPedido && !numeroPedido?.disabled  && (
        <RequestDetails request={element} controlInfo={numeroPedido}/>
      )}
      {tipoPedido && (
        <Table.Td style={fontStyles.table}>
          {getRequestType(element)}
        </Table.Td>
      )}
      {cliente && !cliente?.disabled && isVisible(cliente) &&(
        <Table.Td style={fontStyles.table}>
          <Tooltip label={element.nombreCliente}>
            <div>{element.nombreCliente.slice(0,20)}</div>
          </Tooltip>
          </Table.Td>
      )}
      {fecha && !fecha?.disabled && isVisible(fecha) && <Table.Td style={fontStyles.table}>{element.fecha}</Table.Td>}
      {fechaApartado && !fechaApartado?.disabled && isVisible(fechaApartado) && (
        <Table.Td style={fontStyles.table}>
          {element.apartadoFcancela ? element.apartadoFcancela : "-"}
        </Table.Td>
      )}
      {fechaEntrega && !fechaEntrega?.disabled && isVisible(fechaEntrega) && (
        <Table.Td style={fontStyles.table}>
          {element.fentrega ? element.fentrega : "-"}
        </Table.Td>
      )}
      {pedCliente && !pedCliente?.disabled && isVisible(pedCliente) && (
        <Table.Td style={fontStyles.table}>
          {element.pedcte ? element.pedcte : "-"}
        </Table.Td>
      )}
      {factura && !factura?.disabled && isVisible(factura) && (
        <Table.Td style={fontStyles.table}>
          {element.pedFactura > 0 ? element.pedFactura : '-'}
        </Table.Td>
      )}
      {ruta && !ruta?.disabled && isVisible(ruta) && 
      <Table.Td style={fontStyles.table}>
        {element.ruta}
        </Table.Td>
      }
      {subtotal && !subtotal?.disabled && isVisible(subtotal) && (
        <Table.Td style={fontStyles.table}>
          <Currency amount={element.subtotal} />
        </Table.Td>
      )}
      {iva && !iva?.disabled && isVisible(iva) && (
        <Table.Td style={fontStyles.table}>
          <Currency amount={element.iva} />
        </Table.Td>
      )}
      {total && !total?.disabled && isVisible(total) && !total?.disabled && (
        <Table.Td style={fontStyles.table}>
          <Currency amount={element.total} />
        </Table.Td>
      )}
      {vendedor && !vendedor?.disabled && isVisible(vendedor) && (
        <Table.Td style={fontStyles.table}>
            <Tooltip label={element.nombreVendedor}>
              <div>{element.nombreVendedor.slice(0,15)}</div>
            </Tooltip>
          </Table.Td>
      )}
      {acciones && (
        <Table.Td>
          <MenuActions request={element}/>
        </Table.Td>
      )}
    </Table.Tr>
  ));

  return (
    <Table.ScrollContainer minWidth={1240} maw={1880}>
      <Table striped highlightOnHover styles={tableStyles} horizontalSpacing="md">
        <Table.Thead>{ths}</Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
};

export default MasterTable;
