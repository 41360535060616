import { Menu, Text } from '@mantine/core';
import React, { useCallback } from 'react';
import { IPedido } from '../../../commons/interfaces/Pedidos';
import useRights from '../../../hooks/useRights';

interface MenuItemActionsProps {
    disabled?: boolean;
    controlId: string;
    request: IPedido;
}

const ItemCancelSupply: React.FC<MenuItemActionsProps> = (props) => {
    const { showControlledInput } = useRights();


    const show = useCallback(() => {
        return showControlledInput({rightControlId: props.controlId, disabled: props.disabled});
    }, [showControlledInput]);

    return (
        <Menu.Item disabled={!show()}>
            <Text size="sm">Cancelar surtido</Text>
        </Menu.Item>
    );
};

export default ItemCancelSupply;