import { DatePickerInput, DatesProvider } from '@mantine/dates';
import React from 'react';
import { ExtendedInputProps } from '../../commons/interfaces/SelectInterfaces';
import { useUncontrolled } from '@mantine/hooks';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFormattedDate, getFormattedDateString } from '../../commons/utils/Formatters';

const FormDatePicker: React.FC<ExtendedInputProps> = ({id, label, value, defaultValue, onChange}) => {
    
    const [_value, handleChange] = useUncontrolled({
        value: value,
        defaultValue: defaultValue,
        finalValue: 'Final',
        onChange: onChange,
    });

    const icon = <FontAwesomeIcon icon={faCalendar} />;

    return (
        <>
            {/* <DatesProvider
                settings={{ locale: "es", timezone: "America/Mexico_City" }}
            > */}
                <DatePickerInput
                    id={id}
                    label={label}
                    clearable
                    leftSection={icon}
                    leftSectionPointerEvents="none"
                    valueFormat="DD-MM-YYYY"
                    value={_value ? getFormattedDate(_value) : null}
                    onChange={(date) => {
                        if (date) {
                            const dateStr = getFormattedDateString(new Date(date));
                            handleChange(dateStr);
                        } else {
                            handleChange("");
                        }
                    }}
                />

            {/* </DatesProvider> */}
        </>
    );
};

export default FormDatePicker;