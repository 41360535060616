import { Button, Drawer, Pill, Space, Text, Switch, Divider } from "@mantine/core"
import { useLocalStorage } from "@mantine/hooks";
import { useEffect, useMemo, useState } from "react";

type ColumnSelectorProps = {
  onChanged: (colums: string[]) => void;
  columns: Record<string, string>;
  context: string;
}

const ColumnSelector = ({ onChanged, columns, context }: ColumnSelectorProps) => {

  const [openedColumnSelector, setOpenedColumnSelector] = useState(false);
  const [visibleColumns, setVisibleColumns] = useLocalStorage<string[]>({
    key: context,
    defaultValue: [...Object.keys(columns)],
  });

  const hiddenColumns = useMemo(() => {
    if (visibleColumns) {
      return (Object.keys(columns).length - visibleColumns.length)
    }
    return 0;
  }, [visibleColumns]);

  const formatLabel = (str: string) => {
    return str.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
      .toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
  }

  useEffect(() => {
    onChanged(visibleColumns);
  }, [visibleColumns]);

  return (
    <>
      <Button variant="light" radius="xl" onClick={() => setOpenedColumnSelector(true)}>
      {
        hiddenColumns == 0 ? <>Sin Columnas Ocultas</> :
        <>
          Columnas Ocultas
          <Space w="xs" />
          <Pill size="md">{hiddenColumns}</Pill>
        </>
      }
      </Button>
      <Drawer
        size="sm"
        opened={openedColumnSelector} 
        onClose={() => setOpenedColumnSelector(false)} 
        position="right" 
        title="Mostar y Ocultar Columnas"
      >
        <Text c="dimmed">Las columnas activas se mostrarán en la tabla, las demás se ocultarán.</Text>
        <Divider my="md" />
        <Switch.Group
          label="Columnas"
          value={visibleColumns}
          onChange={setVisibleColumns}
        >
          {
            Object.entries(columns).map(([key, label]) =>
              <Switch key={key} mt="xs" value={key} label={formatLabel(label)} />
            )
          }
        </Switch.Group>
        <Space py="md" />
      </Drawer>
    </>
  )
}

export default ColumnSelector;

