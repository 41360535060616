import { faCalendar, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, FileInput, Flex, Grid, Modal, NumberInput, TextInput, Textarea, rem } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import React from 'react';
import { IPedido } from '../../../commons/interfaces/Pedidos';
import useRequest from '../../../hooks/useRequest';

interface UploadPaymentFileProps {
    openModal: boolean;
    request: IPedido;
    onCloseCallback: () => void;
}

const UploadPaymentFile: React.FC<UploadPaymentFileProps> = (props) => {
    const [opened, { open, close }] = useDisclosure(props.openModal, {});
    const { executeRequest } = useRequest();
    // const { data, executeRequest, loading, responseError } = useAxios({headers: { 'Content-Type': 'multipart/form-data' }});

    const icon = <FontAwesomeIcon icon={faCalendar}  style={{ width: rem(18), height: rem(18) }} />;


    const form = useForm({
        initialValues: {
            uploadDate: null,
            file: null,
            requestNumber: props.request.idPedido,
            paymentAmount: null,
        },
        validate: {
            uploadDate: (value) => {
                return (value === null || value === undefined ? 'Ingresa una fecha valida.' : null);
            },
            file: (value) => {
                return (value === null || value === undefined ? 'Por favor cargue un archivo' : null);
            },
            paymentAmount: (value) => {
                return ( !value || value <= 0? 'Por favor ingrese un monto valido' : null);
            }
        },
    },);

    const onClose = () => {
        props.onCloseCallback();
        close();
    }

    const onSubmit = (values: any) => {

        executeRequest({url: `/files/request?action=payment`,
            method: 'POST',
            body: {
                uploadDate: values.uploadDate,
                requestNumber: props.request.idPedido,
                tkmov: props.request.tkmov,
                companyId: props.request.idEmpresa,
                file: values.file,
                fileContentType: values.file.type,
                paymentAmount: values.paymentAmount,
                notes: values.notes,
            }, headers: {'Content-Type': 'multipart/form-data'}});

        props.onCloseCallback();
        close();
    }

    return (
        <Modal opened={opened} onClose={onClose} title="Archivo de pago">
            <Divider my="sm" />
            <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
                <Grid gutter="lg" justify="center">

                    <Grid.Col span={12} >
                        <DatePickerInput
                            label="Fecha"
                            valueFormat="DD-MM-YYYY"
                            leftSection={icon}
                            leftSectionPointerEvents="none"
                            placeholder="dd-mm-yyyy"
                            clearable
                            {...form.getInputProps('uploadDate')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                        label="No. Pedido"
                        //value={''}
                        disabled
                        {...form.getInputProps('requestNumber')}
                        />
                    </Grid.Col>

                    <Grid.Col span={12}>
                    <FileInput
                        clearable
                        leftSection={<FontAwesomeIcon icon={faFile} />}
                        label="Archivo"
                        // value={}
                        placeholder="No file chosen"
                        leftSectionPointerEvents="none"
                        {...form.getInputProps('file')}
                    />
                    </Grid.Col>

                    <Grid.Col span={12}>
                        <NumberInput
                            label="Monto del pago"
                            placeholder="Monto"
                            decimalSeparator="."
                            thousandSeparator=","
                            decimalScale={2}
                            {...form.getInputProps('paymentAmount')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                    <Textarea
                        label="Notas"
                        {...form.getInputProps('notes')}
                    />
                </Grid.Col>

                </Grid>
                <Divider my="sm" />
                <Flex
                    mih={50}
                    gap="md"
                    justify="center"
                    align="flex-end"
                    direction="row"
                    wrap="wrap"
                >
                    <Button variant="outline" color="gray" onClick={() => onClose()}>Cerrar</Button>
                    <Button variant="primary" type="submit">Agregar archivo</Button>
                </Flex>
            </form>
        </Modal>
    );
};

export default UploadPaymentFile;