import { colors } from "./colors";

const baseStyle = {
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "normal",
  border: "none",
  background: colors.gray,
};

export const actionIconStyles = {
  activoVerde: {
    background: colors.lightGreen,
    color: colors.darkGreen,
  },
  canceladoRojo: {
    background: colors.lightRed,
    color: colors.darkRed,
  },
  selectedAzul: {
    background: colors.lightBlue,
    color: colors.blue,
  },
  normal: {
    background: colors.gray,
    color: colors.darkGray,
  },
  inactivoAzul: {
    background: colors.lightBlue,
    color: colors.blue,
  },
  enEspera: {
    background: colors.yellow,
    color: colors.darkYellow,
  },
  activo: {
    background: colors.lightGreen,
    color: colors.darkGreen,
  },
};

// No usar. Eliminar cuanto antes
export const iconStyles = {
  inactivoAzul: {
    ...baseStyle,
    background: colors.lightBlue,
    color: colors.blue,
  },
  selectedAzul: {
    ...baseStyle,
    background: colors.lightBlue,
    color: colors.blue,
  },
  enEspera: {
    ...baseStyle,
    background: colors.yellow,
    color: colors.darkYellow,
  },
  activoVerde: {
    ...baseStyle,
    background: colors.lightGreen,
    color: colors.darkGreen,
  },
  canceladoRojo: {
    ...baseStyle,
    background: colors.lightRed,
    color: colors.darkRed,
  },
  normal: {
    ...baseStyle,
    background: colors.gray,
    color: colors.darkGray,
  },
  activo: {
    ...baseStyle,
    background: colors.lightGreen,
  },
};
