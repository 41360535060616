import { FC, useEffect, useState } from "react";
import { Input, Title, Button, Flex } from "@mantine/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFilter, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { getHotkeyHandler } from "@mantine/hooks";
import { filterZoneBackground } from "../../styles";
import { notifications } from "@mantine/notifications";

type SearchBarProps = {
  title: string;
  onToggle: () => void;
  searchCallback: (wildcard: string) => void;
};

const SearchBar: FC<SearchBarProps> = ({ title, onToggle, searchCallback}) => {

  const [value, setValue] = useState<string>('');
  const handleFind = () => {
    if(value){
      searchCallback(value);
    }else{
      searchCallback('');
      /** 
       *  Se comenta ya que estan utilizando el filtrar para hacer busqueda completa en cada tab. 
       * Hay que conversar con el equipo para ver si se puede hacer un cambio en la funcionalidad.
       * La idea seria volver a activar esta validacion y hacer que otro key binding active la busqueda de los filtros.
       * 
       * O hacer que el mismo keybinding active la busqueda de los filtros y la busqueda completa, en ese caso hay que agregar logica que permita saber cuando 
       * se esta buscando por numero de pedido y cuando es busqueda completa. Hay un Jira con esto 
       * https://linets.atlassian.net/browse/FPASE-107
      */
    //   notifications.show({
    //     color: 'orange',
    //     title: 'No se indico un valor a buscar',
    //     message: 'Disculpe, por favor indique un valor a buscar.',
    // })
    }
  }

  return (
    <Flex styles={filterZoneBackground} px="sm" py="xs" direction="row" justify="space-between">
      <Flex
        gap="xs"
        justify="flex-start"
        align="center"
        direction="row"
        wrap="wrap"
        px="sm"
        py="xs"
      >
        <Title order={3} style={{ color: "#54595E" }}>
          {title}
        </Title>
      </Flex>
      <Flex
        styles={filterZoneBackground}
        gap="xs"
        justify="flex-end"
        align="center"
        direction="row"
        wrap="wrap"
        px="sm"
        py="xs"
      >
        <Input
          radius="xs"
          placeholder="Buscar"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          
          onKeyDown={getHotkeyHandler([
            ['Enter', handleFind]
          ])}
          
          rightSection={<FontAwesomeIcon icon={faMagnifyingGlass}/>}
        />
        <Button
          bg="white"
          leftSection={<FontAwesomeIcon icon={faFilter} />}
          rightSection={<FontAwesomeIcon icon={faCaretDown} />}
          variant="outline"
          onClick={onToggle}
        >
          Filtros
        </Button>
      </Flex>
    </Flex>
  );
};

export default SearchBar;
