import { Divider, Flex, Grid, Modal, Skeleton } from "@mantine/core";
import useAxios from "../../../hooks/useAxios";
import { useDisclosure } from "@mantine/hooks";
import { ResupplyRSDTO } from "../../../commons/interfaces/Movements";
import { useCallback, useEffect, useState } from "react";
import { IPedido } from "../../../commons/interfaces/Pedidos";
import RegularModalContent from "./RegularModalContent";
import ErrorModalContent from "./ErrorModalContent";


function SkeletonContent() {
    return(
            <>
            <Divider my="sm" />
            <Grid gutter="lg" justify="center">
                <Grid.Col span={12}>
                    <Skeleton height={100} width={300}/>
                </Grid.Col>
            </Grid>
            <Divider my="sm" />
            <Skeleton height={20} width={300}/>
            <Divider my="sm" />
            <Flex
                mih={50}
                gap="md"
                justify="center"
                align="flex-end"
                direction="row"
                wrap="wrap"
            >
                <Skeleton height={20} width={20}/>
                <Skeleton height={20} width={20}/>
            </Flex>
        </>
    );
}


interface ModalConfirmResupplyProps {
    openModal: boolean;
    continueCallback: (canContinue: boolean) => void;
    onSkipResupplyCallback: () => void;
    onCloseCallback: () => void;
    pedido: IPedido;
}

export const ModalConfirmResupply: React.FC<ModalConfirmResupplyProps> = ({
    openModal,
    continueCallback,
    onSkipResupplyCallback,
    onCloseCallback,
    pedido
}) => {

    const [resupplyResponse, setResupplyResponse] = useState<ResupplyRSDTO | undefined>();
    const { data, executeRequest, loading, responseError } = useAxios({});
    const [opened, { open, close }] = useDisclosure(openModal);

    useEffect(() => {
        executeRequest('/sales/warehouse-supply?action=resupply-needed', "post", { pedido: pedido.idPedido, tkmov: pedido.tkmov, empresa: pedido.idEmpresa });
    }, [pedido]);

    useEffect(() => {
        if (data) {
            const resupplyInfo = data as ResupplyRSDTO
            if(!resupplyInfo.needSupply && resupplyInfo.canContinue){
                onSkipResupplyCallback();
            }else{
                setResupplyResponse(data as ResupplyRSDTO);
            }
        }
    }, [data]);

    const onClose = useCallback(() => {
        onCloseCallback();
        close();
    }, [close, onCloseCallback]);

    const canContinueCallback = useCallback((canContinue: boolean) => {
        continueCallback(canContinue);
        close();
    }, [close, continueCallback]);


    const renderContent = useCallback(() => {
        if(resupplyResponse){
            return resupplyResponse?.canContinue ? <RegularModalContent canContinueCallback={canContinueCallback} resupplyResponse={resupplyResponse}/> 
            : <ErrorModalContent onClose={onClose} pedido={pedido} resupplyResponse={resupplyResponse}/>;
        }else{
            return <SkeletonContent/>;
        }
    }, [canContinueCallback, onClose, pedido, resupplyResponse]);

    return (
        <>
            {/* <Modal opened={opened} onClose={() => onClose()} title={resupplyResponse ? "Alerta de requisición!": "Colsultando requisición"}> */}
            <Modal opened={opened} onClose={() => onClose()} title={"Alerta de Stock"}>
                {renderContent()}
            </Modal>
        </>
    );
}